<template >
  <v-container fluid class="pa-0">
    <v-card class="my-2 text-left" flat>
      <v-divider/>
      <v-col cols="12" class="px-0 pt-2 mt-0">
        <v-list class="py-0 my-0">
          <v-list-subheader>
            <span style="line-height: 36px">Иностранные языки:</span>
            <v-btn icon flat size="x-small" v-show="edit">
              <v-icon color="primary" @click="opened_form_language = true">add</v-icon>
            </v-btn>
          </v-list-subheader>
          <v-list-item
            v-for="(item, i) in getProfile.languages"
            :key="i"
            active-color="primary"
          >
            <template v-slot:prepend>
              <v-icon color="primary">language</v-icon>
            </template>
            <template v-slot:append>
              <v-btn icon flat size="x-small" v-show="edit" @click="selectItem(item,'opened_form_language')">
                <v-icon color="primary">edit</v-icon>
              </v-btn>
              <v-btn icon flat size="x-small" v-show="edit" @click="deleteItem(item,'Language')">
                <v-icon color="primary">delete</v-icon>
              </v-btn>
            </template>
            <v-list-item-title :class="(item.is_native)?'font-weight-black text-wrap':'text-wrap'">{{ item.language.text }} <span v-if="!item.is_native">({{ item.level}}) </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="getProfile.languages && getProfile.languages.length === 0">
            <template v-slot:prepend>
              <v-icon color="primary">language</v-icon>
            </template>
            <v-list-item-title>
              <span>не указан</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-card-title class="bg-grey-lighten-3 text-center title dark-primary">
        Контактная информация
      </v-card-title>
      <v-row>
        <v-col cols="12" md="6">
          <v-list density="compact">
            <v-list-subheader>
              <span style="line-height: 36px">Телефоны:</span>
              <v-btn icon flat size="x-small" v-show="edit" @click="opened_form_phone = true">
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-subheader>
            <v-list-item
              v-for="(item, i) in getProfile.phones"
              :key="i"
              active-color="primary"
            >
              <template v-slot:prepend>
                <v-icon color="primary">phone</v-icon>
              </template>
              <v-list-item-title class="text-wrap">{{ item.code }}{{ item.number }} ({{ item.kind }})</v-list-item-title>
              <template v-slot:append>
                <v-btn icon flat size="x-small" v-show="edit" @click="selectItem(item,'opened_form_phone')">
                  <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon flat size="x-small" v-show="edit" @click="deleteItem(item,'Phone')">
                  <v-icon color="primary">delete</v-icon>
                </v-btn>
              </template>
            </v-list-item>
            <v-list-item v-if="getProfile.phones && getProfile.phones.length === 0">
              <template v-slot:prepend>
                <v-icon color="primary">phone</v-icon>
              </template>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6">
          <v-list density="compact">
            <v-list-subheader>
              <span style="line-height: 36px">Электронные почты:</span>
              <v-btn icon flat size="x-small" v-show="edit" @click="opened_form_email = true">
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-subheader>
            <v-list-item
              v-for="(item, i) in getProfile.emails"
              :key="i"
              active-color="primary"
            >
              <template v-slot:prepend>
                <v-icon color="primary">email</v-icon>
              </template>
              <template v-slot:append>
                <v-btn icon flat size="x-small" v-show="edit && item.id" @click="selectItem(item,'opened_form_email')">
                  <v-icon icon="edit" size="x-small" color="primary"></v-icon>
                </v-btn>
                <v-btn icon flat size="x-small" v-show="edit && item.id" @click="deleteItem(item,'Email')">
                  <v-icon color="primary">delete</v-icon>
                </v-btn>
              </template>
              <v-list-item-title class="text-wrap">{{ item.email }} ({{ item.kind }})</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="getProfile.emails && getProfile.emails.length === 0">
              <template v-slot:prepend>
                <v-icon color="primary">email</v-icon>
              </template>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="pt-0 mt-0">
          <v-list class="py-0 my-0" density="compact">
            <v-list-subheader>
              <span style="line-height: 36px">Адреса:</span>
              <v-btn icon flat size="x-small" v-show="edit" @click="opened_form_addresses = true">
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-subheader>
            <v-list-item
              v-for="(item, i) in getProfile.addresses"
              :key="i"
              active-color="primary"
            >
              <template v-slot:prepend>
                <v-icon color="primary">home</v-icon>
              </template>
              <template v-slot:append>
                <v-btn icon flat size="x-small" v-show="edit" @click="selectItem(item,'opened_form_addresses')">
                  <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon flat size="x-small" v-show="edit" @click="deleteItem(item,'Address')">
                  <v-icon color="primary">delete</v-icon>
                </v-btn>
              </template>
              <v-list-item-title class="text-wrap">
                <span>{{ item.free }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="getProfile.addresses && getProfile.addresses.length === 0">
              <template v-slot:prepend>
                <v-icon color="primary">home</v-icon>
              </template>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>

      </v-row>
      <EditLanguage
        v-if="opened_form_language"
        v-model="opened_form_language"
        :select="select"
        @save="saveForm"
        @close="opened_form_language=false; this.select = null"
      ></EditLanguage>
      <EditEmail
        v-if="opened_form_email"
        v-model="opened_form_email"
        :select="select"
        @save="saveForm"
        @close="opened_form_email=false; this.select = null"
      ></EditEmail>
      <EditPhone
        v-if="opened_form_phone"
        v-model="opened_form_phone"
        :select="select"
        @save="saveForm"
        @close="opened_form_phone=false; this.select = null"
      ></EditPhone>
      <EditAddress
        v-if="opened_form_addresses"
        v-model="opened_form_addresses"
        :select="select"
        @save="saveForm"
        @close="opened_form_addresses=false; this.select = null"
      ></EditAddress>
      <apply-dialog
        v-if="dialog_delete"
        v-model="dialog_delete"
        :get-text="() => `Вы уверены, что хотите удалить?`"
        @close="closeDeleteDialog"
      ></apply-dialog>
    </v-card>
  </v-container>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditEmail from '@/modules/profile/account/modals/edit/EditEmail'
import EditLanguage from '@/modules/profile/account/modals/edit/EditLanguage'
import EditPhone from '@/modules/profile/account/modals/edit/EditPhone'
import EditAddress from '@/modules/profile/account/modals/edit/EditAddress.vue'
import ApplyDialog from "@/modules/templates/ApplyDialog.vue"

export default {
  name: 'InformationUser',
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditLanguage,
    EditPhone,
    EditEmail,
    EditAddress,
    ApplyDialog
  },
  data () {
    return {
      opened_form_language: false,
      opened_form_email: false,
      opened_form_phone: false,
      opened_form_addresses: false,
      dialog_delete: false,
      select: null,
      delete_form: ''
    }
  },
  computed: {
    ...mapGetters({
      getProfile: 'user/getProfile'
    })
  },
  methods: {
    ...mapActions({
      deleteUserLanguage: 'user/deleteUserLanguage',
      deleteUserEmail: 'user/deleteUserMail',
      deleteUserPhone: 'user/deleteUserPhone',
      deleteUserAddress: 'user/deleteUserAddress'
    }),
    closeDeleteDialog (event) {
      if (event) {
        if (this.select) {
          this['deleteUser' + this.delete_form]({
            id: this.select.id,
            finalizer: (data, url) => {
              this.dialog_delete = false
              this.saveForm()
            },
            catcher: () => {
              this.dialog_delete = false
            }
          })
        }
      } else {
        this.dialog_delete = false
        this.select = null
      }
    },
    saveForm () {
      this.opened_form_language = false
      this.opened_form_email = false
      this.opened_form_phone = false
      this.opened_form_addresses = false
      this.select = null
      this.delete_form = null
      this.$emit('update_profile')
    },
    selectItem (item, form) {
      this[form] = true
      this.select = item
    },
    deleteItem (item, form) {
      this.dialog_delete = true
      this.delete_form = form
      this.select = item
    },
  }
}
</script>

<style scoped>

</style>
