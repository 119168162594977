import Vuex from 'vuex'
import leftmenu from './modules/leftmenu'
import maintoolbar from './modules/maintoolbar'
import mainpage from './modules/mainpage'
import user from './modules/users'
import worker from './modules/worker'
import mail from './modules/mail'
import education from './modules/education'
import auth from './modules/auth'
import urls from './modules/urls'
import selectors from './modules/selectors'
import choices from './modules/choices'
import feedback from './modules/feedback'
import signer from './modules/signer'
import reference from "./modules/reference"
import notifications from "./modules/notifications"
import socket from "./modules/socket"
import eventchain from "./modules/eventchain";
export default new Vuex.Store({
  modules: {
    leftmenu,
    maintoolbar,
    mainpage,
    auth,
    user,
    worker,
    notifications,
    mail,
    education,
    urls,
    selectors,
    choices,
    feedback,
    signer,
    reference,
    socket,
    eventchain
  },
  state: {},
  getters: {},
  mutations: {}
})
