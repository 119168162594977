<template>
  <scroll-center-dialog v-model="open" :loading="save_loading" width="760" @close="close" @apply="save" :title="form_title" :scrolled="false">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="worker.start_seniority"
          class="mt-4 pr-md-2 py-1"
          label="Дата начала трудового стажа"
          placeholder="ДД.ММ.ГГГГ"
          type="date"
          variant="outlined"
          :error="hasError('start_seniority')"
          :error-messages="getError('start_seniority')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="worker.start_seniority_in_organization"
          class="mt-md-4 pl-md-2 py-1"
          label="Дата начала трудового стажа в организации"
          placeholder="ДД.ММ.ГГГГ"
          type="date"
          variant="outlined"
          :error="hasError('start_seniority_in_organization')"
          :error-messages="getError('start_seniority_in_organization')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-textarea
      class="py-1"
      v-model="worker.personal_information"
      label="Информация о себе"
      placeholder="Информация о себе"
      variant="outlined"
      rows="3"
      :auto-grow="true"
      :error="hasError('personal_information')"
      :error-messages="getError('personal_information')"
    ></v-textarea>
    <v-textarea
      class="py-1"
      v-model="worker.awards"
      label="Награды"
      placeholder="Награды"
      variant="outlined"
      rows="3"
      :auto-grow="true"
      :error="hasError('awards')"
      :error-messages="getError('awards')"
    ></v-textarea>
    <v-textarea
      class="py-1"
      v-model="worker.previous_jobs"
      label="Предыдущие места работы"
      placeholder="Предыдущие места работы"
      variant="outlined"
      rows="3"
      :auto-grow="true"
      :error="hasError('previous_jobs')"
      :error-messages="getError('previous_jobs')"
    ></v-textarea>
    <v-select
      class="py-1"
      v-model="worker.academic_ranks"
      :items="selectors.academic_ranks"
      label="Учебное звание"
      variant="outlined"
      :error="hasError('academic_ranks')"
      :error-messages="getError('academic_ranks')"
    ></v-select>
    <v-select
      class="py-1"
      v-model="worker.academic_degree"
      :items="selectors.academic_degree"
      label="Ученая степень"
      variant="outlined"
      :error="hasError('academic_degree')"
      :error-messages="getError('academic_degree')"
    ></v-select>
    <v-select
      class="py-1"
      v-model="worker.education"
      :items="selectors.education"
      label="Образование"
      variant="outlined"
      :error="hasError('education')"
      :error-messages="getError('education')"
    ></v-select>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog"
import { mapActions } from "vuex"

export default {
  name: "WorkerEditDialog",
  props: {
    data: Object,
    value: Boolean,
    edit: Boolean,
  },
  components: {
    ScrollCenterDialog,
  },
  mixins: [FormErrorsMixin],
  data () {
    return {
      open: false,
      save_loading: false,
      worker: undefined,
      default_worker: {
        start_seniority: '',
        start_seniority_in_organization: '',
        personal_information: '',
        awards: '',
        previous_jobs: '',
        academic_ranks: null,
        academic_degree: null,
        education: null,
      },
      selectors: {
        education: [
          '-----',
          'среднее профессиональное образование',
          'высшее образование - бакалавриат',
          'высшее образование - специалитет, магистратура',
          'высшее образование - подготовка кадров высшей квалификации',
        ],
        academic_ranks: [
          '-----',
          'Доцент',
          "Профессор"
        ],
        academic_degree: [
          '-----',
          'Кандидат наук',
          "Доктор технических наук",
          'Доктор химических наук',
          "Доктор физико-математических наук",
          "Доктор экономических наук",
          "Доктор филосовских наук",
          "Доктор психологических наук",
          "Доктор исторических наук",
          "Доктор юридических наук",
          "Доктор геолого-минералогических наук",
          "Доктор педагогических наук",
          'Кандидат биологических наук',
          'Кандидат филосовских наук',
          'Кандидат экономических наук',
          'Кандидат технических наук',
          'Кандидат химических наук',
          'Кандидат социологических наук',
          'Кандидат физико-математических наук',
          'Кандидат филологических наук',
          'Кандидат психологических наук',
          'Кандидат юридических наук',
          'Кандидат педагогических наук',
          'Кандидат исторических наук',
          'Кандидат медицинских наук',
        ],
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  computed: {
    form_title () {
      return this.$vuetify.display.mdAndUp ? 'Редактировать информацию о работнике' : 'Редактировать информацию'
    },
  },
  methods: {
    ...mapActions({
      changeWorkerDetail: 'worker/changeWorkerDetail'
    }),
    loadData () {
      if (this.data === undefined) {
        this.worker = this.default_worker
      } else {
        this.worker = JSON.parse(JSON.stringify(this.data))
      }
    },
    save () {
      this.save_loading = true
      this.changeWorkerDetail({
        data: this.worker,
        finalizer: (val) => {
          this.$emit('save')
          this.save_loading = false
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
