import mail from '@/urls/mail'
import {
  sendGetRequest,
} from '@/helper/requests'

const state = {
  mail: {
    currentUrl: null,
    items: [],
    loading: false,
    count: 0,
    detail: [],
    // next: null,
    // prev: null,
    error: null
  }
}

const getters = {
  getMail: state => state.mail.items,
  getMailCount: state => state.mail.count,
  getCurrentUrl: state => state.mail.currentUrl,
  getMailDetail: state => state.mail.detail,
  // getNext: state => state.mail.next,
  // getPrev: state => state.mail.prev,
  isLoadingMail: state => state.mail.loading,
  saveMailError: state => state.mail.error
}

const actions = {
  loadMailList: ({ commit, getters }, { url, urlAppend, finalizer = () => {}, catcher = () => {} }) => {
    if (!url) {
      url = mail.MAIL.MAIL_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoadingMail) {
      commit('SET_MAIL_LOADING')
      sendGetRequest(
        url, (data, url) => {
          commit('SET_MAIL', data.results)
          commit('SET_MAIL_COUNT', data.count)
          // commit('SET_MAIL_NEXT', data['next']);
          // commit('SET_MAIL_PREV', data['prev']);
          commit('SET_MAIL_CURRENT_URL', url)
          commit('SET_MAIL_LOADING_END')
          finalizer(data, url)
        }, (data, url) => {
          commit('SET_MAIL_LOADING_END')
          commit('SAVE_MAIL_ERROR', data)
          catcher(data, url)
        }
      )
    }
  }
}

const mutations = {
  SET_MAIL: (state, items) => {
    state.mail.items = items
  },
  SET_MAIL_COUNT: (state, value) => {
    state.mail.count = value
  },
  // SET_NEXT: (state, value) => {
  //   state.mail.next = value
  // },
  // SET_PREV: (state, value) => {
  //   state.mail.prev = value
  // },
  SET_MAIL_CURRENT_URL: (state, value) => {
    state.mail.currentUrl = value
  },
  SET_MAIL_LOADING: (state) => {
    state.mail.loading = true
  },
  SET_MAIL_LOADING_END: (state) => {
    state.mail.loading = false
  },
  SAVE_MAIL_ERROR: (state, error) => {
    state.mail.error = error
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
