<template>
  <v-container :fluid="true" style="width: 100%">
    <v-row no-gutters justify="center" v-show="isLoadingOrganizationTree">
        <semipolar-spinner
          :animation-duration="2000"
          :size="$vuetify.display.mdAndUp ? 200 : 100"
          color="#1976D2"
          class="mt-16"
        />
      <v-col cols="12">
        <span>Данные загружаются...</span>
      </v-col>
    </v-row>
    <v-card
      class="rounded-lg mb-4"
      v-if="!!data"
    >
      <v-toolbar
        class="bg-gray-light text-left pl-4"
        density="comfortable"
      >
        <v-text-field
          v-model="searchText"
          variant="outlined"
          :label="$vuetify.display.smAndUp ? 'Поиск по структуре' : 'Поиск'"
          placeholder="Начните набирать..."
          class="mb-n6 dark-primary"
          density="compact"
          clearable
        >
        </v-text-field>
        <v-spacer/>
        <v-toolbar-items>
          <v-tooltip location="bottom" text="Сохранить структуру в формате .xlsx">
            <template v-slot:activator="{ props }">
              <v-btn flat icon v-bind="props" @click="saveExel()" class="dark-primary">
                <v-icon>download</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="text-left pt-4 text-body-1">
        <Tree
          :nodes="data"
          :use-icon="true"
          :search-text="searchText"
          :row-hover-background="'#BBDEFB'"
          @nodeClick="copyText"
        >
          <template v-slot:iconActive>
            <v-icon class="mr-2">navigate_next</v-icon>
          </template>
          <template v-slot:iconInactive>
            <v-icon class="mr-2">expand_less</v-icon>
          </template>
        </Tree>
      </v-card-text>
    </v-card>

    <pop-up-alert
      v-model="download_error"
      max-width="600"
      top-color="error"
      close-color="grey-darken-2"
      title="Ошибка загрузки"
      text='Похоже загрузка так и не началась, попробуйте еще раз. <br> В случае если проблема осталась обратитесь на почту <a class="medium-primary" style="text-decoration: none; color: inherit" href="mailto:support@muctr.ru"> support@muctr.ru </a>.'
      :scrolled="false"
      @close="download_error = false"
    >
    </pop-up-alert>
  </v-container>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin"
import Tree from 'vue3-tree'
import { mapActions, mapGetters } from "vuex"
import router from "@/router"
import names from "@/modules/profile/routers/names"
import "vue3-tree/dist/style.css"
import { SemipolarSpinner } from "epic-spinners"
import PopUpAlert from "@/modules/templates/PopUpAlert.vue"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  name: "OrganizationTree",
  mixins: [TitledPageMixin],
  components: {
    Tree,
    SemipolarSpinner,
    PopUpAlert,
  },
  data: function () {
    return {
      download_error: false,
      page_title: "Структура подразделений организации",
      searchText: '',
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      isLoadingOrganizationTree: 'worker/isLoadingOrganizationTree',
      data: 'worker/getOrganizationTree',
    })
  },
  methods: {
    ...mapActions({
      loadOrganizationTree: 'worker/loadOrganizationTree'
    }),
    copyText (node) {
      try {
        navigator.clipboard.writeText(node.label)
      } catch (e) {
      }
    },
    loadData (url = undefined) {
      this.loadOrganizationTree({
        organization_id: 1,
        url,
        finalizer: (val, url) => {},
        catcher: () => {
          router.push({ name: names.BASE })
        }
      })
    },
    findChildren (printJSON, nodes) {
      for (const i in nodes) {
        printJSON.push({ abbr: nodes[i].abbr, code: nodes[i].code, label: nodes[i].label })
        this.findChildren(printJSON, nodes[i].nodes)
      }
    },
    saveExel () {
      let export_data = Object.assign({}, this.data)

      const printJSON = []
      for (const i in this.data) {
        printJSON.push({ code: this.data[i].code, label: this.data[i].label, abbr: this.data[i].abbr })
        this.findChildren(printJSON, this.data[i].nodes)
      }

      export_data = (printJSON)

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("Лист 1")
      worksheet.columns = [
        { header: "Код", key: "code", width: 10 },
        { header: "Название", key: "label", width: 150 },
        { header: "Аббревиатура", key: "abbr", width: 20 },
      ]

      for (const i in export_data) {
        worksheet.addRow(export_data[i])
      }

      workbook.xlsx
        .writeBuffer()
        .then((buffer) =>
          saveAs(new Blob([buffer]), `Структура подразделений РХТУ.xlsx`)
        )
        .catch(() => {
          this.download_error = true
        })
    },
  },
  created () {
    if (!this.data) {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
