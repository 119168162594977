<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_74_2123)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 1.25H6.25C5.91848 1.25 5.60054 1.3817 5.36612 1.61612C5.1317 1.85054 5 2.16848 5 2.5V17.5C5 17.8315 5.1317 18.1495 5.36612 18.3839C5.60054 18.6183 5.91848 18.75 6.25 18.75H13.75C14.0815 18.75 14.3995 18.6183 14.6339 18.3839C14.8683 18.1495 15 17.8315 15 17.5V2.5C15 2.16848 14.8683 1.85054 14.6339 1.61612C14.3995 1.3817 14.0815 1.25 13.75 1.25ZM6.25 0C5.58696 0 4.95107 0.263392 4.48223 0.732233C4.01339 1.20107 3.75 1.83696 3.75 2.5V17.5C3.75 18.163 4.01339 18.7989 4.48223 19.2678C4.95107 19.7366 5.58696 20 6.25 20H13.75C14.413 20 15.0489 19.7366 15.5178 19.2678C15.9866 18.7989 16.25 18.163 16.25 17.5V2.5C16.25 1.83696 15.9866 1.20107 15.5178 0.732233C15.0489 0.263392 14.413 0 13.75 0L6.25 0Z" fill="#0563C1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5C10.3315 17.5 10.6495 17.3683 10.8839 17.1339C11.1183 16.8995 11.25 16.5815 11.25 16.25C11.25 15.9185 11.1183 15.6005 10.8839 15.3661C10.6495 15.1317 10.3315 15 10 15C9.66848 15 9.35054 15.1317 9.11612 15.3661C8.8817 15.6005 8.75 15.9185 8.75 16.25C8.75 16.5815 8.8817 16.8995 9.11612 17.1339C9.35054 17.3683 9.66848 17.5 10 17.5Z" fill="#0563C1"/>
    </g>
    <defs>
      <clipPath id="clip0_74_2123">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon'
}
</script>

<style scoped>

</style>
