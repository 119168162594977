import url_acc from '@/urls/accounts'
import { sendDeleteRequest, sendGetRequest } from '@/helper/requests'

const state = {
  session: []
}

const getters = {
  getSession: state => state.session,
}

const mutations = {
  SET_SESSION_LIST: (state, value) => {
    state.session = value
  },
}

const actions = {
  loadUserSession: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = url_acc.PROFILE.SESSION.LIST()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_SESSION_LIST', data)
        finalizer(data, url)
      }, catcher
    )
  },
  deleteUserSession: ({ commit }, { key, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.SESSION.DELETE(key)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserAllSession: ({ commit }, { finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.SESSION.DELETE_ALL()
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
}
export default {
  state,
  mutations,
  getters,
  actions
}
