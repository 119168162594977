import url_acc from '@/urls/accounts'
import { getUserUid } from '@/helper'
import { sendGetRequest, sendPostRequest, sendPutRequest, updateFileRequest } from '@/helper/requests'
import address from "@/store/modules/users/address"
import email from "@/store/modules/users/email"
import phone from "@/store/modules/users/phone"
import language from "@/store/modules/users/language"
import session from "@/store/modules/users/session"
import account from "@/store/modules/users/account"

const state = {
  user: {
    profile: null
  }
}
const getters = {
  getProfile: state => state.user.profile
}

const mutations = {
  SET_USER_PROFILE: (state, value) => {
    state.user.profile = value
  }
}

const actions = {
  loadAccountDetail: ({ commit }, { finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.DETAIL(getUserUid())
    sendGetRequest(
      url, (data, url) => {
        commit('SET_USER_PROFILE', data)
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  changeAvatar: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.CHANGE_AVATAR(getUserUid())
    updateFileRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  changeEmail: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.CHANGE_EMAIL(getUserUid())
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  changePass: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.CHANGE_PASS(getUserUid())
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  approveEmail: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.APPROVE_EMAIL()
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  checkUserConfirmation: ({ commit, dispatch }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = `${process.env.VUE_APP_API_HOST + process.env.VUE_APP_CONFIRMATION_PREFIX}user/document/need/acquaintance/`
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  checkUserSurvey: ({ commit, dispatch }, { id, special_link_key, finalizer = () => {}, catcher = () => {} }) => {
    let url = `${process.env.VUE_APP_API_HOST + process.env.VUE_APP_SURVEY_PREFIX}surveying/survey/${id}/attempt_check/`
    if (special_link_key) {
      url = url + '?special_link_key=' + special_link_key
    }
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    address,
    email,
    phone,
    language,
    session,
    account
  }
}
