import {
  ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, FEEDBACK_INSTANCE,
  SIGNER_INSTANCE, REFERENCE_INSTANCE, EVENTCHAIN_INSTANCE
} from '@/helper/consts'
import Breadcrumbs from '@/modules/profile/Breadcrumbs'
import names from '@/modules/profile/routers/names'
import Worker from '@/modules/profile/worker/Worker'
import Education from '@/modules/profile/education/Education'
import Organization from '@/modules/profile/service/organization/Organization'
import OrganizationTree from '@/modules/profile/service/organization/OrganizationTree'
import Appeal from '@/modules/profile/service/appealDIT/Appeal'
import Signer from '@/modules/profile/service/signer/Signer'
import CorporateDirectory from "@/modules/profile/service/corporateDirectory/CorporateDirectory.vue"
import Session from '@/modules/profile/session/Session.vue'
import PersonalSignature from "@/modules/profile/service/personalSignature/PersonalSignature.vue"
export default ([
  {
    path: '',
    name: names.BASE,
    component: Breadcrumbs,
    meta: {
      breadcrumb: 'Главная страница',
      requiredAuth: false,
      instances: [
        ACCOUNTS_INSTANCE,
        EVENTCHAIN_INSTANCE
      ]
    },
    children: [
      {
        path: 'session',
        name: names.SESSION,
        component: Session,
        meta: {
          breadcrumb: 'Активные сессии',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        }
      },
      {
        path: 'education',
        name: names.STUDENT.MAIN,
        component: Education,
        meta: {
          breadcrumb: 'Образование',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        }
      },
      {
        path: 'worker',
        name: names.WORKER.MAIN,
        component: Worker,
        meta: {
          breadcrumb: 'Работник',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'organization',
        name: names.SERVICE.ORGANIZATION,
        component: Organization,
        meta: {
          breadcrumb: 'Информация об организации',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'tree',
        name: names.SERVICE.ORGANIZATION_TREE,
        component: OrganizationTree,
        meta: {
          breadcrumb: 'Структура подразделений',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'directory',
        alias: 'account/mailcontrol/reference',
        name: names.SERVICE.DIRECTORY,
        component: CorporateDirectory,
        meta: {
          breadcrumb: 'Корпоративный справочник',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            REFERENCE_INSTANCE
          ]
        }
      },
      {
        path: 'dit',
        name: names.SERVICE.DIT,
        component: Appeal,
        meta: {
          breadcrumb: 'Обращение в ДИТ',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            FEEDBACK_INSTANCE
          ]
        }
      },
      {
        path: 'signature',
        name: names.SERVICE.PERSONAL_SIGNATURE,
        component: PersonalSignature,
        meta: {
          breadcrumb: 'Подпись',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'signer',
        name: names.SERVICE.SIGNER,
        component: Signer,
        meta: {
          breadcrumb: 'ЭЦП',
          requiredAuth: false,
          instances: [
            ACCOUNTS_INSTANCE,
            SIGNER_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        }
      }

    ]
  }

])
