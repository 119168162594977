<template>
  <div >
    <PageHeader></PageHeader>
    <Account
        v-if="$route.name === names.BASE"
    ></Account>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import names from '@/modules/profile/routers/names'
import { getInstMenu, getLeftMenu, getInstNameByRouterName } from "@/helper/instances"

import Account from "./account/Account"
import { mapActions, mapGetters } from 'vuex'
import PageHeader from "@/modules/core/components/menus/PageHeader"
import store from '@/store'
import { checkIsDevelopmentServer } from "@/helper";

export default {
  name: "BreadcrumbsComponent",
  components: { PageHeader, Account },
  data () {
    return {
      user: null
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticate',
      accountAccounts: "selectors/accountAccounts",
    })
  },
  methods: {
    ...mapActions({
      loadAccountDetail: 'user/loadAccountDetail',
      loadAccountAccounts: 'selectors/loadAccountAccounts',
      connectJWT: 'eventchain/connectJWT',
    }),
    loadChoices () {
      this.loadAccountAccounts({
        url: undefined,
        finalizer: () => {},
      })
    },
    loadData (url = undefined) {
      this.loadAccountDetail({
        finalizer: (data, url) => {
          this.user = data
          let is_student = false
          if (this.user.is_student || this.user.is_graduate) {
            is_student = true
          }
          this.$store.commit('leftmenu/replaceItems', getLeftMenu(this.user.is_employee, is_student))
          this.$store.commit('leftmenu/replaceInstances', getInstMenu(this.user.is_employee, is_student))
        }
      })
    },
    loadLeftmenu () {
      this.$store.commit('mainpage/changeTitle', 'Мой профиль')
      this.$store.commit('leftmenu/replaceInstances', getInstMenu())
      this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.BASE))
      this.loadData()
    },
  },

  created () {
    if (this.isAuthenticated) {
      this.$store.commit('leftmenu/enableLeftMenu')
      if (this.$vuetify.display.smAndUp) {
        this.$store.commit('leftmenu/show')
      }
      this.loadLeftmenu()
      if (!this.accountAccounts) {
        this.loadChoices()
      }
      if (checkIsDevelopmentServer()) {
        this.connectJWT({
          finalizer: async (data, url) => {
            await store.dispatch('eventchain/initEvent', {})
          }
        })
      }
    }
  }
}
</script>

<style scoped>
    .border-right {
        border-right: 2px solid var(--steel-gray);
    }

</style>
