import worker from '@/urls/worker'
import accounts from "@/urls/accounts"
import {
  sendGetRequest, sendPatchRequest,
} from '@/helper/requests'
import { executeRequest } from '@/store/utils'
import organization from '@/store/modules/worker/organization'

const state = {
  worker: {
    workerDetail: null,
    positionHeld: null,
    loadingWorkerDetail: false,
    loadingPositionHeld: false,
    error: null
  },
}

const getters = {
  getWorkerDetail: state => state.worker.workerDetail,
  isLoadingWorkerDetail: state => state.worker.loadingWorkerDetail,
  getPositionHeld: state => state.worker.positionHeld,
  isLoadingPositionHeld: state => state.worker.loadingPositionHeld
}

const actions = {
  loadWorkerDetail: ({ commit }, { url, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_WORKERDETAIL_LOADING')
    if (!url) url = accounts.PROFILE.WORKER()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_WORKERDETAIL', data)
        commit('SET_WORKERDETAIL_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_WORKERDETAIL_ERROR', data)
        commit('SET_WORKERDETAIL_LOADING_END')
        catcher(data, url)
      }
    )
  },
  changeWorkerDetail: ({ dispatch, commit, getters }, { data, finalizer, catcher }) => {
    const url = worker.WORKER.UPDATE(data.id)
    executeRequest('WorkerDetail', sendPatchRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  },
  loadPositionHeldDetail: ({ commit }, { url, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_POSITIONHELD_LOADING')
    if (!url) url = worker.WORKER.POSITION_HELD_DETAIL()
    sendGetRequest(
      url, (data, url) => {
        data = data.sort(function (a, b) {
          if (a.is_fired > b.is_fired) {
            return 1
          }
          if (a.is_fired < b.is_fired) {
            return -1
          }
          return 0
        })
        commit('SET_POSITIONHELD', data)
        commit('SET_POSITIONHELD_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_WORKERDETAIL_ERROR', data)
        commit('SET_POSITIONHELD_LOADING_END')
        catcher(data, url)
      }
    )
  }

}

const mutations = {
  SET_WORKERDETAIL: (state, value) => {
    state.worker.workerDetail = value
  },
  SET_WORKERDETAIL_LOADING: (state) => {
    state.worker.loadingWorkerDetail = true
  },
  SET_WORKERDETAIL_LOADING_END: (state) => {
    state.worker.loadingWorkerDetail = false
  },
  SAVE_WORKERDETAIL_ERROR: (state, error) => {
    state.worker.error = error
  },
  SET_POSITIONHELD: (state, value) => {
    state.worker.positionHeld = value
  },
  SET_POSITIONHELD_LOADING: (state) => {
    state.worker.loadingPositionHeld = true
  },
  SET_POSITIONHELD_LOADING_END: (state) => {
    state.worker.loadingPositionHeld = false
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    organization,
  }
}
