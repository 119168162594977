import { sendGetRequest, sendPostRequest } from "@/helper/requests"
import reference from "@/urls/reference"
import { executeRequest } from '@/store/utils'

const state = {
  personal: {
    list: [],
    loading: null,
    count: 0,
  }
}

const getters = {
  getPersonalList: (state) => state.personal.list,
  getPersonalListCount: (state) => state.personal.count,
  isPersonalListLoading: (state) => state.personal.loading,
}

const actions = {
  loadPersonalList: ({ commit }, { url, urlAppend, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_LOADING')
    if (!url) {
      url = reference.CONTACTS.PERSONAL.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_PERSONAL_LIST', data.results)
        commit('SET_PERSONAL_COUNT', data.count)
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  makeReportProblem: ({ dispatch, commit, getters }, { id, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = reference.CONTACTS.PERSONAL.ISSUE(id)
    executeRequest('Personal', sendPostRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  },
}

const mutations = {
  SET_PERSONAL_LIST: (state, list) => {
    state.personal.list = list
  },
  SET_LOADING: (state) => {
    state.personal.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.personal.loading = false
  },
  SET_PERSONAL_COUNT: (state, count) => {
    state.personal.count = count
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
