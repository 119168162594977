<template>
  <v-list-item
    v-if="item.router"
    :class="{ 'current_menu__sub_item--active': checkActive(item) || checkIncluded(item) }"
    class="current_menu__sub_item"
    @click="changeRouter(item)"
    link
  >
    <v-list-item-title class="current_menu__sub_item__title text-body-1">
      {{ item.title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "CurrentLeftMenuSubAction",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const route = useRoute();
    const router = useRouter();

    const changeParam = (router) => {
      if (typeof (router) === "object") {
        return { name: router.name, params: router.params, query: router.query };
      } else {
        return { name: router };
      }
    };

    const changeRouter = (item) => {
      if (item.link) {
        window.open(item.link, '_blank');
        return;
      }
      if (!checkActive(item)) {
        router.push(changeParam(item.router));
      }
    };

    const checkRouteParams = (keys, verifiable, route) => {
      if (verifiable === undefined) return false;
      if (route === undefined) return false;
      for (const key of keys) {
        if (verifiable[key] !== route[key]) return false;
      }
      return true;
    };

    const checkIncluded = (item) => {
      if (item.included) {
        const checkInParents = (verifiable, matchedRoutes, params) => {
          for (let i = matchedRoutes.length - 1; i >= 0; i--) {
            const parent = matchedRoutes[i];
            if (verifiable.name === parent.name) {
              if (typeof item.included !== 'object') return true;
              else return checkRouteParams(item.included, verifiable.params, params);
            }
          }
          return false;
        };

        let verifiable;
        if (typeof item.router === 'object') {
          verifiable = item.router;
        } else {
          verifiable = { name: item.router };
        }
        return checkInParents(verifiable, route.matched, route.params);
      } else {
        return false;
      }
    };

    const checkActive = (item) => {
      const current = route;
      const verifiable = typeof item.router === 'object' ? item.router : { name: item.router, params: {} };
      if (verifiable.name !== current.name) return false;
      if (Object.keys(current.params).length > Object.keys(verifiable.params).length) return false;
      return checkRouteParams(Object.keys(current.params), current.params, verifiable.params);
    };

    return {
      changeParam,
      changeRouter,
      checkRouteParams,
      checkIncluded,
      checkActive
    };
  }
});
</script>

<style lang="scss">
.current_menu__sub_item {
  padding: 16px 24px !important;
  padding-inline-start: 24px !important;
  height: 50px;
  background: var(--background-white);

  &:hover {
    background: var(--gray-shades-hover);
  }

  &--active {
    background: var(--gray-shades-focus, #F6F6F6);
  }
}

.current_menu__sub_item__title {
  text-align: left;
  color: var(--blue-shades-dark);
}
</style>
