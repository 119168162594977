<template>
  <v-container :fluid="true" style="width: 100%" v-if="isActive">
    <v-card
      class="rounded-lg mb-4"
    >
      <v-toolbar
        class="bg-gray-light text-left pl-4 pr-1"
        density="comfortable"
      >
        <v-text-field
          v-model="search.value"
          variant="outlined"
          label="Поиск"
          :placeholder="$vuetify.display.smAndUp ? 'Для поиска нажмите Enter' : 'Поиск'"
          class="mb-n6 dark-primary"
          density="compact"
          @keydown.enter="searchData"
          clearable
          @click:clear="$nextTick(() =>{ this.loadData() })"
        >
        </v-text-field>
        <v-spacer/>
        <v-toolbar-items>
          <v-tooltip location="bottom" text="Посмотреть инструкцию">
            <template v-slot:activator="{ props }">
              <v-btn
                flat icon v-bind="props"
                class="dark-primary"
                href="https://r.muctr.ru/portal/worker"
                target="_blank"
              >
                <v-icon>info_outline</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip location="bottom" text="Нажмите для начала процесса подписания">
            <template v-slot:activator="{ props }">
              <v-btn flat v-bind="props"  @click="signatureFile"  class="dark-primary">
                <v-icon>verified</v-icon>
                <span class="ml-2" v-if="$vuetify.display.smAndUp">Подписать файл ЭЦП</span>
              </v-btn>
            </template>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="text-left pt-2 text-body-1">
        <v-data-table-server
          v-model:items-per-page="pagination.size"
          :items-per-page-options="[{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}]"
          v-model:page="pagination.page"
          :headers="headers"
          :items-length="count"
          :items="items"
          :loading="isLoading"
          :loading-text="'Подождите идет загрузка данных...'"
          :color="'#5e9de0'"
          :theme="'#5e9de0'"
          :search="search"
        >
          <template v-slot:item.actions="{item}">
            <v-tooltip location="bottom" text="Скачать файл">
              <template v-slot:activator="{ props }">
                <v-btn
                  flat icon v-bind="props"
                  class="dark-primary"
                  style="background: none !important;"
                  @click="downloadFile(item)"
                >
                  <v-icon>cloud_download</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-menu location="center">
              <template v-slot:activator="{ props: menu }">
                <v-tooltip location="bottom" text="Переслать на электронную почту">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn
                      flat icon
                      class="dark-primary"
                      style="background: none !important;"
                      v-bind="mergeProps(menu, tooltip)"
                    >
                      <v-icon>outgoing_mail</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(list_item, index) in signerRecipients"
                  :key="index"
                  @click="sendMail(list_item, item)"
                >
                  <v-list-item-title>{{ list_item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:loader>
            <v-progress-linear
              :indeterminate="true"
              color="#5e9de0"
            ></v-progress-linear>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <signature-dialog
      v-if="dialog_signature"
      v-model="dialog_signature"
      @save="acceptSignatureDialog"
      @close="dialog_signature=false"
    >
    </signature-dialog>
    <send-email-dialog
      v-if="dialog_send"
      v-model="dialog_send"
      :files="send_files"
      :recipient="send_recipient"
      :append-file="true"
      @save="closeSendMailDialog"
      @close="dialog_send=false"
    >
    </send-email-dialog>
  </v-container>
  <v-container style="" v-else>
   <DisableServices></DisableServices>
  </v-container>

</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin"
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import { mapActions, mapGetters } from "vuex"
import router from '@/router'
import names from '@/modules/profile/routers/names'
import { mergeProps } from 'vue'
import SignatureDialog from "@/modules/profile/service/signer/modals/SignatureDialog.vue"
import SendEmailDialog from "@/modules/profile/service/signer/modals/SendEmailDialog.vue"
import store from '@/store'
import { SIGNER_INSTANCE } from '@/helper/consts'
import DisableServices from '@/modules/templates/DisableServices.vue'

export default {
  name: "SignerPage",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {
    SignatureDialog,
    SendEmailDialog,
    DisableServices
  },
  data: function () {
    return {
      dialog_signature: false,
      dialog_send: false,
      send_files: undefined,
      send_recipient: undefined,
      download_error: false,
      page_title: "Подпись ЭЦП",
      copy: false,
      search: {
        parameter: 'file_name',
      },
      headers: [
        { title: 'Файл', value: 'file_name', align: 'start', sortable: false, width: 300 },
        { title: 'Дата и время загрузки', value: 'loaded', align: 'start', sortable: false, width: 140 },
        { title: 'Действия', value: 'actions', align: 'center', sortable: false, width: 80 },
      ],
    }
  },
  watch: {},
  computed: {
    isActive () {
      return store.getters['urls/checkAlreadyLoaded'](SIGNER_INSTANCE)
    },
    ...mapGetters({
      items: 'signer/getSigner',
      count: 'signer/getSignerCount',
      isLoading: 'signer/isLoadingSigner',
      signerRecipients: "selectors/signerRecipients",
    })
  },
  methods: {
    ...mapActions({
      loadSignerList: 'signer/loadSignerList',
      saveFile: 'signer/saveFile',
      loadSignerRecipients: "selectors/loadSignerRecipients",
    }),
    mergeProps,
    loadData (url = undefined) {
      this.loadSignerList({
        url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: () => {
          router.push({ name: names.BASE })
        }
      })
    },
    loadChoices () {
      this.loadSignerRecipients({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    downloadFile (item) {
      this.saveFile({
        url: item.file.file,
        id: item.id,
        finalizer: (data, url) => {
          const a = document.createElement("a")
          a.href = URL.createObjectURL(data)
          a.download = item.file_name
          a.click()
        },
        catcher: (val) => {},
      })
    },
    signatureFile () {
      this.dialog_signature = true
    },
    acceptSignatureDialog () {
      this.loadData()
    },
    sendMail (list_item, doc) {
      this.dialog_send = true
      this.send_files = doc
      this.send_recipient = list_item
    },
    closeSendMailDialog () {
      this.dialog_send = false
      this.send_files = undefined
      this.send_recipient = undefined
    },
  },
  created () {
    if (this.isActive) {
      this.loadData()
      if (this.signerRecipients.length === 0) {
        this.loadChoices()
      }
    }
  }
}
</script>

<style>
</style>
