<template>
  <scroll-center-dialog v-model="open" :loading="save_loading" width="760" @close="close" @apply="save" :title="form_title" :scrolled="false">
    <v-text-field
      class="mt-4 py-1"
      v-model="advanced_training.title"
      label="Название программы *"
      placeholder="Введите название программы"
      variant="outlined"
      :error="hasError('title')"
      :error-messages="getError('title')"
    ></v-text-field>
    <v-textarea
      class="py-1"
      v-model="advanced_training.description"
      label="Описание *"
      placeholder="Введите описание программы"
      variant="outlined"
      rows="3"
      :auto-grow="true"
      :error="hasError('description')"
      :error-messages="getError('description')"
    ></v-textarea>
    <v-select
      class="py-1"
      v-model="advanced_training.work_type"
      :items="advancedTrainingWorkType"
      item-title="text"
      item-value="value"
      label="Тип пройденной программы"
      no-data-text="Нет подходящих данных"
      variant="outlined"
      :error="hasError('work_type')"
      :error-messages="getError('work_type')"
    ></v-select>
    <v-select
      class="py-1"
      v-model="advanced_training.knowledge_field"
      :items="advancedTrainingKnowledgeType"
      item-title="text"
      item-value="value"
      label="Сфера"
      no-data-text="Нет подходящих данных"
      variant="outlined"
      :error="hasError('knowledge_field')"
      :error-messages="getError('knowledge_field')"
    ></v-select>
    <v-select
      class="py-1"
      v-model="advanced_training.document_type"
      :items="advancedTrainingDocumentType"
      item-title="text"
      item-value="value"
      label="Тип документа"
      no-data-text="Нет подходящих данных"
      variant="outlined"
      :error="hasError('document_type')"
      :error-messages="getError('document_type')"
    ></v-select>
    <v-text-field
      class="py-1"
      v-model="advanced_training.number"
      label="Номер документа"
      placeholder="Введите номер документа"
      variant="outlined"
      :error="hasError('number')"
      :error-messages="getError('number')"
    ></v-text-field>
    <v-text-field
      class="py-1"
      v-model="advanced_training.volume"
      label="Объем пройденной программы"
      placeholder="Введите объем пройденной программы"
      variant="outlined"
      type="number"
      :error="hasError('volume')"
      :error-messages="getError('volume')"
    ></v-text-field>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="advanced_training.start"
          class="pr-md-2 py-1"
          label="Дата начала программы"
          placeholder="ДД.ММ.ГГГГ"
          type="date"
          variant="outlined"
          :error="hasError('start')"
          :error-messages="getError('start')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="advanced_training.end"
          class="pl-md-2 py-1"
          label="Дата окончания программы"
          placeholder="ДД.ММ.ГГГГ"
          type="date"
          variant="outlined"
          :error="hasError('end')"
          :error-messages="getError('end')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-file-input
      class="py-1"
      v-model="advanced_training.file"
      counter
      label="Выберите файл (.pdf) *"
      placeholder="Выберите файл (.pdf)"
      prepend-icon="attach_file"
      :multiple="true"
      variant="outlined"
      accept=".pdf"
      :show-size="1000"
      :error="hasError('file')"
      :error-messages="getError('file')"
    >
      <template v-slot:selection="{ fileNames }">
        <template v-for="(fileName, index) in fileNames" :key="`${index}_file_chip`">
          <v-chip
            label
            size="small"
            :class="this.errors.fields.file ? 'mr-2 bg-red-darken-2' : 'mr-2 medium-primary'"
          >
            {{ fileName }}
          </v-chip>
        </template>
      </template>
    </v-file-input>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AdvancedTrainingEditDialog",
  props: {
    data: Object,
    value: Boolean,
    edit: Boolean,
  },
  components: {
    ScrollCenterDialog,
  },
  mixins: [FormErrorsMixin],
  data () {
    return {
      open: false,
      save_loading: false,
      change_file: false,
      advanced_training: {
        title: '',
        description: '',
        work_type: 'Иное',
        knowledge_field: 'Иное',
        document_type: 'Иное',
        number: '',
        volume: 0,
        start: '',
        end: '',
        file: [],
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    },
    advanced_training: {
      handler: function () {
        this.errors = {
          fields: {},
          non: []
        }
      },
      deep: true
    }
  },
  computed: {
    form_title () {
      return this.edit ? 'Редактировать повышение квалификации' : 'Добавить повышение квалификации'
    },
    ...mapGetters({
      advancedTrainingWorkType: 'choices/advancedTrainingWorkType',
      advancedTrainingDocumentType: 'choices/advancedTrainingDocumentType',
      advancedTrainingKnowledgeType: 'choices/advancedTrainingKnowledgeType',
    })
  },
  methods: {
    ...mapActions({
      createAdvancedTraining: 'education/createAdvancedTraining',
      changeAdvancedTraining: 'education/changeAdvancedTraining',
      loadAdvancedTrainingDocumentType: "choices/loadAdvancedTrainingDocumentType",
      loadAdvancedTrainingWorkType: "choices/loadAdvancedTrainingWorkType",
      loadAdvancedTrainingKnowledgeType: "choices/loadAdvancedTrainingKnowledgeType",
    }),
    loadData () {
      if (this.data !== undefined) {
        this.advanced_training = JSON.parse(JSON.stringify(this.data))
        this.advanced_training.file = [{ name: 'Загруженный файл', url: this.advanced_training.file, is_string: true }]
      }
    },
    loadChoices () {
      this.loadAdvancedTrainingWorkType({
        url: undefined,
        finalizer: (val) => {},
      })
      this.loadAdvancedTrainingDocumentType({
        url: undefined,
        finalizer: (val) => {},
      })
      this.loadAdvancedTrainingKnowledgeType({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.save_loading = true

      const sendData = new FormData()
      sendData.append('title', this.advanced_training.title)
      sendData.append('description', this.advanced_training.description)
      sendData.append('work_type', this.advanced_training.work_type)
      sendData.append('knowledge_field', this.advanced_training.knowledge_field)
      sendData.append('document_type', this.advanced_training.document_type)
      sendData.append('number', this.advanced_training.number)
      if (this.advanced_training.start !== null) {
        sendData.append('start', this.advanced_training.start)
      }
      if (this.advanced_training.end !== null) {
        sendData.append('end', this.advanced_training.end)
      }

      if ((typeof this.advanced_training.file === 'object' && this.advanced_training.file.length > 0) && !this.advanced_training.file[0].is_string) {
        sendData.append('file', this.advanced_training.file[0], this.advanced_training.file[0].name)
      }

      if (this.edit) {
        this.changeAdvancedTraining({
          id: this.advanced_training.id,
          data: sendData,
          finalizer: (val) => {
            this.$emit('save')
            this.save_loading = false
          },
          catcher: (val) => {
            this.mapErrors(val)
            this.save_loading = false
          },
        })
      } else {
        this.createAdvancedTraining({
          data: sendData,
          finalizer: (val) => {
            this.$emit('save')
            this.save_loading = false
          },
          catcher: (val) => {
            this.mapErrors(val)
            this.save_loading = false
          },
        })
      }
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.loadData()
    if (this.advancedTrainingWorkType.length === 0 || this.advancedTrainingDocumentType.length === 0 || this.advancedTrainingKnowledgeType.length === 0) {
      this.loadChoices()
    }
  }
}
</script>

<style scoped>

</style>
