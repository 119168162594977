<template>
  <v-card>
    <v-container fluid class="pa-0">
      <v-row dense no-gutters class="service__container">
        <v-col
          v-for="(item) in items"
          :key="item"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="service__col"
        >
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              :height="$vuetify.display.width > 600 ? '390px' : '375px'"
              v-bind="props"
              rounded="0"
              :elevation="isHovering ? 16 : 0"
              class="service__card py-12 px-7 px-md-12"
              @click="goToPath(item)"
            >
              <v-img
                :src="item.src"
                contain
                flat
                aspect-ratio="1/1"
                :max-height="$vuetify.display.width > 600 ? 120 : 105"
              />
              <div class="service__description">
                <div class="service__title">
                  {{ item.name }}
                </div>
                <v-divider class="service__divider"/>
                <div class="service__text">
                  {{ item.text }}
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'ServicesPage',
  data () {
    return {
      items: [
        {
          name: 'Официальный сайт',
          text: 'Официальный сайт Российского химико-технологического университета им. Д.И. Менделеева',
          url: 'https://muctr.ru/',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/official_site.svg',
          route: '',
        },
        {
          name: 'Электронная информационно-образовательная среда',
          text: 'Теперь вся информация об учебном процессе в одном месте',
          url: 'https://eios.muctr.ru/',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/eios.svg',
          route: '',
        },
        {
          name: 'Информационно-библиотечный центр',
          text: 'Информационная поддержка образовательного процесса и научно-исследовательской деятельности\n' +
            '                        университета',
          url: 'https://lib.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/library.svg',
          route: '',
        },
        {
          name: 'Оплата',
          text: 'Онлайн-сервис оплаты услуг университета',
          url: 'https://pay.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/pay.svg',
          route: '',
        },
        {
          name: 'База знаний',
          text: 'Информация и инструкции пользования веб-сервисами университета',
          url: 'https://wiki.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/wiki.svg',
          route: '',
        },
        {
          name: 'Почтовый справочник',
          text: 'Справочник корпоративных почт',
          url: 'https://portal.muctr.ru/directory',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/corporate_directory.svg',
          route: '',
        },
        {
          name: 'Корпоративная почта',
          text: 'Почтовый веб-интерфейс для использования корпоративной почты',
          url: 'https://post.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/post.svg',
          route: '',
        },
        {
          name: 'Cервис обмена сообщениями',
          text: 'Система для для обмена сообщениями в реальном времени через Интернет.',
          url: 'https://messaging.muctr.ru/',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/messaging.svg',
          route: '',
        },
        {
          name: 'Cервис проведения опросов',
          text: 'Система анкетирования с широким функционалом',
          url: 'https://survey.muctr.ru/',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/survey.svg',
          route: '',
        },
        {
          name: 'Материальная помощь',
          text: 'Оформление заявок на материальную помощь и санатарий-профилакторий',
          url: 'https://queue.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/equeue.svg',
          route: '',
        },
        {
          name: 'Учебный портал',
          text: 'Онлайн-поддержка курсов, читаемых в университете',
          url: 'https://study.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/study.svg',
          route: '',
        },

        {
          name: 'Диссертации РХТУ',
          text: ' Электронная библиотека диссертаций, защищаемых в университете',
          url: 'https://diss.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/dissertation.svg',
          route: '',
        },
        {
          name: 'Система учета деятельности диссертационных советов',
          text: 'Аттестационные дела соискателей ученой степени кандидата наук, доктора наук, зашищающихся в университете',
          url: 'https://council.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/dissovet.svg',
          route: '',
        },
        {
          name: 'Система тестирования',
          text: ' Тестирование и оценка знаний по разным дисциплинам',
          url: 'https://exam.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/testing.svg',
          route: '',
        },
        {
          name: 'Антиплагиат',
          text: 'Cистема обнаружения текстовых заимствований',
          url: 'http://antiplagiat.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/antiplagiat.svg',
          route: '',
        },
        {
          name: 'Межрегиональная олимпиада',
          text: 'Химическая олимпиада школьников им. академика П.Д. Саркисова',
          url: 'http://olimp.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/region_olympiad.svg',
          route: '',
        },
        {
          name: 'Справочник первокурсника',
          text: 'Помощь первокурсникам в поиске информации, связанной со студенческой жизнью',
          url: 'https://welcomebook.muctr.ru/',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/welcomebook.svg',
          route: '',
        },
        {
          name: 'Сервис заказа и учёта пропусков',
          text: 'Сервис для автоматизации процесса оформления заявок, а также контроля входа и выхода посетителей Университета',
          url: 'http://passorder.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/passorder.svg',
          route: '',
        },
        {
          name: 'Cервис ознакомления с документами',
          text: 'Cервис ознакомления пользователей с документами и распоряжениями публикуемые Университетом',
          url: 'http://confirmation.muctr.ru',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/confirmation.svg',
          route: '',
        },
        {
          name: 'Простая электронная подпись',
          text: 'Сервис для подписания служебных документов в электронном виде простой неквалифицированной электронной подписью',
          url: 'https://portal.muctr.ru/signer',
          src: 'https://www.muctr.ru/upload/university/it-resources/services/signer.svg',
          route: '',
        }
      ]
    }
  },
  created () {
    this.$store.commit('leftmenu/dissable')
    this.$store.commit('leftmenu/replaceItems', [])
  },
  methods: {
    goToPath (path) {
      if (path.url !== '') {
        window.open(path.url)
      } else {
        this.$router.push({ name: path.route })
      }
    },

  }
}
</script>

<style lang="scss">
.service__card:hover {
  color: #dadada;

  .service__title {
    color: #1D71B8;
  }

  .service__divider {
    color: #1D71B8;
    background: #1D71B8;
  }
}

.service__description {
  margin-top: 50px;
  text-align: left;
}

.service__title {
  margin-bottom: 25px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.service__divider {
  padding-bottom: 1px;
  background: #CECECE;
  color: #CECECE;
  opacity: 1 !important;
}

.service__text {
  margin-top: 10px;
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 500px){
  .service__title {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .service__text {
    line-height: 18px;
    -webkit-line-clamp: 3;
  }
}

@media screen and (max-width: 959px){
  .service__container {
    :nth-child(2n).service__col {
      .service__card {
        background: #F6F6F6;
      }
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px){
  .service__container {
    :nth-child(4n-3).service__col {
      .service__card {
        background: #F6F6F6;
      }
    }
  }

  .service__container {
    :nth-child(4n).service__col {
      .service__card {
        background: #F6F6F6;
      }
    }
  }

  .service__text {
    -webkit-line-clamp: 3;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px){
  .service__container {
    :nth-child(even).service__col {
      .service__card {
        background: #F6F6F6;
      }
    }
  }

  .service__text {
    -webkit-line-clamp: 3;
  }
}

@media screen and (min-width: 1920px){
  :nth-child(2).service__col, :nth-child(4).service__col, :nth-child(5).service__col,
  :nth-child(7).service__col, :nth-child(10).service__col, :nth-child(12).service__col,
  :nth-child(13).service__col,:nth-child(15).service__col, :nth-child(18).service__col,
  :nth-child(20).service__col, :nth-child(21).service__col, :nth-child(23).service__col,
  :nth-child(26).service__col, :nth-child(28).service__col, :nth-child(29).service__col,
  :nth-child(31).service__col, :nth-child(34).service__col, :nth-child(36).service__col {
    .service__card {
      background: #F6F6F6;
    }
  }
}
</style>
