<template>
  <v-card class="rounded-lg mt-4">
    <v-toolbar
      :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
      :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
    >
      <span class="dark-primary"> Список повышений квалификаций </span>
      <v-spacer/>
      <v-toolbar-items>
        <v-tooltip location="bottom" text="Добавить">
          <template v-slot:activator="{ props }">
            <v-btn flat icon v-bind="props"  @click="createAdvancedTraining"  class="dark-primary" :disabled="!!items ? false : true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="pa-0" v-if="!!items">
      <v-table v-if="items.length > 0">
        <thead>
        <tr>
          <th class="text-center">Название</th>
          <th class="text-center">Описание</th>
          <th class="text-center">Тип пройденной программы</th>
          <th class="text-center">Сфера</th>
          <th class="text-center">Тип документа</th>
          <th class="text-center">Номер</th>
          <th class="text-center">Объем пройденной программы</th>
          <th class="text-center">Дата начала</th>
          <th class="text-center">Дата окончания</th>
          <th class="text-center">Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.work_type }}</td>
          <td>{{ item.knowledge_field }}</td>
          <td>{{ item.document_type }}</td>
          <td>{{ item.number }}</td>
          <td>{{ item.volume }}</td>
          <td v-if="item.start">{{ displayDate(item.start) }}</td>
          <td v-else>-</td>
          <td v-if="item.end">{{ displayDate(item.end) }}</td>
          <td v-else>-</td>
          <td style="min-width: 180px">
            <v-tooltip location="bottom" text="Просмотреть загруженный файл">
              <template v-slot:activator="{ props }">
                <v-btn flat icon v-bind="props" :href="item.file" target="_blank" class="dark-primary -blank">
                  <v-icon>cloud_download</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="bottom" text="Редактировать">
              <template v-slot:activator="{ props }">
                <v-btn flat icon v-bind="props"  @click="editAdvancedTraining(item)"  class="dark-primary">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="bottom" text="Удалить">
              <template v-slot:activator="{ props }">
                <v-btn flat icon v-bind="props"  @click="deleteAdvancedTrainingDialog(item)"  class="dark-primary">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </td>
        </tr>
        </tbody>
      </v-table>
      <v-row no-gutters justify="center" align="center" style="min-height: 120px" v-else>
        <v-col cols="12">
          <v-icon size="40" class="medium-primary">inventory</v-icon>
        </v-col>
        <v-col cols="12" class="mt-n8">
          <span class="text-body-1 medium-primary bold">Нет данных</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row no-gutters justify="center" v-else>
      <semipolar-spinner
        :animation-duration="2000"
        :size="$vuetify.display.mdAndUp ? 150 : 60"
        color="#1976D2"
        class="ma-12"
      />
    </v-row>
  </v-card>

  <advanced-training-edit-dialog
    v-if="dialog_edit"
    v-model="dialog_edit"
    :data="edit_data"
    :edit="edit_status"
    @save="saveAdvancedTrainingEdit"
    @close="dialog_edit=false"
  >
  </advanced-training-edit-dialog>
  <apply-dialog
    v-if="dialog_delete"
    v-model="dialog_delete"
    :get-text="() => `Вы уверены, что хотите удалить?`"
    @close="closeDeleteDialog"
  ></apply-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { displayDate } from "@/helper"
import AdvancedTrainingEditDialog from "../modals/AdvancedTrainingEditDialog.vue"
import ApplyDialog from "@/modules/templates/ApplyDialog.vue"
import names from "@/modules/profile/routers/names"
import { makeGetRequest } from '@/helper/requests'
import { SemipolarSpinner } from "epic-spinners"

export default {
  name: "AdvancedTraining",
  components: { AdvancedTrainingEditDialog, ApplyDialog, SemipolarSpinner },
  data: function () {
    return {
      dialog_edit: false,
      dialog_delete: false,
      edit_data: undefined,
      edit_status: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'education/getAdvancedTrainingList'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadAdvancedTraining: 'education/loadAdvancedTraining',
      deleteAdvancedTraining: 'education/deleteAdvancedTraining',
    }),
    loadData (url = undefined) {
      this.loadAdvancedTraining({
        url,
        finalizer: (data, url) => {},
        catcher: () => {
          // router.push({ name: names.BASE })
        }
      })
    },
    displayDate,
    createAdvancedTraining () {
      this.edit_data = undefined
      this.edit_status = false
      this.dialog_edit = true
    },
    editAdvancedTraining (item) {
      this.edit_data = item
      this.edit_status = true
      this.dialog_edit = true
    },
    deleteAdvancedTrainingDialog (item) {
      this.dialog_delete = true
      this.edit_data = item
    },
    closeDeleteDialog (event) {
      if (event) {
        this.deleteAdvancedTraining({
          data: this.edit_data,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.edit_data = undefined
    },
    saveAdvancedTrainingEdit () {
      this.edit_data = undefined
      this.edit_status = false
      this.dialog_edit = false
      this.loadData()
    },
  },
  created () {
    if (!this.items) {
      if (!this.$store.getters['urls/checkAlreadyLoaded']('education')) {
        makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + '?instances=education')
          .then(resp => resp.json())
          .then(data => {
            this.$store.commit('urls/appendUrls', data)
            this.loadData()
          })
      } else {
        this.loadData()
      }
    }
  }
}
</script>

<style scoped>

</style>
