import { createApp } from 'vue'

import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/index'
import '../public/css/main.css'

import breadcrumbs from 'vue-3-breadcrumbs'
import VueYandexMetrika from 'vue-yandex-metrika-v3/dist/vue-yandex-metrika-v3'
import VueCookie from 'vue3-cookies'

import { checkIsProductionServer } from '@/helper'

const app = createApp({})

app.use(VueCookie)

app.config.productionTip = false

if (checkIsProductionServer()) {
  app.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YandexMetrika,
    router,
    env: process.env.NODE_ENV
    // other options
  })
}

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(breadcrumbs, {
    includeComponent: true // {boolean} [includeComponent=false] - Include global breadcrumbs component or not
  })
  .mount('#app')
