import helper from './helper'

const getReferenceUrlWithParameters = helper.getReferenceUrlWithParameters
export default {
  getReferenceUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getReferenceUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  CONTACTS: {
    PERSONAL: {
      LIST: (gets = null) => getReferenceUrlWithParameters(
        'employee|contacts|personal-list',
        'list',
        null,
        gets
      ),
      ISSUE: (id, gets = null) => getReferenceUrlWithParameters(
        'employee|contacts|personal-issue',
        'issue',
        { id },
        gets
      ),
    },
  },
}
