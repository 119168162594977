<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66659 18.3334C3.16242 18.3334 2.73082 18.1538 2.37179 17.7948C2.01277 17.4358 1.83325 17.0042 1.83325 16.5V5.50002C1.83325 4.99585 2.01277 4.56426 2.37179 4.20523C2.73082 3.8462 3.16242 3.66669 3.66659 3.66669H18.3333C18.8374 3.66669 19.269 3.8462 19.628 4.20523C19.9871 4.56426 20.1666 4.99585 20.1666 5.50002V16.5C20.1666 17.0042 19.9871 17.4358 19.628 17.7948C19.269 18.1538 18.8374 18.3334 18.3333 18.3334H3.66659ZM10.9999 11.9167L3.66659 7.33335V16.5H18.3333V7.33335L10.9999 11.9167ZM10.9999 10.0834L18.3333 5.50002H3.66659L10.9999 10.0834ZM3.66659 7.33335V5.50002V16.5V7.33335Z" fill="#0563C1"/>
  </svg>
</template>

<script>
export default {
  name: 'MailIcon'
}
</script>

<style scoped>

</style>
