const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '')
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '')
const ACCOUNT_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '')
const ACCOUNTS_INSTANCE = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '')
const ORGANIZATION_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ORGANIZATION_PREFIX.replace('/', '')
const ORGANIZATION_INSTANCE = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '')
const EDUCATION_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace('/', '')
const EDUCATION_INSTANCE = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '')
const SIGNER_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_SIGNER_PREFIX.replace('/', '')
const SIGNER_INSTANCE = String(process.env.VUE_APP_SIGNER_PREFIX).replace('/', '')
const FEEDBACK_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_FEEDBACK_PREFIX.replace('/', '')
const FEEDBACK_INSTANCE = String(process.env.VUE_APP_FEEDBACK_PREFIX).replace('/', '')
const MAILCONTROL_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_MAILCONTROL_INSTANCE_NAME.replace('/', '')
const MAILCONTROL_INSTANCE = process.env.VUE_APP_MAILCONTROL_INSTANCE_NAME.replace('/', '')
const EVENTCHAIN_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EVENTCHAIN_PREFIX.replace('/', '')
const EVENTCHAIN_INSTANCE = String(process.env.VUE_APP_EVENTCHAIN_PREFIX).replace('/', '')
const REFERENCE_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_REFERENCE_PREFIX).replace('/', '')
const REFERENCE_INSTANCE = String(process.env.VUE_APP_REFERENCE_PREFIX).replace('/', '')
export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#%&*+\-.:<=>?@^_|~])[0-9a-zA-Z!#%&*+\-.:<=>?@^_|~]{12,255}$/
const SOCKET_BASE_HOST = String(process.env.VUE_APP_SOCKET_BASE_HOST)
const EIOS_LINK = process.env.VUE_APP_EIOS_LINK
const MESSAGE_LINK = process.env.VUE_APP_MSG_LINK
const PASSORDER_LINK = process.env.VUE_APP_PASSORDER_LINK
const CONF_LINK = process.env.VUE_APP_CONF_LINK
const ADMIN_LINK = process.env.VUE_APP_ADMIN_LINK

export {
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  ORGANIZATION_HOST,
  ORGANIZATION_INSTANCE,
  EDUCATION_INSTANCE,
  EDUCATION_HOST,
  SIGNER_INSTANCE,
  SIGNER_HOST,
  FEEDBACK_INSTANCE,
  FEEDBACK_HOST,
  MAILCONTROL_INSTANCE,
  MAILCONTROL_HOST,
  REFERENCE_INSTANCE,
  REFERENCE_HOST,
  EIOS_LINK,
  MESSAGE_LINK,
  PASSORDER_LINK,
  ADMIN_LINK,
  EVENTCHAIN_HOST,
  EVENTCHAIN_INSTANCE,
  CONF_LINK,
  SOCKET_BASE_HOST
}
export default {
  accounts,
  education
}
