// import { useCookies } from 'vue3-cookies'
//
// const { cookies } = useCookies()
//
// function get_domain () {
//   if (import.meta.env.VITE_VUE_APP_COOKIE_DOMAIN !== undefined) {
//     return import.meta.env.VITE_VUE_APP_COOKIE_DOMAIN
//   } else {
//     return null
//   }
// }

export class SocketV2 {
  socket = null
  url = null
  handlers
  onClose
  onMessage
  onError
  onOpen

  constructor ({
    url,
    handlers,
    onOpen = () => {
    },
    onClose = () => {
    },
    onMessage = () => {
    },
    onError = () => {
    }
  }) {
    this.url = url
    this.handlers = handlers
    this.onOpen = onOpen
    this.onClose = onClose
    this.onMessage = onMessage
    this.onError = onError
  }

  setOnOpen (cb = null) {
    this.socket.onopen = () => {
      if (cb) {
        cb(this.socket)
      } else {
        this.onOpen(this.socket)
      }
    }
  }

  setOnMessage (cb = null) {
    this.socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data)
        if (this.handlers[message.type]) {
          this.handlers[message.type]({
            data: message,
          })
        }
        if (cb) {
          cb(this.socket, message)
        } else {
          this.onMessage(this.socket, message)
        }
      } catch (e) {
        console.error('Error parsing message:', e)
      }
    }
  }

  setOnError (cb = null) {
    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      if (error instanceof Event && error.target instanceof WebSocket) {
        console.error('WebSocket error details:', error.target.readyState, error.target.url)
        this.socket = null
      }
      if (cb) {
        cb(this.socket, error)
      } else {
        this.onError(this.socket, error)
      }
    }
  }

  setOnClose (cb = (socket, error) => {
  }) {
    this.socket.onclose = (event) => {
      if (cb) {
        cb(this.socket, event)
      } else {
        this.onClose(this.socket, event)
      }
      // window.location.reload()
    }
  }

  isConnected () {
    return this.socket !== null && this.socket !== undefined
  }

  connect ({
    url = null,
  }) {
    return new Promise((resolve, reject) => {
      if (url) {
        this.url = url
      }
      this.socket = new WebSocket(this.url)
      this.setOnOpen((socket) => {
        resolve(socket)
        this.onOpen()
      })
      this.setOnClose(this.onClose)
      this.setOnMessage(this.onMessage)
      // eslint-disable-next-line n/handle-callback-err
      this.setOnError((err) => {
        reject(reject)
        this.onError()
      })
    })
  }

  disconnect (onclose = () => {
  }) {
    if (this.socket) {
      this.setOnClose(onclose)
      this.socket.close()
    }
  }

  disconnectWithClearSocket (onclose = () => {
  }) {
    if (this.socket) {
      this.setOnClose(onclose)
      this.socket.close()
      this.socket = null
    }
  }

  addHandler (handlerName, handler) {
    this.handlers[handlerName] = handler
  }

  addHandlers (handlers) {
    this.handlers = { ...this.handlers, ...handlers }
  }

  replaceHandlers (handlers) {
    this.handlers = handlers
  }

  sendMessage (message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message))
    } else {
      console.error('WebSocket is not open. Message not sent:', message)
    }
  }

  showNotification ({
    title,
    text,
    type
  }) {
    window.dispatchEvent(new CustomEvent('show-notification', {
      detail: {
        title,
        text,
        type
      }
    }))
  }
}

export const MESSAGES_TYPES = {
  AUTH: 'auth',
  NEW_EVENT: 'new_event',
  OLD_MESSAGES: 'old_messages',
  HEARTBEAT: 'heartbeat',
  RECEIVE_MESSAGE: 'receive_message',
  RECEIVE_ALL_MESSAGE: 'receive_all_message',
  REACTION_MESSAGE: 'reaction_message',
  ACCEPT: 'accept',
  ERROR: 'error',
}
