import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { aliases, md } from 'vuetify/iconsets/md'
import { en, ru } from "vuetify/locale"

import { createVuetify } from 'vuetify'
import TelephoneIcon from '@/assets/custom_icons/TelephoneIcon.vue'
import PhoneIcon from '@/assets/custom_icons/PhoneIcon.vue'
import PersonCheckIcon from '@/assets/custom_icons/PersonCheckIcon.vue'
import PeopleIcon from '@/assets/custom_icons/PeopleIcon.vue'
import MailIcon from '@/assets/custom_icons/MailIcon.vue'
import ImageIcon from '@/assets/custom_icons/ImageIcon.vue'
import HouseIcon from '@/assets/custom_icons/HouseIcon.vue'
import CaseIcon from '@/assets/custom_icons/CaseIcon.vue'

import "@fontsource/inter"
import "@fontsource/inter/400.css" // Specify weight
import "@fontsource/inter/500.css" // Specify weight and style
import "@fontsource/inter/600.css" // Specify weight and style
import "@fontsource/inter/700.css" // Specify weight and style
import "@fontsource/inter/800.css" // Specify weight and style
import "@fontsource/inter/900.css" // Specify weight and style
import '@/styles/settings.scss'

const customAliases = {
  ...aliases,
  TelephoneIcon,
  PhoneIcon,
  PersonCheckIcon,
  PeopleIcon,
  MailIcon,
  ImageIcon,
  HouseIcon,
  CaseIcon
}

export default createVuetify({
  icons: {
    defaultSet: 'md',
    aliases: {
      ...customAliases,
    },
    sets: {
      md
    }
  },
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { ru, en }
  },
  theme: {
    themes: {
      light: {
        // dark: false,
        colors: {
          darkprimary: '#366091',
          primary: '#1D71B8',
          lightprimary: '#E4EEF6',
          extralightprimary: '#F1F5F8',
          darksecondary: '#767676',
          secondary: '#9D9D9D',
          lightsecondary: '#AEAEAE',
          white: '#FFFFFF',
        }
      }
    }
  }
}
)
