<template>
  <scroll-center-dialog v-model="open" @close="close" @apply="checkForm" :title=" (select)?'Изменить почты':'Добавить почту'" :scrolled="false">
    <v-card flat class="pt-4">

      <v-text-field
        class="py-1"
        :error="hasError('email')"
        :error-messages="getError('email')"
        label="Почта"
        ref="number"
        variant="outlined"
        required
        v-model="form.email"
      ></v-text-field>

      <v-select
        class="pt-1"
        :error="hasError('kind')"
        :error-messages="getError('kind')"
        :items="contactType"
        label="Вид"
        item-title="text"
        item-value="value"
        variant="outlined"
        ref="kind"
        required
        v-model="form.kind"
      ></v-select>
      <v-checkbox
        label=" Виден всем пользователям"
        ref="is_visible"
        v-model="form.is_visible"
      ></v-checkbox>
    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImageCropper',
  props: {
    value: Boolean,
    select: Object,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      selectors: [],
      form: {
        email: null,
        kind: null,
        is_visible: false,
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  computed: {
    ...mapGetters({
      contactType: 'choices/contactType',
    })
  },
  methods: {
    ...mapActions({
      loadContactType: 'choices/loadContactType',
      createUserMail: "user/createUserMail",
      updateUserMail: "user/updateUserMail"
    }),
    loadChoices () {
      this.loadContactType({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.$emit('close')
    },
    checkForm () {
      const finalizer = (val) => {
        this.$emit('save')
      }
      const catcher = (data, url) => {
        this.errors.fields = data
      }
      if (this.select) {
        this.updateUserMail({
          data: this.form,
          id: this.select.id,
          finalizer,
          catcher
        })
      } else {
        this.createUserMail({
          data: this.form,
          finalizer,
          catcher
        })
      }
    },
  },
  close () {
    this.$emit('close')
  },
  created () {
    if (!this.contactType) {
      this.loadChoices()
    }
    if (this.select) {
      this.form.email = this.select.email
      this.form.kind = this.select.kind
      this.form.is_visible = this.select.is_visible
    }
  }
}
</script>

<style scoped>

</style>
