import store from '../store'
import moment from 'moment'

export function getUserToken () {
  return localStorage.getItem('user-token')
}

export function isWorker () {
  return store.state.user.isWorker && localStorage.getItem('isWorker')
}

export function isAuthenticated () {
  return store.state.user.isAuthenticated && localStorage.getItem('user-token')
}

export function clearUserAuth () {
  localStorage.removeItem('user-token') // clear obj.users.exclude(user=self.context['request'].user.db_user).first().user.full_nameyour user's token from localstorage
  localStorage.removeItem('isWorker')
  localStorage.removeItem('username')
  localStorage.removeItem('user-uid')
  window.$cookies.remove('userMuctr')
  store.commit('auth/AUTH_LOGOUT')
}

export function checkIsProductionServer () {
  return process.env.NODE_ENV === 'production'
}
export function checkIsDevelopmentServer () {
  return process.env.NODE_ENV === 'development'
}

export function getUserUid () {
  return localStorage.getItem('user-uid')
}

export function makeProfileUrl (url) {
  return url.replace('<user-uid>', getUserUid()).replace('<uuid:user>', getUserUid()).replace('<user>', getUserUid())
}

export function getDisplay (value, selector) {
  /**
     * Функции выбиращая текстовое отображения для значения из списка выбора
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     */
  if (selector) {
    const res = selector.find((el) => {
      if (el.value === value) {
        return true
      } else {
        return false
      }
    })
    if (res) { return res.text } else { return null }
  } else { return null }
}

export function mapPaginatedResult (from, to) {
  /**
     * Присваивает параметры пагинации к объекту из запроса
     *
     * @param {Object} from - откуда брать параметры пагинации
     * @param {Object} to - куда прокидывать параметры пагинации
     */
  to.next = from.next
  to.prev = from.previous
  to.count = from.count
}

export function checkFieldExist (object, field) {
  /**
     * Проверка существования поля в объекте
     *
     * @param {Object} object - объект в котором проводиться проверка
     * @param {String} field - поле которое проверяется в объекте
     *
     * @return {Boolean} - результат True если есть
     */
  if (object !== Object && object !== Array) { return false }
  return field in object
}

export function checkFieldTrue (object, field) {
  /**
     * Проверить что поле существуе и оно не null, undefined, False
     *
     * @param {Object} object - объект в котором проводиться проверка
     * @param {String} field - поле которое проверяется в объекте
     *
     * @return {Boolean} - результат True если соответвует условиям
     */
  return checkFieldExist(object, field) && object[field]
}

export function getFieldOrEmpty (object, field) {
  /**
     * Вывод либо значение поля, либо пустую строку
     *
     * @param {Object} object - объект в котором проводиться проверка
     * @param {String} field - поле которое проверяется в объекте
     *
     * @return {Boolean} - результат True если соответвует условиям
     */
  if (checkFieldExist(object, field)) { return object[field] }
  return ''
}

export function displayDate (date, minutes = false) {
  /**
     * Вывод нормального представления даты по русски
     * @param {string} date - строка даты и времени
     * @param {Boolean} minutes - выводить минут или не выводить, по умолчанию нет
     *
     * @return {String} вывод строки даты в нужном виде
     */
  let format = 'DD.MM.YYYY'
  if (minutes) { format += ' HH:mm' }
  return moment(date).format(format)
}

export function getDomain () {
  /*
    *
     */
  if (process.env.VUE_APP_COOKIE_DOMAIN !== undefined) {
    return process.env.VUE_APP_COOKIE_DOMAIN
  } else {
    return null
  }
}
