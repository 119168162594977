import helper from './helper'

const getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters
// const getWorkerUrlWithParameters = helper.getWorkerUrlWithParameters
const getEducationUrlWithParameters = helper.getEducationUrlWithParameters

export default {
  CHOICES: {
    CONTACT_TYPE: (gets = null) => getAccountsUrlWithParameters(
      'choices|contacts|contact',
      'get',
      {},
      gets
    ),
    LANGUAGE_TYPE: (gets = null) => getAccountsUrlWithParameters(
      'choices|users|language',
      'get',
      {},
      gets
    ),
    ADVANCED_TRAINING_DOCUMENT_TYPE: (gets = null) => getEducationUrlWithParameters(
      'choices|relations|document|type',
      'get',
      {},
      gets
    ),
    ADVANCED_TRAINING_WORK_TYPE: (gets = null) => getEducationUrlWithParameters(
      'choices|relations|work|type',
      'get',
      {},
      gets
    ),
    ADVANCED_TRAINING_KNOWLEDGE_TYPE: (gets = null) => getEducationUrlWithParameters(
      'choices|relations|knowledge|type',
      'get',
      {},
      gets
    )
  }
}
