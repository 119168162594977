import PageNotFound from '@/modules/core/pages/PageNotFound'
import coreRouter from '@/modules/core/routers/routers'
import portalRouter from '@/modules/profile/routers/routers'
import { createRouter, createWebHistory } from 'vue-router'
import { authRequiredHook, instanceRequiredHook } from '@/router/hooks'

const baseRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]
let allRoutes = []

allRoutes = allRoutes.concat(
  baseRoutes,
  coreRouter,
  portalRouter
)

const router = createRouter(
  {
    base: '',
    history: createWebHistory(process.env.BASE_URL),
    routes: allRoutes
  }
)
router.beforeEach(authRequiredHook)
router.beforeEach(instanceRequiredHook)

export default router
