import helper from './helper'

const getFeedbackUrlWithParameters = helper.getFeedbackUrlWithParameters

export default {
  getFeedbackUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getFeedbackUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  FEEDBACK: {
    CREATE_TICKET:
          (gets = null) => getFeedbackUrlWithParameters(
            'selfservice|ticket-list',
            'create',
            null,
            gets
          ),
    TICKET_LIST:
          (gets = null) => getFeedbackUrlWithParameters(
            'selfservice|ticket-list',
            'list',
            null,
            gets
          )
  }
}
