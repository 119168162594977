import { sendGetRequest } from "@/helper/requests"
import selectors from "@/urls/selectors"

const state = {
  selectors: {
    signerRecipients: [],
    accountCountry: [],
    accountCountryCode: [],
    accountLanguages: [],
    accountAccounts: null,
  }
}

const getters = {
  signerRecipients: state => state.selectors.signerRecipients,
  accountCountry: state => state.selectors.accountCountry,
  accountCountryCode: state => state.selectors.accountCountryCode,
  accountLanguages: state => state.selectors.accountLanguages,
  accountAccounts: state => state.selectors.accountAccounts,
}

const mutations = {
  SET_RECIPIENTS: (state, value) => {
    state.selectors.signerRecipients = value
  },
  SET_COUNTRY: (state, value) => {
    state.selectors.accountCountry = value
  },
  SET_COUNTRY_CODE: (state, value) => {
    state.selectors.accountCountryCode = value
  },
  SET_LANGUAGES: (state, value) => {
    state.selectors.accountLanguages = value
  },
  SET_ACCOUNTS: (state, value) => {
    state.selectors.accountAccounts = value
  },
}

const actions = {
  loadSignerRecipients: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.SIGNER.RECIPIENTS()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_RECIPIENTS', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAccountCountryCode: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.ACCOUNTS.COUNTRY_CODE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_COUNTRY_CODE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAccountCountry: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.ACCOUNTS.COUNTRY()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_COUNTRY', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAccountLanguages: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.ACCOUNTS.LANGUAGES()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_LANGUAGES', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAccountAccounts: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.ACCOUNTS.ACCOUNTS()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ACCOUNTS', data)
        finalizer(data, url)
      }, catcher
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
