const Portal = {
  BASE: 'Portal',
  MAIN: undefined,
  DETAIL: undefined,
  SESSION: undefined,
  WORKER: {
    _BASE: undefined,
    MAIN: undefined
  },
  STUDENT: {
    _BASE: undefined,
    MAIN: undefined
  },
  SERVICE: {
    _BASE: undefined,
    MAIN: undefined,
    ORGANIZATION: undefined,
    ORGANIZATION_TREE: undefined,
    DIRECTORY: undefined,
    SIGNER: undefined,
    PERSONAL_SIGNATURE: undefined,
    DIT: undefined
  }
}
Portal.MAIN = `${Portal.BASE}|Main`
Portal.DETAIL = `${Portal.MAIN}|Detail`
Portal.SESSION = `${Portal.MAIN}|Session`

Portal.WORKER._BASE = `${Portal.BASE}|Worker`
Portal.WORKER.MAIN = `${Portal.WORKER._BASE}|Detail`

Portal.STUDENT._BASE = `${Portal.BASE}|Student`
Portal.STUDENT.MAIN = `${Portal.STUDENT._BASE}|Detail`

Portal.SERVICE._BASE = `${Portal.BASE}|Services`
Portal.SERVICE.MAIN = `${Portal.SERVICE._BASE}|Detail`
Portal.SERVICE.ORGANIZATION = `${Portal.SERVICE._BASE}|Organization`
Portal.SERVICE.ORGANIZATION_TREE = `${Portal.SERVICE._BASE}|Organization Tree`
Portal.SERVICE.SIGNER = `${Portal.SERVICE._BASE}|Signer`
Portal.SERVICE.PERSONAL_SIGNATURE = `${Portal.SERVICE._BASE}|Personal signature`
Portal.SERVICE.DIT = `${Portal.SERVICE._BASE}|Dit`
Portal.SERVICE.DIRECTORY = `${Portal.SERVICE._BASE}|Corporate Directory`
export default {
  ...Portal
}
