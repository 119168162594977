import url_acc from '@/urls/accounts'
import { sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/helper/requests'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  createUserPhone: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.PHONE.CREATE()
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserPhone: ({ commit }, { data, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.PHONE.UPDATE(id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserPhone: ({ commit }, { id, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.PHONE.DELETE(id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
