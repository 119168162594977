<template>
  <v-navigation-drawer
    location="left"
    v-if="leftMenu.enabled"
    v-model="leftMenu.show"
    id="main-left-menu"
    width="300"
  >
    <CurrentLeftMenu :items="leftMenu.items" class="overflow-hidden"/>
    <template v-slot:append>
      <InstanceLeftMenu :instances="leftMenu.instances" :current_instance="leftMenu.currentInstance"/>
    </template>
  </v-navigation-drawer>
</template>

<script>
import CurrentLeftMenu from "@/modules/core/components/menus/left_menu/CurrentLeftMenu.vue"
import InstanceLeftMenu from "@/modules/core/components/menus/left_menu/InstanceLeftMenu.vue"
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'LeftMenu',
  components: {
    CurrentLeftMenu,
    InstanceLeftMenu
  },
  setup () {
    const store = useStore()

    const leftMenu = computed(() => store.state.leftmenu)

    return {
      leftMenu,
    }
  }
}

</script>

<style scoped>

</style>
