<template>
  <div>
    <v-list-item
      v-if="item.router"
      :class="{ 'instance_menu__item--active': isCurrent(item)}"
      @click="changeRouter(item)"
      link
      class="instance_menu__item"
    >
      <v-list-item-title class="instance_menu__title text-uppercase text-h4">
        {{item.title}}
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "LeftMenuAction",
  props: {
    item: {
      type: Object,
      required: true
    },
    currentInstance: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // Methods
    const changeParam = (item) => {
      if (typeof (item) === "object") {
        return { name: item.name, params: item.params, query: item.query };
      } else {
        return { name: item };
      }
    };

    const changeRouter = (item) => {
      if (!isCurrent(item)) {
        store.commit('leftmenu/selectInstance', item.instance);
        router.push(changeParam(item.router));
      } else {
        if (route.name !== item.name) {
          router.push(changeParam(item.router));
        }
      }
    };

    const isCurrent = (item) => {
      if (props.currentInstance !== undefined && item.name !== undefined) {
        return props.currentInstance === item.name;
      }
      return false;
    };

    return {
      changeParam,
      changeRouter,
      isCurrent
    };
  }
});
</script>

<style lang="scss" scoped>
.instance_menu__item {
  padding: 16px 24px !important;
  height: 55px;
  border-bottom: 1px solid var(--blue-shades-focus);
  background: var(--background-white);

  &:hover {
    background: var(--gray-shades-hover);
  }

  &--active {
    padding: 16px 24px !important;
    height: 55px;
    border-bottom: 1px solid var(--blue-shades-focus);
    background: var(--blue-shades-focus);
  }
}

.instance_menu__title {
  text-align: left;
  color: var(--blue-shades-dark);
}
</style>
