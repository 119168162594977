<template>
  <v-container :fluid="true" style="width: 100%">
    <Detail v-if="$route.name === names.WORKER.MAIN"></Detail>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import names from "../routers/names"
import Detail from "./components/Detail"

export default {
  name: "WorkerPage",
  components: { Detail },

  data () {
    return {

    }
  },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>
