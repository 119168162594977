<template>
  <v-container class="page-content px-5" style="width: 100%">
    <div v-if="loading === false">
      <v-row no-gutters class="justify-center">
        <v-icon
          v-if="accept"
          size="250"
          color="green"
        >check_circle_outline</v-icon>
        <v-icon
          v-else
          size="250"
          color="red"
        >error</v-icon>
      </v-row>
      <v-row no-gutters class="justify-center text-h5 text-md-h4 mt-6 font-weight-bold">
        <span v-if="accept"> Почта была подтверждена!</span>
        <span v-else>Ошибка подтверждения</span>
      </v-row>
      <v-row no-gutters class="justify-center mt-10">
        <v-btn
          v-if="!accept"
          color="primary"
          elevation="4"
          x-large
          rounded
          @click="regApprove()"
        >
          Повторить отправку
        </v-btn>
      </v-row>
    </div>

    <div class="action mt-16 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </v-container>
</template>

<script>

import router from "@/router"
import names from '@/modules/profile/routers/names'
import { mapActions } from 'vuex'

export default {
  name: 'ApproveMail',
  created: function () {
    this.regApprove()
  },
  data () {
    return {
      loading: true,
      accept: false,
    }
  },
  computed: {
    names: () => names,
  },
  methods: {
    ...mapActions({
      approveEmail: 'user/approveEmail',
    }),
    regApprove () {
      const data = {
        token: this.$route.params.token
      }
      this.approveEmail({
        data,
        finalizer: (data, url) => {
          this.accept = true
          this.loading = false
          setTimeout(() => {
            router.push({ name: names.BASE })
          }, 2000)
        },
        catcher: (data, url) => {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
