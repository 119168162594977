import helper from './helper'

const getMailUrlWithParameters = helper.getMailUrlWithParameters

export default {
  getMailUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getMailUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  MAIL: {
    MAIL_LIST:
      (gets = null) => getMailUrlWithParameters(
        'inside_email|catalog',
        'get',
        null,
        gets
      )
  }
}
