<template>
  <v-container :fluid="true" style="width: 100%">
    <v-card class="rounded-lg">
      <v-toolbar
        :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
        :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
      >
        <span class="dark-primary">Активные сеансы на {{ displayDate(new Date(), true) }} </span>
        <v-spacer/>
        <v-toolbar-items>
          <v-tooltip location="bottom" text="Завершить все сеансы, кроме текущего">
            <template v-slot:activator="{ props }">
              <v-btn class="dark-primary" flat icon v-bind="props" @click="deleteSession(null)">
                <v-icon>delete_sweep</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        class="text-left"
        item-value="ip"
        :headers="headers"
        :items="getSession"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip v-if="!item.current"  location="bottom" text="Завершить сеанс">
            <template v-slot:activator="{ props }">
              <v-btn class="dark-primary" v-bind="props"  icon="delete" variant="text" @click="deleteSession(item)"></v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.used="{ item }">
          {{ displayDate(item.used, true) }}
        </template>
        <template v-slot:item.ip="{ item }">
          <span v-if="item.ip">{{ item.ip }}</span>
          <span v-else>Нет данных</span>
        </template>
      </v-data-table>
    </v-card>
    <apply-dialog
      v-if="dialog_delete"
      v-model="dialog_delete"
      :get-text="() => deleteTitle"
      @close="closeDeleteDialog"
    ></apply-dialog>
  </v-container>
</template>

<script>

import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import TitledPageMixin from '@/mixins/TitledPageMixin'
import { mapActions, mapGetters } from 'vuex'
import { displayDate } from '@/helper'
import ApplyDialog from '@/modules/templates/ApplyDialog.vue'

export default {
  name: 'SessionPage',
  mixins: [FormErrorsMixin, TitledPageMixin],
  components: { ApplyDialog },
  data () {
    return {
      page_title: 'Мои устройства',
      dialog_delete: false,
      delete_data: null,
      headers: [
        {
          title: 'Устройство',
          key: 'device',
          sortable: false,
          align: 'start'
        },
        {
          title: 'IP',
          key: 'ip',
          sortable: false,
          align: 'start'
        },
        {
          title: 'Время последней активности',
          key: 'used',
          sortable: false,
          align: 'start'
        },
        {
          title: 'Действия',
          key: 'action',
          sortable: false,
          align: 'end'
        }
      ],
    }
  },
  computed: {
    deleteTitle () {
      if (this.delete_data) {
        return 'Вы уверены, что хотите завершить сеанс?'
      }
      return 'Вы уверены, что хотите завершить все сеансы, кроме текущего?'
    },
    ...mapGetters({
      getSession: 'user/getSession'
    })
  },
  methods: {
    ...mapActions({
      loadUserSession: 'user/loadUserSession',
      deleteUserSession: 'user/deleteUserSession',
      deleteUserAllSession: 'user/deleteUserAllSession',
    }),
    displayDate,
    loadData () {
      this.loadUserSession({
        finalizer: (data, url) => {
        },
        catcher: (val) => {
        }
      })
    },
    closeDeleteDialog (event) {
      if (event) {
        if (this.delete_data) {
          this.deleteUserSession({
            key: this.delete_data.key,
            finalizer: (data, url) => {
              this.loadData()
            },
            catcher: (val) => {
            }
          })
        } else {
          this.deleteUserAllSession({
            finalizer: (data, url) => {
              this.loadData()
            },
            catcher: (val) => {
            }
          })
        }
      }
      this.dialog_delete = false
      this.delete_data = undefined
    },
    deleteSession (item) {
      this.dialog_delete = true
      this.delete_data = item
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
