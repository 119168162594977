<template>
  <scroll-center-dialog v-model="open" @close="close" @apply="checkForm" :title=" (select)?'Изменить адрес':'Добавить адрес'" :scrolled="false">
    <v-card flat class="pt-4">
      <v-checkbox
        :error="hasError('is_visible')"
        :error-messages="getError('is_visible')"
        label=" Показывать всем"
        variant="outlined"
        ref="is_visible"
        v-model="form.is_visible"
        class="pb-3"
      ></v-checkbox>
      <v-checkbox
        :error="hasError('registration')"
        :error-messages="getError('registration')"
        label=" Является адресом регистрации"
        variant="outlined"
        class="mt-n6 pb-6"
        ref="registration"
        v-model="form.registration"
      ></v-checkbox>
      <v-autocomplete
        class="mt-n4"
        no-data-text ="Нет данных"
        :error="hasError('country')"
        :error-messages="getError('country')"
        :items="accountCountry"
        :rules="[() => !!form.country || 'Выберите страну проживания']"
        item-title="text"
        item-value="value"
        variant="outlined"
        label="Страна"
        placeholder="Выберите..."
        ref="country"
        required
        v-model="form.country"
      ></v-autocomplete>
      <v-text-field
        class="py-1"
        :error="hasError('region')"
        :error-messages="getError('region')"
        label="Регион"
        variant="outlined"
        placeholder="Московская область"
        ref="region"
        required
        v-model="form.region"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('autonomy')"
        :error-messages="getError('autonomy')"
        label="Автономия"
        placeholder="Ямало-Ненецкий автономный округ"
        ref="autonomy"
        variant="outlined"
        required
        v-model="form.autonomy"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('area')"
        :error-messages="getError('area')"
        label="Область"
        variant="outlined"
        placeholder="Московская область"
        ref="area"
        required
        v-model="form.area"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('city')"
        :error-messages="getError('city')"
        label="Город"
        variant="outlined"
        placeholder="Москва"
        ref="city"
        required
        v-model="form.city"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('district')"
        :error-messages="getError('district')"
        label="Район"
        placeholder=""
        variant="outlined"
        ref="district"
        required
        v-model="form.district"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('locality')"
        :error-messages="getError('locality')"
        label="Населенный пункт"
        placeholder=""
        variant="outlined"
        ref="locality"
        required
        v-model="form.locality"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('street')"
        :error-messages="getError('street')"
        label="Улица"
        variant="outlined"
        placeholder=""
        ref="street"
        required
        v-model="form.street"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('house')"
        :error-messages="getError('house')"
        label="Дом"
        placeholder="1"
        variant="outlined"
        ref="house"
        required
        v-model="form.house"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('pavilion')"
        :error-messages="getError('pavilion')"
        label="Строение"
        placeholder="1"
        variant="outlined"
        ref="pavilion"
        required
        v-model="form.pavilion"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('building')"
        :error-messages="getError('building')"
        label="Здание"
        placeholder="1"
        variant="outlined"
        ref="building"
        required
        v-model="form.building"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('apartment')"
        :error-messages="getError('apartment')"
        label="Квартира"
        placeholder="1"
        ref="apartment"
        variant="outlined"
        required
        v-model="form.apartment"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :error="hasError('free')"
        :error-messages="getError('free')"
        :label="$vuetify.display.smAndUp ? 'Свободная запись (заполняется автоматически)' : 'Свободная запись'"
        :placeholder="$vuetify.display.smAndUp ? 'Альметьевск, Республика Татарстан, Россия, 423455' : 'Адрес'"
        ref="free"
        variant="outlined"
        required
        v-model="form.free"
      ></v-text-field>
    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImageCropper',
  props: {
    value: Boolean,
    select: Object,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      form: {
        is_visible: true,
        registration: false,
        country: null,
        region: '',
        autonomy: '',
        area: '',
        city: '',
        district: '',
        locality: '',
        street: '',
        house: '',
        pavilion: '',
        building: '',
        apartment: '',
        free: '',
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  computed: {
    ...mapGetters({
      accountCountry: 'selectors/accountCountry',
    })
  },
  methods: {
    ...mapActions({
      getUserAddressDetail: "user/getUserAddressDetail",
      updateUserAddress: "user/updateUserAddress",
      loadAccountCountry: 'selectors/loadAccountCountry',
      createUserAddress: "user/createUserAddress",
    }),
    loadSelectors () {
      this.loadAccountCountry({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.$emit('close')
    },
    checkForm () {
      const finalizer = (val) => {
        this.$emit('save')
      }
      const catcher = (data, url) => {
        this.errors.fields = data
      }
      if (this.select) {
        this.updateUserAddress({
          data: this.form,
          id: this.select.id,
          finalizer,
          catcher
        })
      } else {
        this.createUserAddress({
          data: this.form,
          finalizer,
          catcher
        })
      }
    },
  },
  close () {
    this.$emit('close')
  },
  created () {
    if (this.accountCountry.length === 0) {
      this.loadSelectors()
    }
    if (this.select) {
      this.getUserAddressDetail({
        id: this.select.id,
        finalizer: (data) => {
          this.form = data
        },
      })
    }
  }
}
</script>

<style scoped>

</style>
