import names from '@/modules/profile/routers/names'
import { getUrlAllowed } from "@/helper/urls"
// import { checkIsDevelopmentServer } from '@/helper/index'

export function getInstMenu (isWorker = false, isStudent = false) {
  /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
  const menu = []
  menu.push({ title: 'Профиль', icon: 'person', router: 'Portal', name: 'Portal' })

  if (isWorker && isStudent) {
    menu.push({ title: 'ЭИОС', icon: 'school', router: { name: 'RedirectPage', query: { link: 'eios' } }, name: 'eios' })
  } else if (isStudent) {
    menu.push({ title: 'Образование', icon: 'school', router: { name: 'RedirectPage', query: { link: 'eios' } }, name: 'eios' })
  } else if (isWorker) {
    menu.push({ title: 'ЭИОС', icon: 'school', router: { name: 'RedirectPage', query: { link: 'eios' } }, name: 'eios' })
  }

  if (getUrlAllowed('accounts', 'admin|authentication|login|as', 'post')) {
    menu.push({ title: 'Администрирование', icon: 'manage_accounts', router: { name: 'RedirectPage', query: { link: 'admin' } }, name: 'admin' })
  }

  return menu
}

export function getLeftMenu (isWorker = false, isStudent = false) {
  const menu = [
    { title: 'Мой профиль', icon: 'person', included: false, router: { name: names.BASE, params: {} } },
    { title: 'Мои устройства', icon: 'important_devices', included: false, router: { name: names.SESSION, params: {} } },
  ]
  if (isWorker) {
    menu.push({ title: 'Работник', icon: 'badge', included: false, router: { name: names.WORKER.MAIN, params: {} } })
  }
  if (isStudent) {
    menu.push({ title: 'Обучающийся', icon: 'school', included: false, router: { name: names.STUDENT.MAIN, params: {} } })
  }
  const service = {
    title: 'Сервисы',
    icon: 'grid_view',
    subs: [
      { title: 'Обращение в ДИТ', icon: 'mark_as_unread', included: false, router: { name: names.SERVICE.DIT, params: {} } },
      { title: 'Ознакомление с документами', icon: 'assignment_turned_in', included: false, router: { name: 'RedirectPage', query: { link: 'confirmation' } }, name: 'confirmation' },
    ]
  }

  const organization = {
    title: 'Организация',
    icon: 'corporate_fare',
    subs: [
      { title: 'Корпоративный справочник', icon: 'book', included: false, router: { name: names.SERVICE.DIRECTORY, params: {} } },
      { title: 'Информация об организации', icon: 'assured_workload', included: false, router: { name: names.SERVICE.ORGANIZATION, params: {} } },
      { title: 'Структура подразделений', icon: 'account_tree', included: false, router: { name: names.SERVICE.ORGANIZATION_TREE, params: {} } },
      // { title: 'Делегаты конференции', icon: 'language', included: true, router: { name: 'RedirectPage', params: {} }, link: 'https://www.muctr.ru/upload/docs/conf_25.12.2024.pdf' }
    ]
  }
  // if (checkIsDevelopmentServer()) {
  //   service.subs = service.subs.concat([
  //     { title: 'Личная подпись', icon: 'border_color', included: true, router: { name: names.SERVICE.PERSONAL_SIGNATURE, params: {} } }])
  // }
  if (isWorker) {
    service.subs = service.subs.concat([
      { title: 'Подпись ЭЦП', icon: 'edit_calendar', included: false, router: { name: names.SERVICE.SIGNER, params: {} } },
      { title: 'Личная подпись', icon: 'border_color', included: true, router: { name: names.SERVICE.PERSONAL_SIGNATURE, params: {} } },
      { title: 'Заказ пропусков (СЗУП)', icon: 'how_to_reg', included: false, router: { name: 'RedirectPage', query: { link: 'passorder' } }, name: 'passorder' }
    ])
  }
  if (isStudent) {
    service.subs = service.subs.concat([
      // { title: 'Интерактивная навигация', icon: 'assignment_turned_in', included: false, router: { name: 'RedirectPage', query: { link: 'map' } }, name: 'map' },
      // { title: 'Расписание занятий РХТУ', icon: 'assignment_turned_in', included: false, router: { name: 'RedirectPage', query: { link: 'schedule' } }, name: 'schedule' },
      { title: 'Учебный портал', icon: 'language', included: true, router: { name: 'RedirectPage', params: {} }, link: 'study.muctr.ru' },
      { title: 'Сервис обмена сообщениями', icon: 'assignment_turned_in', included: false, router: { name: 'RedirectPage', query: { link: 'messaging' } }, name: 'messaging' },
    ])
  }
  menu.push(organization)
  menu.push(service)
  return menu
}

export function getInstNameByRouterName (router) {
  /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
  return getInstMenu().find(el => {
    return router === el.router
  }).name
}
