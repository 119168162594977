<template>
  <v-app class="color--background-main">
    <TopMenu />
    <LeftMenu />
    <v-main
      id="base-content"
    >
      <div
        v-show="!isLoadingAlive"
        class="overflow-y-auto"
        style="height: calc(100vh - 78px); height: calc(var(--doc-height) - 78px);"
      >
        <router-view />
      </div>
      <div v-show="isLoadingAlive">
        <v-row no-gutters justify="center" align="center" style="height: 90vh">
            <semipolar-spinner
              :animation-duration="2000"
              :size="$vuetify.display.mdAndUp ? 200 : 60"
              color="#1976D2"
            />
        </v-row>
      </div>
    </v-main>
  </v-app>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LeftMenu from './modules/core/components/menus/LeftMenu.vue'
import TopMenu from './modules/core/components/menus/TopMenu'
import names from '@/modules/profile/routers/names'
import { SemipolarSpinner } from "epic-spinners"

export default {
  components: {
    TopMenu,
    LeftMenu,
    SemipolarSpinner
  },
  data () {
    return {
      clipped: true,
      drawer: true,
      surls: {},
      footer_height: 0,
    }
  },
  computed: {
    ...mapGetters({
      isLoadingAlive: "auth/isLoadingAlive",
    }),
    names: () => names,
    ...mapState({
      leftmenu: state => state.leftmenu,
      maintoolbar: state => state.maintoolbar,
      user: state => state.user,
      urls: state => state.urls,
    }),
  },
  watch: {
    urls () {
      this.surls = this.urls.urls
    }
  },
  methods: {
    documentHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
  },
  mounted () {
    window.addEventListener("resize", this.documentHeight)
    this.documentHeight()
  },
  unmounted () {
    window.removeEventListener("resize", this.documentHeight)
  },
  created: async function () {},
}
</script>

<style>
#app {
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
:root {
  --doc-height: 100vh;
}

</style>
