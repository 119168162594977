import url_acc from '@/urls/accounts'
import { sendPostRequest, sendGetRequest, sendPutRequest, sendDeleteRequest } from '@/helper/requests'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getUserAddressDetail: ({ commit }, {
    id, finalizer = () => {
    }, catcher = () => {
    }
  }) => {
    const url = url_acc.PROFILE.ADDRESS.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserAddress: ({ commit }, { id, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.ADDRESS.DELETE(id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createUserAddress: ({ commit }, {
    data, finalizer = () => {
    }, catcher = () => {
    }
  }) => {
    const url = url_acc.PROFILE.ADDRESS.CREATE()
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserAddress: ({ commit }, {
    data, id, finalizer = () => {
    }, catcher = () => {
    }
  }) => {
    const url = url_acc.PROFILE.ADDRESS.UPDATE(id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
}

export default {
  state,
  mutations,
  getters,
  actions
}
