<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :close-on-back="false"
    location="bottom  "
    transition="slide-y-transition"
    class="text-left"
  >
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="flat" icon size="32" color="white" style="margin-top: 2px !important">
        <v-icon color="var(--blue-shades-dark)" v-if="notifications.length === 0 && open" icon="notifications_active"/>
        <v-badge v-else-if="notifications.length > 0" color="var(--additional-red)" :content="notifications.length">
          <v-icon color="var(--blue-shades-dark)" v-if="open" icon="notifications_active"/>
          <v-icon color="var(--blue-shades-dark)" v-else icon="notifications"/>
        </v-badge>
        <v-icon color="var(--blue-shades-dark)" v-else icon="notifications"/>
        <v-tooltip activator="parent" location="bottom" text="Уведомления"/>
      </v-btn>
    </template>
    <v-sheet style="box-shadow: 0 6px 20px 0 rgba(38, 54, 73, 0.32); background: rgba(0, 0, 0, 0); margin-top: 12px; border-radius: 15px;">
      <v-list :lines="'three'" class="top_menu__button__list--title elevation-0">
        <v-list-item variant="text" :lines="'one'" class="top_menu__button__list-item__prepend">
          <v-list-item-title class="top_menu__button__list-title text-h6">
            Список уведомлений
          </v-list-item-title>
          <template v-slot:append>
            <v-btn v-if="notifications.length > 0" @click="readAllNotifications()" variant="text" icon>
              <v-icon color="var(--additional-red)" icon="delete_forever"/>
              <v-tooltip activator="parent" location="bottom" text="Закрыть все оповещения"/>
            </v-btn>
          </template>
        </v-list-item>
      </v-list>
      <v-list :lines="'three'" class="top_menu__button__list" max-width="600" max-height="540">
        <v-list-item :lines="'two'" class="top_menu__button__list-item__prepend" v-if="notifications.length === 0">
          <v-row no-gutters justify="center" align-content="center">
            <v-list-item-title class="px-0 mx-0 top_menu__button__list-title text-body-1 text-grey">
              Новых уведомлений нет
            </v-list-item-title>
          </v-row>
        </v-list-item>
        <v-list-item
          class="top_menu__button__list-item"
          v-for="notification in notifications"
          :key="notification.global_hash"
        >
          <template v-slot:append>
            <v-btn icon variant="text" @click="readSingleNotification(notification)">
              <v-icon color="var(--blue-shades-dark)">clear</v-icon>
            </v-btn>
          </template>
          <v-row no-gutters @click="openLink(notification)">
            <div class="d-flex justify-center align-center">
              <v-icon color="var(--blue-shades-dark)" size="28">{{ notification?.visual_data?.icon }}</v-icon>
            </div>
            <v-col class="px-4">
              <v-list-item-title class="px-0 mx-0 top_menu__button__list-title text-body-1">
                {{ notification?.system }} от {{ displayDate(notification?.generated, true) }}
              </v-list-item-title>
              <v-list-item-subtitle class="px-0 mx-0 top_menu__button__list-title text-body-1">
                <span style="color: black; opacity: 0.9;" v-html="notification?.text"/>
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
  <v-snackbar
    v-if="notifications.length > 0 && !open"
    v-model="snackbar"
    rounded="lg"
    location="right"
    color="white"
    :timeout="3000"
  >
    <v-row no-gutters @click="openLink(notifications[0])">
      <div class="d-flex justify-center align-center">
        <v-icon color="var(--blue-shades-dark)" size="28">{{ notifications[0]?.visual_data?.icon }}</v-icon>
      </div>
      <v-col class="px-4">
        <v-list-item-title class="px-0 mx-0 top_menu__button__list-title text-body-1">
          {{ notifications[0]?.system }} от {{ displayDate(notifications[0]?.generated, true) }}
        </v-list-item-title>
        <v-list-item-subtitle class="px-0 mx-0 top_menu__button__list-title text-body-1">
          <span class="snackbar__subtitle" v-html="notifications[0]?.text"></span>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-btn variant="text" icon="close" color="var(--blue-shades-dark)" @click="snackbar = false"/>
    </template>
  </v-snackbar>
</template>

<script setup>
import { displayDate } from "@/helper";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const open = ref(false);
const snackbar = ref(true);

const store = useStore();

const notifications = computed(() => store.getters['eventchain/getMessages'])

watch(
  () => notifications.value,
  (value, oldValue) => {
    if (value.length > 0) {
      if (value.length >= oldValue.length) {
        snackbar.value = true
      }
    }
  },
  { deep: true }
)

const readSingleNotification = async (val) => {
  await store.dispatch('eventchain/deleteMessages', { val: val })
}

const readAllNotifications = async () => {
  await store.dispatch('eventchain/deleteAllMessages', {})
}

const openLink = (data) => {
  if (data.kind_data.target) {
    if (!data.kind_data.method) {
      const url = new URL(data.kind_data.target);
      for (const key in data.kind_data.gets) {
        url.searchParams.set(key, data.kind_data.gets[key]);
      }
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.top_menu__button .v-btn.v-btn--density-default {
  margin: 0 12px;
  border-radius: 2000px;

  &:hover {
    background: var(--blue-shades-hover);
  }
}

.top_menu__button__list--title {
  padding: 0;
  border-radius: 15px 15px 0 0;
  background: var(--background-white);
}

.top_menu__button__list {
  padding: 0;
  border-radius: 0 0 15px 15px;
  background: var(--background-white);
}

.top_menu__button__list-item {
  border-bottom: 1px solid var(--blue-shades-focus);

  &:hover {
    background: var(--blue-shades-focus);
  }
}

.top_menu__button__list-item__prepend {
  border-bottom: 1px solid var(--blue-shades-focus);
}

.top_menu__button__list-title {
  padding-left: 24px;
  padding-right: 24px;
  color: var(--corporate-main);
}

.snackbar__subtitle {
  color: black;
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
