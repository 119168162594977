import feedback from '@/urls/feedback'
import {
  postFileRequest
} from '@/helper/requests'
import { executeRequest } from '@/store/utils'

const state = {
  feedback: {
    feedback: null,
    loadingFeedback: false,
    error: null
  }
}

const getters = {
  getFeedback: state => state.feedback.feedback,
  getLoadingFeedback: state => state.feedback.loadingFeedback,
  getFeedbackError: state => state.feedback.error
}

const actions = {
  createTicket: ({ dispatch, commit, getters }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = feedback.FEEDBACK.CREATE_TICKET()
    executeRequest('Feedback', postFileRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  }
}

const mutations = {
  SET_FEEDBACK_LOADING: (state) => {
    state.feedback.loadingFeedback = true
  },
  SET_FEEDBACK_LOADING_END: (state) => {
    state.feedback.loadingFeedback = false
  },
  SAVE_FEEDBACK_ERROR: (state, error) => {
    state.feedback.error = error
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
