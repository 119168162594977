<template>
  <v-container :fluid="true">
    <v-card class="rounded-lg">
      <v-toolbar
        class="color-main-blue text-size-18 text-bold text-inter text-left px-4"
        :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
      >Генератор подписи для Корпоративной электронной почты</v-toolbar>
      <v-card-text class="text-left text-black text-size-16">
        Подпись в корпоративной электронной почте содержит основную информацию о работнике Университета и может быть установлена в Outlook и OWA (post.muctr.ru).
        Заполните информацию о себе ниже, после чего скопируйте готовую подпись в почтовый клиент.
      </v-card-text>
      <v-card-text>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="12" sm="6" lg="3">
              <div class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Фамилия Имя Отчество
              </div>
              <v-text-field
                placeholder="Фамилия Имя Отчество"
                variant="outlined"
                color="#0563C1"
                v-model="fio"
                :loading="isLoadingAccount"
                disabled
                prepend-inner-icon="$PersonCheckIcon"
              />
              <div class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Должность
              </div>
              <v-text-field
                placeholder="Должность"
                variant="outlined"
                color="#0563C1"
                v-model="firstPosition"
                :loading="isLoadingWorker"
                disabled
                prepend-inner-icon="$CaseIcon"
              />
              <div class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Подразделение
              </div>
              <v-text-field
                placeholder="Подразделение"
                variant="outlined"
                color="#0563C1"
                hide-details="auto"
                v-model="firstDepartment"
                :loading="isLoadingWorker"
                disabled
                prepend-inner-icon="$PeopleIcon"
              />
<!--              <v-checkbox-->
<!--                v-model="showSecondPosition"-->
<!--                :disabled="!haveSecondPosition"-->
<!--                density="compact"-->
<!--                hide-details="auto"-->
<!--                color="#0563C1"-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                  <span class="color-main-gray text-regular text-roboto text-size-12">-->
<!--                    Отображать вторую должность (при наличии)-->
<!--                  </span>-->
<!--                </template>-->
<!--              </v-checkbox>-->
              <div v-if="showSecondPosition" class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Должность
              </div>
              <v-text-field
                v-show="showSecondPosition"
                v-model="secondPosition"
                :loading="isLoadingWorker"
                disabled
                placeholder="Должность"
                variant="outlined"
                color="#0563C1"
                prepend-inner-icon="$CaseIcon"
              />
              <div v-if="showSecondPosition" class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Подразделение
              </div>
              <v-text-field
                v-show="showSecondPosition"
                v-model="secondDepartment"
                :loading="isLoadingWorker"
                disabled
                placeholder="Подразделение"
                variant="outlined"
                color="#0563C1"
                prepend-inner-icon="$PeopleIcon"
              />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <div class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Телефон рабочий
              </div>
              <v-text-field
                v-model="workerPhone"
                :loading="isLoadingAccount"
                disabled
                v-maska:[phone_options]
                placeholder="Телефон рабочий"
                variant="outlined"
                color="#0563C1"
                prepend-inner-icon="$TelephoneIcon"
              />
              <div class="disabled text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Корпоративная почта
              </div>
              <v-text-field
                v-model="workerMail"
                :loading="isLoadingAccount"
                disabled
                placeholder="Корпоративная почта"
                variant="outlined"
                color="#0563C1"
                prepend-inner-icon="$MailIcon"
              />
              <div class="text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                <p>Мобильный номер <wbr /> <span class="color-light-gray">(опционально)</span></p>
                <v-tooltip>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" size="16" color="#DBDBDB">help</v-icon>
                  </template>
                  <span>Вы можете указать дополнительный номер телефона для связи с Вами</span>
                </v-tooltip>
              </div>
              <v-text-field
                v-model="personalPhone"
                :disabled="canEditPhone"
                :style="`${!$vuetify.display.xs ? 'margin-bottom: 18px' : ''}`"
                v-maska:[phone_options]
                :rules="phoneRules"
                clearable
                type="phone"
                bg-color="#F1F5F8"
                placeholder="Мобильный номер"
                variant="outlined"
                color="#0563C1"
                prepend-inner-icon="$PhoneIcon"
              />
<!--              <div class="text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">-->
<!--                <p>Логотип подразделения <span class="color-light-gray">(опционально)</span></p>-->
<!--                <v-tooltip>-->
<!--                  <template v-slot:activator="{ props }">-->
<!--                    <v-icon v-bind="props" size="16" color="#DBDBDB">help</v-icon>-->
<!--                  </template>-->
<!--                  <span>Для запроса на размещение логотипа Вашего подразделения, пожалуйста направьте соответствующее обращения на support@muctr.ru с прикрепленным файлом логотипа в формате jpeg/svg, размер не более 120х60 px. Горизонтально ориентированный формат</span>-->
<!--                </v-tooltip>-->
<!--              </div>-->
<!--              <v-text-field-->
<!--                v-model="departmentLogo"-->
<!--                placeholder="Логотип подразделения"-->
<!--                variant="outlined"-->
<!--                color="#0563C1"
                    bg-color="#F1F5F8"
                    prepend-inner-icon="$ImageIcon"-->
<!--              />-->
              <div class="text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Дополнительная почта
                <v-tooltip>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" size="16" color="#DBDBDB">help</v-icon>
                  </template>
                  <span>Вы можете указать одну дополнительную почту</span>
                </v-tooltip>
              </div>
              <v-select
                v-model="personalMail"
                :items="mailsSelector"
                bg-color="#F1F5F8"
                placeholder="Дополнительная почта"
                variant="outlined"
                clearable
                color="#0563C1"
                prepend-inner-icon="$MailIcon"
              />
              <div class="text-roboto size-16 text-size-12 text-regular color-main-gray d-flex align-center justify-space-between mb-1">
                Адрес
                <v-tooltip>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" size="16" color="#DBDBDB">help</v-icon>
                  </template>
                  <span>Укажите адрес площадки Университета, который будет отображен в подписи</span>
                </v-tooltip>
              </div>
              <v-select
                v-model="address"
                :rules="addressRules"
                :items="addressSelector"
                item-title="title"
                item-value="value"
                placeholder="Адрес"
                variant="outlined"
                bg-color="#F1F5F8"
                color="#0563C1"
                prepend-inner-icon="$HouseIcon"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-row justify="center" class="pr-3 ga-6 mt-3 mb-9">
                <v-btn
                  rounded="xl"
                  height="45"
                  color="#0563C1"
                  width="260"
                  @click="createQRCode"
                  :disabled="this.isLoadingWorker && this.isLoadingAccount"
                  type="submit"
                >
                  Сгенерировать QR - код
                </v-btn>
                <v-btn
                  rounded="xl"
                  height="45"
                  color="#0563C1"
                  width="260"
                  :disabled="this.isLoadingWorker && this.isLoadingAccount"
                  @click="createSignature"
                  type="submit"
                >
                  Сгенерировать подпись
                </v-btn>
              </v-row>
              <span class="text-inter text-bold text-size-18 color-main-blue">Предпросмотр</span>
              <div class="preview-wrapper">
                <vue-qrcode
                  v-if="createdQR"
                  :value="vcard3"
                  :options="{ width: 300, color: {dark: '#214D81' } }"
                  @ready="onReadyQR"
                />
                <signature-component
                  v-if="createdSignature"
                  :vcard3="signatureData.vcard3"
                  :fio="signatureData.fio"
                  :first-position="signatureData.firstPosition"
                  :first-department="signatureData.firstDepartment"
                  :second-position="signatureData.secondPosition"
                  :second-department="signatureData.secondDepartment"
                  :first-email="signatureData.workerMail"
                  :second-email="signatureData.personalMail"
                  :first-phone="signatureData.workerPhone"
                  :second-phone="signatureData.personalPhone"
                  :address="signatureData.address"
                />
              </div>
              <v-row justify="center" class="pr-3 ga-6 mt-3">
                <v-btn
                  rounded="xl"
                  height="45"
                  style="background-color: #E4EEF6"
                  variant="outlined"
                  color="#0563C1"
                  @click="copyToClipboard"
                  :disabled="!(this.createdQR || this.createdSignature)"
                >
                  Скопировать
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="text-left text-inter text-size-16 text-regular color-main-gray">
        <p>
          Подробная инструкция по установке: <a class="text-bold color-main-blue" href="https://r.muctr.ru/portal/worker" target="_blank">wiki.muctr.ru</a>
        </p>
        <p>
          Если в некоторых полях информация о Вас указана некорректно, пожалуйста, обратитесь в Единый центр поддержки пользователей (<a class="color-main-blue text-decoration-none" href="mailto:support@muctr.ru">support@muctr.ru</a>)
        </p>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapActions, mapGetters } from "vuex"
import { vMaska } from 'maska'
import TitledPageMixin from "@/mixins/TitledPageMixin"
import SignatureComponent from "@/modules/profile/service/personalSignature/SignatureComponent.vue"
import html2canvas from 'html2canvas'
export default {
  name: "PersonalSignature",
  components: { VueQrcode, SignatureComponent },
  directives: { maska: vMaska },
  mixins: [TitledPageMixin],
  data () {
    return {
      page_title: 'Личная подпись',
      vc: null,
      isLoadingAccount: false,
      haveSecondPosition: false,
      showSecondPosition: false,
      canEditPhone: false,
      createdQR: false,
      createdSignature: false,
      phone_options: {
        mask: "+7 (###) ###-##-##",
        eager: true
      },
      fio: '',
      firstPosition: '',
      firstDepartment: '',
      secondPosition: '',
      secondDepartment: '',
      workerPhone: '',
      workerMail: '',
      personalMail: null,
      personalPhone: '',
      departmentLogo: '',
      signatureData: {},
      address: null,
      canvasQR: null,
      phoneRules: [
        value => {
          if (value !== '') {
            const pattern = /^\+7[ ]\(\d{3}\)[ ]\d{3}-\d{2}-\d{2}$/
            return pattern.test(value) || 'Некорректный ввод'
          }
        },
      ],
      addressRules: [
        value => {
          if (value) return true
          return 'Выберите адрес'
        },
      ],
      addressSelector: [
        {
          title: '125047, г. Москва, Миусская площадь, д. 9',
          value: {
            idx: '125047',
            city: 'Москва',
            street: 'Миусская площадь, д. 9',
            urlMap: 'https://yandex.ru/maps/org/1364845373'
          },
        },
        {
          title: '125480, г. Москва, ул. Героев Панфиловцев, д. 20',
          value: {
            idx: '125480',
            city: 'Москва',
            street: 'Героев Панфиловцев, д. 20',
            urlMap: 'https://yandex.ru/maps/org/1263632100'
          },
        },
        {
          title: '125480, г. Москва, ул. Вилиса Лациса, д. 21',
          value: {
            idx: '125480',
            city: 'Москва',
            street: 'Вилиса Лациса, д. 21',
            urlMap: 'https://yandex.ru/maps/org/1141661774'
          },
        }
      ],
      mailsSelector: [],
      snackbar: false,
      text: 'Скопировано в буфер обмена.',
      timeout: 1500,
      vcard3: ''
    }
  },
  computed: {
    ...mapGetters({
      getWorker: "worker/getWorkerDetail",
      getAccount: "user/getProfile",
      isLoadingWorker: "worker/isLoadingWorkerDetail"
    })
  },
  methods: {
    ...mapActions({
      loadWorkerDetail: "worker/loadWorkerDetail",
      loadAccountDetail: "user/loadAccountDetail"
    }),
    loadWorker () {
      this.loadWorkerDetail({
        url: null,
        finalizer: (data) => {
          this.setWorker(data)
        },
      })
    },
    loadAccount () {
      this.isLoadingAccount = true
      this.loadAccountDetail({
        url: null,
        finalizer: (data) => {
          this.isLoadingAccount = false
          this.setAccount(data)
        },
        catcher: () => {
          this.isLoadingAccount = false
        },
      })
    },
    setAccount (getAccount) {
      this.fio = getAccount.lastname + ' ' + getAccount.firstname + ' ' + getAccount.midname
      const currWorkerPhone = getAccount.phones.find((el) => el.kind === 'Служебный')
      if (currWorkerPhone) this.workerPhone = currWorkerPhone.number
      this.mailsSelector.push(...getAccount.emails.map((el) => el.email))
      this.workerMail = getAccount.username + '@muctr.ru'
    },
    setWorker (getWorker) {
      const currHelds = getWorker.position_helds.filter((el) => !el.is_fired)
      if (currHelds.length) {
        this.firstPosition = currHelds[0].title
        this.firstDepartment = currHelds[0].department
        if (currHelds.length > 1) {
          this.haveSecondPosition = true
          this.secondPosition = currHelds[1].title
          this.secondDepartment = currHelds[1].department
        }
      }
    },
    async createQRCode () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.clearSignature()
        await this.createVCard()
        this.createdQR = true
      }
    },
    async createVCard () {
      const fname = this.fio.split(' ')
      const checkmail = (this.personalMail !== null)
        ? `EMAIL: ${this.personalMail}`
        : ``
      this.vcard3 =
`BEGIN:VCARD
VERSION:3.0
N:${fname[2]};${fname[0]};${fname[1]}
FN:${fname[2]} ${fname[0]} ${fname[1]}
TITLE:${this.firstPosition}
ORG:РХТУ им. Д.И. Менделеева; ${this.firstDepartment}
EMAIL;TYPE=INTERNET:${this.workerMail}` + '\n' + checkmail + '\n' +
        `TEL;TYPE=voice,work,pref:${this.workerPhone}
TEL;TYPE=voice,cell,pref:${this.personalPhone}
ADR:;;г. ${this.address.city}, ул. ${this.address.street};${this.address.city};Россия;${this.address.idx}
END:VCARD`
    },
    async createSignature () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.clearSignature()
        await this.createVCard()
        this.signatureData = {
          vcard3: this.vcard3,
          fio: this.fio,
          firstPosition: this.firstPosition,
          firstDepartment: this.firstDepartment,
          secondPosition: this.showSecondPosition ? this.secondPosition : '',
          secondDepartment: this.showSecondPosition ? this.secondDepartment : '',
          workerMail: this.workerMail,
          personalMail: this.personalMail,
          workerPhone: this.workerPhone,
          personalPhone: this.personalPhone,
          address: this.address
        }
        this.createdSignature = true
      }
    },
    onReadyQR (canvas) {
      this.canvasQR = canvas
    },
    copySignatureAsImage () {
      if (this.createdSignature) {
        html2canvas(document.querySelector(".preview-wrapper")).then(canvas => {
          canvas.toBlob(function (blob) {
            const item = new ClipboardItem({ "image/png": blob })
            navigator.clipboard.write([item])
          })
          this.snackbar = true
        })
      }
    },
    copyToClipboard () {
      if (this.createdSignature) {
        const copyData = document.querySelector('.signature-wrapper')

        const range = document.createRange()

        range.setStart(copyData, 0)
        range.setEndAfter(copyData)
        window.getSelection()?.removeAllRanges()
        window.getSelection()?.addRange(range)

        document.execCommand("copy")

        window.getSelection()?.removeAllRanges()
        document.execCommand("copy")

        // if (copyData) {
        //   navigator.clipboard.writeText(copyData.textContent)
        // }
        this.snackbar = true
      }
      if (this.createdQR) {
        this.canvasQR.toBlob(function (blob) {
          const item = new ClipboardItem({ "image/png": blob })
          navigator.clipboard.write([item])
        })
        this.snackbar = true
      }
    },
    clearSignature () {
      this.createdSignature = false
      this.createdQR = false
      this.canvasQR = null
      this.vcard3 = ''
    }
  },
  beforeMount () {
    if (!this.getWorker) this.loadWorker()
    else this.setWorker(this.getWorker)
    if (!this.getAccount) this.loadAccount()
    else this.setAccount(this.getAccount)
  }
}
</script>

<style scoped lang="scss">
  .preview-wrapper {
    overflow-x: auto;
    width: auto;
    min-height: 250px;
  }

  v-text-field label {
    color: #032F59;
  }
  .color-main-blue {
    color: #0563C1
  }
  .color-main-gray {
    color: #8C8C8C
  }
  .color-light-gray {
    color: #C7C7C7
  }
  .text-size-10 {
    font-size: 10px;
  }
  .text-size-12 {
    font-size: 12px;
  }
  .text-size-14 {
    font-size: 14px;
  }
  .text-size-16 {
    font-size: 16px;
  }
  .text-size-18 {
    font-size: 18px;
  }
  .text-roboto {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    line-height: normal;
  }
  .text-inter {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: normal;
  }
  .text-regular {
    font-weight: 400;
  }
  .text-medium {
    font-weight: 500;
  }
  .text-bold {
    font-weight: 700;
  }
  .size-16 {
    height: 16px;
  }
  .disabled {
    opacity: 0.38;
    user-select: none;
  }
</style>
