<template>
  <v-container :fluid="true" style="width: 100%">
    <EducationDetail v-if="$route.name === names.STUDENT.MAIN"></EducationDetail>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import names from "../routers/names"
import EducationDetail from "./components/EducationDetail"
import TitledPageMixin from '@/mixins/TitledPageMixin'

export default {
  name: "EducationPage",
  components: { EducationDetail },
  mixins: [TitledPageMixin],
  data () {
    return {
      page_title: "Обучающийся",
    }
  },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>
