import {
  ORGANIZATION_HOST,
  ORGANIZATION_INSTANCE,
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  EDUCATION_HOST,
  EDUCATION_INSTANCE,
  FEEDBACK_HOST,
  FEEDBACK_INSTANCE,
  MAILCONTROL_HOST,
  MAILCONTROL_INSTANCE,
  SIGNER_HOST,
  SIGNER_INSTANCE,
  REFERENCE_HOST,
  REFERENCE_INSTANCE,
  EVENTCHAIN_HOST,
  EVENTCHAIN_INSTANCE
} from '@/helper/consts'
import { getUrlAllowed, getUrlWithParameters } from '@/helper/urls'

export default {
  getAccountsUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
    )
  },
  getWorkerUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
    )
  },
  allowedWorkerOrderUrl (name, method) {
    return getUrlAllowed(ORGANIZATION_INSTANCE, name, method)
  },
  getEducationUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters, gets
    )
  },
  allowedEducationOrderUrl (name, method) {
    return getUrlAllowed(EDUCATION_HOST, name, method)
  },
  getFeedbackUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      FEEDBACK_HOST, FEEDBACK_INSTANCE, name, method, parameters, gets
    )
  },
  getMailUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      MAILCONTROL_HOST, MAILCONTROL_INSTANCE, name, method, parameters, gets
    )
  },
  getSignerUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      SIGNER_HOST, SIGNER_INSTANCE, name, method, parameters, gets
    )
  },
  getEventChainUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      EVENTCHAIN_HOST, EVENTCHAIN_INSTANCE, name, method, parameters, gets
    )
  },
  getReferenceUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      REFERENCE_HOST, REFERENCE_INSTANCE, name, method, parameters, gets
    )
  },
  getReferenceUrlAllowed: function (name, method) {
    return getUrlAllowed(REFERENCE_INSTANCE, name, method)
  }
}
