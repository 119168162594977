import store from '@/store'

export function isAuthenticated () {
  return store.state.user.isAuthenticated
}

export function isAliveToken (success = () => {}, error = () => {}) {
  if (store.getters['auth/isCheckExpired']()) {
    store.dispatch('auth/CHECK_TOKEN_ALIVE').then(
      () => {
        success()
      }
    ).catch(error)
  } else {
    success()
  }
}

export function goToLoginPage () {
  const params = (new URL(document.location)).searchParams
  const from = params.get("from")
  if (from) {
    // хук для кривого редиректа
    document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=` + from
  } else {
    document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
  }
}
