<template>
  <scroll-center-dialog v-model="open"   @close="close" @apply="checkPass" :title="'Изменить пароль'" :scrolled="false">
    <v-form v-model="valid" class="px-2">
      <v-card-text class="title px-0">Выберите надежный пароль и не используйте его для других аккаунтов
      </v-card-text>
      <v-text-field
        class="py-1"
        :error="hasError('old_password')"
        :error-messages="getError('old_password')"
        :rules="[rules.required ,rules.passStr, rules.notLogin]"
        label="Старый пароль пользователя"
        placeholder="Пароль"
        ref="password"
        variant="outlined"
        :title= PASSWORD_TEXT
        v-model="pass.old_password"
        :append-icon="show ? 'visibility_off' : 'visibility'"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :loading="loading"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :rules="[rules.required ,rules.passStr, rules.notLogin]"
        label="Новый пароль пользователя"
        placeholder="Пароль"
        ref="password"
        variant="outlined"
        :title= PASSWORD_TEXT
        v-model="pass.new_password"
        :append-icon="shownew ? 'visibility_off' : 'visibility'"
        :type="shownew ? 'text' : 'password'"
        :error="hasError('password')"
        :error-messages="getError('password')"
        @click:append="shownew= !shownew"
        :loading="loading"
      ></v-text-field>
      <v-text-field
        class="py-1"
        :rules="[rules.required,rules.passStr, rules.identif, rules.notLogin]"
        label="Повторите пароль"
        placeholder="Пароль"
        ref="password"
        variant="outlined"
        @click:append="shownew2= !shownew2"
        :title= PASSWORD_TEXT
        v-model="password"
        :append-icon="shownew2 ? 'visibility_off' : 'visibility'"
        :type="shownew2 ? 'text' : 'password'"
        :loading="loading"
      ></v-text-field>
      <template v-if="hasAdditional">
        <v-checkbox
          v-model="pass.force"
          label="Сменить во всех связанных аккаунтах"
          variant="outlined"
          class="mt-n4"
          :error="hasError('force')"
          :error-messages="getError('force')"
        ></v-checkbox>
        <v-scroll-y-transition>
          <v-alert
            v-if="pass.force"
            :value="true"
            type="info"
            density="comfortable"
            variant="outlined"
            class="mt-n4"
          >
            <b>Важно!</b>
            После смены пароля на всех связанных учетных записях все активные сессии будут прекращены.
          </v-alert>
        </v-scroll-y-transition>
      </template>
    </v-form>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog"
import { PASSWORD_PATTERN } from "@/helper/consts"
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions } from 'vuex'

export default {
  name: "ChangePass",
  props: {
    value: Boolean,
    edit: Boolean,
    hasAdditional: Boolean,
    username: String
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      show: false,
      shownew: false,
      shownew2: false,
      PASSWORD_TEXT: [
        "Пароль должен содержать не менее 12 символов, минимум 1 букву нижнего, верхнего регистров (А - Z, a - z), цифру (от 0 до 9), а также специальные символы (!#%&*+-.:<=>?@^_|~) и не должен совпадать с пятью предыдущими"
      ].join(''),
      rules: {
        required: value => !!value || 'Пустое поле',
        identif: value => {
          return ((value === this.password) && (value === this.pass.new_password)) || 'Пароли не совпадают'
        },
        notLogin: value => {
          const username = String(this.username).replace('@muctr.ru', '')
          return value.includes(username) === false || 'Пароль не должен содержать в себе логин'
        },
        passStr: value => {
          return PASSWORD_PATTERN.test(value) || 'Пароль должен содержать не менее 12 символов, минимум 1 букву нижнего, верхнего регистров (А - Z, a - z), цифру (от 0 до 9), а также специальные символы (!#%&*+-.:<=>?@^_|~) и не должен совпадать с пятью предыдущими'
        },
      },
      valid: false,
      pass: {
        old_password: '',
        new_password: '',
      },
      password: '',
      loading: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  methods: {
    ...mapActions({
      changePass: 'user/changePass'
    }),
    checkPass () {
      if (this.password === this.pass.new_password) {
        this.loading = true
        const data = this.pass
        this.changePass({
          data,
          finalizer: (data, url) => {
            this.loading = false
            this.$store.dispatch('auth/AUTH_LOGOUT')
            document.location.href = process.env.VUE_APP_LOGIN_HOST
          },
          catcher: (data, url) => {
            this.loading = false
            this.errors.fields = data
          }
        })
      }
    },
    save () {
      this.$emit('close')
    },
  },
  close () {
    this.$emit('close')
  },
  created () {

  }
}
</script>

<style scoped>

</style>
