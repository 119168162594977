import { MESSAGES_TYPES, SocketV2 } from '@/helper/socket'

const state = () => ({
  socket: null,
  isConnected: false,
  token: null,
});

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_SOCKET (state, socket) {
    state.socket = socket;
  },
  SET_IS_CONNECTED (state, value) {
    state.isConnected = value;
  },
  APPEND_HANDLER (state, { name, handler }) {
    state.socket.addHandler(name, handler)
  },
  REPLACE_HANDLERS (state, handlers) {
    state.socket.replaceHandlers(handlers)
  },
};

const actions = {
  init ({ commit, dispatch, getters }, { url = null, handlers = null }) {
    const current_token = getters.token
    if (!getters.isInit) {
      const socket = new SocketV2({
        url, handlers
      })
      commit('SET_SOCKET', socket);
      socket.connect({}).then(({ getters }) => {
        commit('SET_IS_CONNECTED', true)
        socket.sendMessage(
          {
            type: MESSAGES_TYPES.AUTH,
            token: current_token
          }
        )
      }).catch(() => {
        commit('SET_IS_CONNECTED', false)
      })
    }
  },
  connect ({ getters, commit }, { url }) {
    if (getters.isConnected) {
      getters.socket.disconnect()
    }
    getters.socket.connect({
      url
    }).catch(() => {
      commit('SET_IS_CONNECTED', false)
    })
  },
  disconnect ({ getters, commit }) {
    return new Promise(resolve => {
      if (getters.isConnected) {
        getters.socket.disconnectWithClearSocket(resolve)
      }
      commit('SET_IS_CONNECTED', false)
    })
  },
  setToken ({ getters, commit }, { token = null }) {
    commit('SET_TOKEN', token)
  },
  sendMessages ({ getters }, { message }) {
    getters.socket.sendMessage(message)
  }
};

const getters = {
  isConnected: (state) => state.socket && state.isConnected,
  isInit: (state) => state.socket !== null,
  socket: (state) => state.socket,
  handlers: state => state.socket.handlers,
  token: (state) => state.token,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
