<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_74_2111)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.25C10 6.91304 9.73661 7.54893 9.26777 8.01777C8.79893 8.48661 8.16304 8.75 7.5 8.75C6.83696 8.75 6.20107 8.48661 5.73223 8.01777C5.26339 7.54893 5 6.91304 5 6.25C5 5.58696 5.26339 4.95107 5.73223 4.48223C6.20107 4.01339 6.83696 3.75 7.5 3.75C8.16304 3.75 8.79893 4.01339 9.26777 4.48223C9.73661 4.95107 10 5.58696 10 6.25ZM7.5 10C8.49456 10 9.44839 9.60491 10.1517 8.90165C10.8549 8.19839 11.25 7.24456 11.25 6.25C11.25 5.25544 10.8549 4.30161 10.1517 3.59835C9.44839 2.89509 8.49456 2.5 7.5 2.5C6.50544 2.5 5.55161 2.89509 4.84835 3.59835C4.14509 4.30161 3.75 5.25544 3.75 6.25C3.75 7.24456 4.14509 8.19839 4.84835 8.90165C5.55161 9.60491 6.50544 10 7.5 10ZM15 16.25C15 17.5 13.75 17.5 13.75 17.5H1.25C1.25 17.5 0 17.5 0 16.25C0 15 1.25 11.25 7.5 11.25C13.75 11.25 15 15 15 16.25ZM13.75 16.245C13.7488 15.9375 13.5575 15.0125 12.71 14.165C11.895 13.35 10.3613 12.5 7.5 12.5C4.6375 12.5 3.105 13.35 2.29 14.165C1.4425 15.0125 1.2525 15.9375 1.25 16.245H13.75ZM19.8175 6.4325C19.8757 6.49056 19.9219 6.55953 19.9534 6.63546C19.9849 6.71139 20.0011 6.79279 20.0011 6.875C20.0011 6.95721 19.9849 7.03861 19.9534 7.11454C19.9219 7.19047 19.8757 7.25944 19.8175 7.3175L16.0675 11.0675C16.0094 11.1257 15.9405 11.1719 15.8645 11.2034C15.7886 11.2349 15.7072 11.2511 15.625 11.2511C15.5428 11.2511 15.4614 11.2349 15.3855 11.2034C15.3095 11.1719 15.2406 11.1257 15.1825 11.0675L13.3075 9.1925C13.1901 9.07514 13.1242 8.91597 13.1242 8.75C13.1242 8.58403 13.1901 8.42486 13.3075 8.3075C13.4249 8.19014 13.584 8.12421 13.75 8.12421C13.916 8.12421 14.0751 8.19014 14.1925 8.3075L15.625 9.74125L18.9325 6.4325C18.9906 6.3743 19.0595 6.32812 19.1355 6.29661C19.2114 6.2651 19.2928 6.24888 19.375 6.24888C19.4572 6.24888 19.5386 6.2651 19.6145 6.29661C19.6905 6.32812 19.7594 6.3743 19.8175 6.4325Z" fill="#0563C1"/>
    </g>
    <defs>
      <clipPath id="clip0_74_2111">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PersonCheckIcon'
}
</script>

<style scoped>

</style>
