<template>
  <scroll-center-dialog v-model="open" :loading="save_loading" width="700" @close="close" @apply="save"
                        :apply-text="'Завершить'" :closed="sign_success || save_loading ? false : true"
                        :title="form_title" :scrolled="false">

    <div v-if="answerSuccess.length === 0 && selectors.position.length > 0  ">
      <v-alert
        :value="true"
        type="info"
        density="comfortable"
        variant="outlined"
        class="mt-4"
      >
        Чтобы повторно подписать документ, используйте уже ранее подписанный не измененный файл.
      </v-alert>
      <v-select
        class="mt-4 py-1"
        v-model="signature.as_position"
        :items="selectors.position"
        item-title="position"
        item-value="uid"
        :readonly="save_loading"
        :disabled="save_loading"
        label="Должность для подписи"
        variant="outlined"
        :error="hasError('as_position')"
        :error-messages="getError('as_position')"
      ></v-select>
      <v-form v-model="is_access">
        <v-file-input
          v-model="signature.file"
          counter
          :label="$vuetify.display.smAndUp ? 'Выберите или перетащите файл (.pdf) *' : 'Выберите файл (.pdf) *'"
          placeholder="Выберите или перетащите файл (.pdf)"
          prepend-icon="attach_file"
          variant="outlined"
          :rules="[rules.size,rules.required]"
          :readonly="save_loading"
          :disabled="save_loading"
          :show-size="1000"
          :error="hasError('file')||hasError('detail')"
          :error-messages="getError('file')||getError('detail')"
          multiple
          accept=".pdf"
        >
          <template v-slot:selection="{ fileNames }">
            <template v-for="(fileName, index) in fileNames" :key="`${index}_file_chip`">
              <v-chip
                label
                size="small"
                :class="this.errors.fields.file ? 'mr-2 bg-red-darken-2' : 'mr-2 medium-primary'"
              >
                {{ fileName }}
              </v-chip>
            </template>
          </template>
          </v-file-input>
        </v-form>
        <v-alert
          :value="true"
          type="info"
          density="comfortable"
          variant="outlined"
          class="mt-1"
        >
          Осуществляя ввод пароля и нажимая кнопку "Подписать" Вы осуществляете подписание документов и признаете равнозначность своей
          <v-tooltip  maxWidth="400" location="bottom" text='
                    Для подписания электронных документов в системе используется ПЭП.
                    В качестве публичной части ключа ПЭП используется уникальное имя учетной записи, применяемое для авторизации в системе. В качестве конфиденциальной части ключа ПЭП используется пароль к учетной записи.
                    Подписание ПЭП инициируется соответствующим действием владельца ПЭП, а значит не выполняется автоматически или незаметно для владельца.
                    Подписание Документа выполняется путем ввода пароля в интерфейсе системы и последующего нажатия на кнопку "Подписать".
        '>
            <template v-slot:activator="{ props }">
            <span
              class="font-weight-bold"
              v-bind="props"
            >
              ПЭП
            </span>
            </template>
          </v-tooltip>
          собственноручной подписи на бумажном носителе.
        </v-alert>
        <v-text-field
          class="mt-4 py-1"
          :rules="[rules.required]"
          label="Пароль пользователя"
          placeholder="Пароль пользователя"
          ref="password"
          variant="outlined"
          v-model="password"
          :append-inner-icon="show_password ? 'visibility_off' : 'visibility'"
          :type="show_password ? 'text' : 'password'"
          @click:append-inner="show_password = !show_password"
          :readonly="save_loading"
          :disabled="save_loading"
          :error="hasError('password')"
          :error-messages="getError('password')"
        ></v-text-field>
      </div>
<!--    </div>-->
    <div v-else-if="answerSuccess.length > 0" class="mt-4">
      <v-progress-linear
        v-model="progress_signature"
        color="blue-darken-1"
        height="18"
        class="mt-1 mb-4"
        rounded
      >
        <strong class="text-white">{{ Math.ceil(progress_signature) }}%</strong>
      </v-progress-linear>
      <v-alert
        v-for="(item, index) in answerSuccess"
        :key="index"
        :value="true"
        type="success"
        density="comfortable"
        color="green-darken-1"
        class="mt-1"
      >
        Файл {{ item.file_name }} успешно подписан
      </v-alert>
    </div>
    <v-row no-gutters justify="center" v-if="selectors.position.length === 0" >
      <semipolar-spinner
        :animation-duration="2000"
        :size="$vuetify.display.mdAndUp ? 80 : 40"
        color="#1976D2"
        class="ma-12"
      />
    </v-row>

    <template #actions>
      <v-row no-gutters justify="center">
        <v-spacer v-if="$vuetify.display.mdAndUp"/>
        <v-btn
          :class="$vuetify.display.mdAndUp ? '' : 'ml-2'"
          color="blue-darken-1"
          variant="text"
          :loading="save_loading"
          :readonly="save_loading"
          @click="saveFileOrCancel"
        >
          <v-icon class="mr-2" v-if="sign_success">cloud_download</v-icon>
          <span v-if="!sign_success">ОТМЕНА</span>
          <span v-else>СКАЧАТЬ ФАЙЛ<span v-if="answerSuccess.length > 1">Ы</span></span>
        </v-btn>
        <v-btn
          v-if="!sign_success "
          color="blue-darken-1"
          variant="text"
          :loading="save_loading"
          :readonly="save_loading"
          @click="startSign"
        >
          ПОДПИСАТЬ
        </v-btn>
        <v-menu location="center" v-if="sign_success">
          <template v-slot:activator="{ props: menu }">
            <v-btn
              variant="text"
              color="blue-darken-1"
              v-bind="menu"
            >
              <v-icon class="mr-2">outgoing_mail</v-icon>
              <span>ОТПРАВИТЬ ФАЙЛ<span v-if="answerSuccess.length > 1">Ы</span></span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(list_item, index) in signerRecipients"
              :key="index"
              @click="sendMail(list_item, answerSuccess)"
            >
              <v-list-item-title>{{ list_item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="answerSuccess.length !== 0 && !save_loading"
          color="blue-darken-1"
          variant="text"
          :loading="save_loading"
          :readonly="save_loading"
          @click="save"
        >
          ЗАКРЫТЬ
        </v-btn>
      </v-row>
    </template>

    <pop-up-alert
      v-model="errorSend"
      top-color="error"
      close-color="grey-darken-2"
      title="Системная ошибка"
      text='Попробуйте еще раз. <br> В случае возникновения вопросов обратитесь на почту <a class="medium-primary" style="text-decoration: none; color: inherit" href="mailto:support@muctr.ru"> support@muctr.ru </a>.'
      :scrolled="false"
      @close="errorSend = false"
    >
    </pop-up-alert>
    <pop-up-alert
      v-model="errorApprove"
      top-color="error"
      close-color="grey-darken-2"
      title="Ошибка в процессе подписания"
      :text="getError('detail')"
      :scrolled="false"
      @close="close"
    >
    </pop-up-alert>
    <send-email-dialog
      v-if="dialog_send"
      v-model="dialog_send"
      :files="send_files"
      :recipient="send_recipient"
      :append-file="false"
      @save="closeSendMailDialog"
      @close="dialog_send=false"
    >
    </send-email-dialog>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import { mapActions, mapGetters } from 'vuex'
import PopUpAlert from '@/modules/templates/PopUpAlert.vue'
import SendEmailDialog from '@/modules/profile/service/signer/modals/SendEmailDialog.vue'
import { SemipolarSpinner } from 'epic-spinners'

export default {
  name: 'SignatureDialog',
  props: {
    value: Boolean,
  },
  components: {
    ScrollCenterDialog,
    PopUpAlert,
    SemipolarSpinner,
    SendEmailDialog
  },
  mixins: [FormErrorsMixin],
  data () {
    return {
      is_access: false,
      hash: null,
      password: null,
      show_password: false,
      rules: {
        required: value => !!value || 'Пустое поле',
        size: files => !files || !files.some(file => file.size > 15e6) || 'Максимальный размер файлов не должен превышать 15 Мб',
      },
      errorApprove: false,
      open: false,
      save_loading: false,
      change_file: false,
      sign_success: false,
      dialog_send: false,
      send_files: undefined,
      send_recipient: undefined,
      errorSend: false,
      progress_signature: 0,
      selectors: {
        position: []
      },
      signature: {
        as_position: 'null',
        file: []
      },
      answerSuccess: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    },
    signature: {
      handler: function () {
        this.errors = {
          fields: {},
          non: []
        }
      },
      deep: true
    }
  },
  computed: {
    form_title () {
      return 'Подписать файл ЭЦП'
    },
    ...mapGetters({
      signerRecipients: 'selectors/signerRecipients',
    })
  },
  methods: {
    ...mapActions({
      loadPositionHeldDetail: 'worker/loadPositionHeldDetail',
      createSignature: 'signer/createSignature',
      saveFile: 'signer/saveFile',
      getApproveHash: 'signer/getApproveHash',
    }),
    saveFileOrCancel () {
      if (this.sign_success) {
        this.downloadFile()
      } else {
        this.close()
      }
    },
    loadChoices (url = undefined) {
      this.loadPositionHeldDetail({
        url,
        finalizer: (data, url) => {
          let arr = []
          arr = data
          arr = arr.filter(function (item) {
            return !item.is_fired
          })
          if (arr.length > 1) {
            this.selectors.position = arr
          }

          this.selectors.position.push({
            position: 'Основная должность',
            uid: 'null'
          })
        }
      })
    },
    startSign () {
      this.save_loading = true
      if (typeof this.signature.file === 'object' && this.signature.file.length > 0 && this.is_access) {
        this.getApproveHash({
          data: { password: this.password },
          finalizer: (val) => {
            this.hash = val.hash
            this.password = null
            this.sendFile(this.signature.file[0], 0)
          },
          catcher: (val) => {
            this.password = null
            this.errors.fields = val
            this.save_loading = false
          },
        })
      } else {
        this.save_loading = false
        this.errors.fields = { file: ['Обязательное поле.'] }
      }
    },
    sendFile (file, index) {
      this.progress_signature = index / this.signature.file.length * 100

      if (this.signature.file.length > index) {
        const sendData = new FormData()

        if (this.hash !== null) {
          sendData.append('hash', this.hash)
        }

        if (this.signature.as_position !== 'null') {
          sendData.append('as_position', this.signature.as_position)
        }

        if ((typeof file === 'object')) {
          sendData.append('file', file, file.name)
        }

        this.createSignature({
          data: sendData,
          finalizer: (val) => {
            this.answerSuccess.push(val)

            if (this.signature.file.length - 1 === index) {
              this.progress_signature = 100
              this.sign_success = true
              this.hash = null
              this.save_loading = false
            } else {
              index++
              this.sendFile(this.signature.file[index], index)
            }
          },
          catcher: (val) => {
            if (val.code) {
              this.errorApprove = true
            }
            this.errors.fields = val
            if (String(val).search('JSON') !== -1) {
              this.errorSend = true
            }
            this.save_loading = false
          },
        })
      }
    },
    downloadFile () {
      for (const i in this.answerSuccess) {
        this.saveFile({
          id: this.answerSuccess[i].id,
          finalizer: (data, url) => {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(data)
            a.download = this.answerSuccess[i].file_name
            a.click()
          },
          catcher: (val) => {
          },
        })
      }
    },
    sendMail (list_item, doc) {
      this.dialog_send = true
      this.send_files = doc
      this.send_recipient = list_item
    },
    closeSendMailDialog () {
      this.dialog_send = false
      this.send_files = undefined
      this.send_recipient = undefined
    },

    save () {
      this.$emit('save')
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.loadChoices()
  }
}
</script>

<style scoped>

</style>
