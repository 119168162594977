<template>
  <v-container fluid>
    <v-expansion-panels v-if="organization && !isLoadingOrganizationDetail" v-model="panel">
      <v-expansion-panel v-for="(col,i) in  organization" no-gutters :key="`r${i}`" class="rounded-lg">
        <v-expansion-panel-title>
          <h3 class="text-body-1 dark-primary">
            {{ col['full_name'] }}
          </h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-card flat>
            <v-row
              v-for="(row,j) in Object.keys(fields)"
              :key="`d${j}_${i}`"
              no-gutters
              :class="(j%2) ? 'pa-3 bg-grey-lighten-3 rounded-lg text-left' : 'pa-3 rounded-lg text-left'"
            >
              <v-col cols="12" md="3">{{ fields[row].text }}</v-col>
              <v-col cols="12" md="9">{{ col[fields[row].value] }}</v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row no-gutters justify="center" v-else>
      <semipolar-spinner
        :animation-duration="2000"
        :size="$vuetify.display.mdAndUp ? 200 : 100"
        color="#1976D2"
        class="mt-16"
      />
      <v-col cols="12">
        <span>Данные загружаются...</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import names from '@/modules/profile/routers/names'
import TitledPageMixin from '@/mixins/TitledPageMixin'
import { SemipolarSpinner } from "epic-spinners"

export default {
  name: 'OrganizationPage',
  mixins: [TitledPageMixin],
  components: { SemipolarSpinner },
  data: function () {
    return {
      page_title: "Информация об организации",
      panel: [],
      loadingPage: false,
      errorLoadPage: '',
      fields: [
        {
          text: 'Полное наименование:',
          value: 'full_name'
        },
        {
          text: 'Полное наименование на английском языке:',
          value: 'full_name_eng'
        },
        {
          text: 'Краткое наименование:',
          value: 'name'
        },
        {
          text: 'Краткое наименование на английском языке:',
          value: 'name_eng'
        },
        {
          text: 'Наименование получателя средств:',
          value: 'name_of_the_beneficiary'
        },
        {
          text: 'Юридический адрес:',
          value: 'legal_address'
        },
        {
          text: 'Телефон:',
          value: 'phone'
        },
        {
          text: 'ОГРН:',
          value: 'ogrn'
        },
        {
          text: 'ИНН:',
          value: 'inn'
        },
        {
          text: 'КПП:',
          value: 'kpp'
        },
        {
          text: 'ОКТМО:',
          value: 'oktmo'
        },
        {
          text: 'ОКПО:',
          value: 'okpo'
        },
        {
          text: 'ОКОГУ:',
          value: 'okogy'
        },
        {
          text: 'ОКОПФ:',
          value: 'okopf'
        },
        {
          text: 'ОКВЭД:',
          value: 'okved'
        },
        {
          text: 'ОКФС:',
          value: 'okfc'
        },
        {
          text: 'Расчетный счет:',
          value: 'checking_account'
        },
        {
          text: 'Корреспондентский счет:',
          value: 'correspondent_account'
        },
        {
          text: 'Лицевой счет:',
          value: 'personal_account'
        },
        {
          text: 'Полное наименование банка:',
          value: 'full_name_bank'
        },
        {
          text: 'Краткое наименование банка:',
          value: 'name_bank'
        },
        {
          text: 'БИК:',
          value: 'bik'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      organization: 'worker/getOrganization',
      isLoadingOrganizationDetail: 'worker/isLoadingOrganizationDetail'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadOrganization: 'worker/loadOrganization',
    }),
    loadData (url = undefined) {
      this.loadOrganization({
        url,
        finalizer: (data, url) => {
          this.panel = 0
        },
        catcher: () => {
          router.push({ name: names.BASE })
        }
      })
    },
  },
  created: function () {
    if (!this.organization) {
      this.loadData()
    }
  },

}
</script>

<style scoped>

</style>
