<template>
  <scroll-center-dialog v-model="open" @close="close" @apply="saveImg" :title="'Изменить аватар'" :scrolled="false">
    <v-card flat class="px-2">
      <v-row style="height: 350px" align="center" justify="center">
        <v-col cols="12" md="6" :class="$vuetify.display.smAndDown ? 'justify-center d-inline-flex' : ''">
          <v-btn
            style="position: absolute; left: 260px"
            flat icon @click="clearFile">
            <v-icon>delete</v-icon></v-btn>
          <vue-cropper
            :auto-crop-area="1"
            :aspect-ratio="1/1"
            style="max-width: 250px;"
            :img-style="{ 'width': '250px', 'height': '250px' }"
            :min-container-height="250"
            :min-container-width="250"
            :rotatable="true"
            :src="imgSrc"
            ref='cropper'>
          </vue-cropper>
        </v-col>
        <v-col cols="12" md="6"
               class="hidden-sm-and-down">
          <v-avatar
            :size="250">
            <v-img :src="cropImg"
            />
          </v-avatar>
        </v-col>
      </v-row>
      <v-alert
        :value="true"
        type="info"
        density="comfortable"
        variant="outlined"
        class="my-4"
      >
        Пожалуйста, убедитесь, что загружаемое изображение соответствует следующим критериям: формат файла - JPEG, PNG; максимальный размер файла - 10 МБ.
      </v-alert>
      <v-file-input
        v-model="file"
        variant="outlined"
        :multiple="false"
        accept="image/png, image/jpeg, image/bmp"
        label="Файл изображения"
        :rules="rules"
        clearable
      ></v-file-input>
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions } from 'vuex'
import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import VueCropper from 'vue-cropperjs'
import NotAvatar from '@/assets/NotAvatar.png'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageCropper',
  props: {
    value: Boolean,
    edit: Boolean,
    picture: Object
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    VueCropper
  },

  watch: {
    file: {
      immediate: true,
      handler () {
        if (this.file) {
          this.setImage(this.file)
        }
      }
    },
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    picture: {
      immediate: true,
      handler () {
        if (!this.picture) { this.imgSrc = NotAvatar } else {
          this.imgSrc = this.picture
        }
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  data () {
    return {
      imgSrc: NotAvatar,
      cropImg: NotAvatar,
      file: null,
      iLoad: false,
      oLoad: false,
      rules: [
        value => {
          return !value || !value.length || value[0].size < 15000000 || 'Аватар должен быть меньше 15 MB!'
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      changeAvatar: 'user/changeAvatar'
    }),
    clearFile () {
      this.file = undefined
      this.imgSrc = NotAvatar
      this.cropImg = NotAvatar
      this.$refs.cropper.replace(NotAvatar)
    },
    saveImg () {
      const data = new FormData()
      if (String(this.imgSrc).includes('NotAvatar')) {
        data.append('avatar', '')
        this.changeAvatar({
          data,
          finalizer: (data, url) => {
            this.imgSrc = NotAvatar
            this.cropImg = NotAvatar
            this.iLoad = false
            this.$emit('save')
          },
          catcher: (data, url) => {
            this.errors.fields = data
          }
        })
      } else {
        const i = this.imgSrc.indexOf('base64,')
        const type = this.imgSrc.slice(5, i - 1)
        const name = 'avatar.' + type.split('/')[1]
        this.iLoad = true
        fetch(this.cropImg)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], name, { type })
            const data = new FormData()
            if (this.file) {
              data.append('avatar', file)
              this.changeAvatar({
                data,
                finalizer: (data, url) => {
                  this.imgSrc = NotAvatar
                  this.cropImg = NotAvatar
                  this.iLoad = false
                  this.$emit('save')
                },
                catcher: (data, url) => {
                  this.errors.fields = data
                }
              })
            }
          })
      }
    },
    setImage (files) {
      const file = files
      if (file.size / 1024 / 1024 > 15) {
        alert('Please select a file less than 15MB')
        return
      } else {
        this.oLoad = true
      }
      if (!file.type.includes('image/')) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.cropImg = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
          this.oLoad = false
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      if (this.$refs.cropper) {
        if (this.$refs.cropper.getCroppedCanvas() !== null) {
          this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
        }
      }
    },

  },
  mounted () {
    this.$refs.cropper.clear()
    window.addEventListener('cropend', (event) => {
      this.cropImage()
    })
    window.addEventListener('zoom', (event) => {
      this.cropImage()
    })
  },
}
</script>

<style scoped>

</style>
