<template>
  <v-row no-gutters justify="center" v-show="isLoadingEducationDetail">
    <semipolar-spinner
      :animation-duration="2000"
      :size="$vuetify.display.mdAndUp ? 200 : 100"
      color="#1976D2"
      class="mt-16"
    />
  </v-row>

  <div v-show="!isLoadingEducationDetail">
    <div v-if="!!getEducation">
      <v-card
        class="rounded-lg mb-4"
        v-for="(education, index) in getEducation"
        :key="`${index}_education`"
      >
        <v-toolbar
          :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
          :height="toolbarDisplayHeight(education)"
        >
            <span class="dark-primary"> {{ education.direction }}
              <v-chip :class="$vuetify.display.xs ? 'mt-1' : ''" style="margin-bottom: 1px">
                {{ education.active ? 'Обучается' : 'Завершил обучение' }}
              </v-chip>
            </span>
          <v-spacer/>
          <v-toolbar-items>
            <v-tooltip
              location="bottom"
              text="Перейти в ЭИОС"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  flat
                  v-bind="props"
                  class="dark-primary"
                  @click="openInEIOS(education.id)"
                >
                    <span>
                      <v-icon>school</v-icon> ЭИОС
                    </span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="text-left pt-2">
          <v-row
            no-gutters
            :class="($vuetify.display.mdAndUp && i%2) ? 'bg-grey-lighten-3 rounded-lg' : 'rounded-lg'"
            v-for="(row, i) in data" :key="`row${i}`"
          >
            <v-col
              cols="12"
              :md="(row[col].typeField === 'solo') ? 12 : 6"
              :class="getTableColStyle(col, row, i)"
              v-for="col in Object.keys(row)" :key="`row${i}_${col}`"
            >
              <b>{{ row[col].text }}:</b> {{ education[row[col].field] }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import index, { mapActions, mapGetters } from 'vuex'
import { SemipolarSpinner } from "epic-spinners"

export default {
  name: 'EducationDetail',
  components: {
    SemipolarSpinner
  },
  data: function () {
    return {
      data: [
        [
          { text: 'Год поступления', field: 'entry_year' },
          { text: 'Форма обучения', field: 'form' },
        ],
        [
          { text: 'Уровень образования', field: 'level' },
          { text: 'Группа', field: 'education_group' },
        ],
        [
          { text: 'Зачетная книжка', field: 'record_book_number' },
          { text: 'Финансирование', field: 'financing' },
        ],
        [
          { text: 'Направление подготовки', field: 'direction', typeField: 'solo' },
        ],
        [
          { text: 'Профиль подготовки', field: 'profile', typeField: 'solo' },
        ],
        [
          { text: 'Факультет', field: 'faculty', typeField: 'solo' },
        ],
        [
          { text: 'Кафедра', field: 'cathedra', typeField: 'solo' },
        ],
      ],
    }
  },
  computed: {
    index () {
      return index
    },
    ...mapGetters({
      getEducation: 'education/getEducation',
      isLoadingEducationDetail: 'education/isLoadingEducationDetail',
    }),
  },
  methods: {
    ...mapActions({
      loadEducation: 'education/loadEducation',
    }),
    loadData (url = undefined) {
      this.loadEducation({
        url,
        finalizer: (data, url) => {}
      })
    },
    getTableColStyle (col, row, i) {
      if (this.$vuetify.display.smAndDown && (col % 2 || (row[col].typeField === 'solo' && i % 2 === 0))) {
        return 'pa-3 bg-grey-lighten-3 rounded-lg'
      }
      return 'pa-3 rounded-lg pr-md-8'
    },
    toolbarDisplayHeight (education) {
      if (this.$vuetify.display.width < 380 && (education.direction.length > 30)) {
        return 240
      } else if (this.$vuetify.display.width < 430 && (education.direction.length > 40)) {
        return 190
      } else if (this.$vuetify.display.width < 500 && (education.direction.length > 40)) {
        return 160
      } else if (this.$vuetify.display.xs) {
        return 128
      } else if (this.$vuetify.display.sm && (education.direction.length > 30)) {
        return 98
      } else if (this.$vuetify.display.width < 1680 && (education.direction.length > 80)) {
        return 86
      } else {
        return 48
      }
    },
    openInEIOS (id) {
      window.open((`https://eios.muctr.ru/education/student/${id}`), '_blank')
    }
  },
  created () {
    if (!this.getEducation) {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
