<template>
  <scroll-center-dialog v-model="open" :loading="save_loading" width="700" @close="close" @apply="save" :title="form_title" :closed="save_loading ? false : true" :scrolled="false">
    <div v-if="!send_success">
      <v-textarea
        v-if="appendFile"
        class="mt-4 mb-n5 py-1"
        v-model="send_msg"
        label="Текст письма"
        placeholder="Введите текст письма"
        variant="outlined"
        rows="3"
        :auto-grow="true"
        :error="hasError('sendMsg')"
        :error-messages="getError('sendMsg')"
      ></v-textarea>
      <v-progress-linear
        v-if="!appendFile"
        v-model="viewProgress"
        color="green-darken-1"
        height="18"
        class="mt-4 mb-4 py-1"
        rounded
      >
        <strong class="text-white">{{ Math.ceil( viewProgress ) }}%</strong>
      </v-progress-linear>
      <div v-if="!appendFile">
        <v-alert
          v-for="(item, index) in files"
          :key="index"
          :value="true"
          type="info"
          density="comfortable"
          color="blue-darken-1"
          class="mt-1"
        >
          Файл {{ item.file_name }} готов к отправке
        </v-alert>
      </div>
      <v-alert
        v-else
        type="info"
        density="comfortable"
        color="blue-darken-1"
        class="mt-4"
      >
        Файл {{ files.file_name }} готов к отправке
      </v-alert>
      <v-file-input
        class="mt-4"
        v-if="appendFile"
        v-model="append_file.file"
        counter
        label="Выберите дополнительный файл (.pdf)"
        placeholder="Выберите дополнительный файл (.pdf)"
        prepend-icon="attach_file"
        variant="outlined"
        accept=".pdf"
        :show-size="1000"
        :error="hasError('file')"
        :error-messages="getError('file')"
      >
        <template v-slot:selection="{ fileNames }">
          <template v-for="(fileName, index) in fileNames" :key="`${index}_file_chip`">
            <v-chip
              label
              size="small"
              :class="this.errors.fields.file ? 'mr-2 bg-red-darken-2' : 'mr-2 medium-primary'"
            >
              {{ fileName }}
            </v-chip>
          </template>
        </template>
      </v-file-input>
    </div>
    <v-alert
      v-else
      type="success"
      density="comfortable"
      color="green-darken-1"
      class="mt-4"
    >
      Ваше письмо успешно отправлено
    </v-alert>

    <template #actions>
        <v-spacer/>
        <v-btn
          color="blue-darken-1"
          variant="text"
          :loading="save_loading"
          :readonly="save_loading"
          @click="close"
        >
          <span v-if="!send_success">ОТМЕНА</span>
          <span v-else>ЗАКРЫТЬ</span>
        </v-btn>
      <v-btn
        v-if="!send_success"
        color="blue-darken-1"
        variant="text"
        :loading="save_loading"
        :readonly="save_loading"
        @click="save"
      >
        ОТПРАВИТЬ
      </v-btn>
    </template>

    <pop-up-alert
      v-model="errorSend"
      top-color="error"
      close-color="grey-darken-2"
      title="Системная ошибка"
      text='Попробуйте еще раз. <br> В случае возникновения вопросов обратитесь на почту <a class="medium-primary" style="text-decoration: none; color: inherit" href="mailto:support@muctr.ru"> support@muctr.ru </a>.'
      :scrolled="false"
      @close="errorSend = false"
    >
    </pop-up-alert>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog"
import { mapActions, mapGetters } from "vuex"
import PopUpAlert from "@/modules/templates/PopUpAlert.vue"

export default {
  name: "SendEmailDialog",
  props: {
    appendFile: Boolean,
    files: Object,
    recipient: Object,
    value: Boolean,
  },
  components: {
    ScrollCenterDialog,
    PopUpAlert,
  },
  mixins: [FormErrorsMixin],
  data () {
    return {
      open: false,
      errorSend: false,
      save_loading: false,
      send_success: false,
      progress_send: 0,
      send_msg: undefined,
      append_file: {
        file: [],
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    },
  },
  computed: {
    form_title () {
      return 'Отправка на электронную почту'
    },
    viewProgress () {
      return this.progress_send / this.files.length * 100
    },
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      sendFileToMail: 'signer/sendFileToMail',
      sendFileToMailAppendFile: 'signer/sendFileToMailAppendFile',
    }),
    save () {
      this.save_loading = true

      if (!this.appendFile) {
        for (const i in this.files) {
          this.sendFileToMail({
            data: {},
            document: this.files[i].id,
            recipient: this.recipient.id,
            finalizer: (data, url) => {
              this.progress_send++
              if (this.files.length === this.progress_send) {
                this.send_success = true
                this.save_loading = false
              }
            },
            catcher: (val) => {
              this.errors.fields = val
              if (String(val).search('JSON') !== -1) {
                this.errorSend = true
              }
              this.save_loading = false
            },
          })
        }
      } else {
        const sendData = new FormData()

        if (this.send_msg) {
          sendData.append('sendMsg', this.send_msg)
        }

        if ((typeof this.append_file.file === 'object' && this.append_file.file.length > 0)) {
          sendData.append('file', this.append_file.file[0], this.append_file.file[0].name)
        }

        this.sendFileToMailAppendFile({
          data: sendData,
          document: this.files.id,
          recipient: this.recipient.id,
          finalizer: (val) => {
            this.send_success = true
            this.save_loading = false
          },
          catcher: (val) => {
            this.errors.fields = val
            if (String(val).search('JSON') !== -1) {
              this.errorSend = true
            }
            this.save_loading = false
          },
        })
      }
    },
    close () {
      this.$emit('close')
    },
  },
  created () {}
}
</script>

<style scoped>

</style>
