<template>
  <v-card class="color--background-main py-4 px-6" color="background_main" elevation="0" rounded="0">
    <v-row justify="start" v-if="mainpage.title">
      <v-col cols="auto" class="color--corporate-main text-h1 mb-3">
        <span v-if="$vuetify.display.smAndUp">{{mainpage.title}}</span>
        <span v-else>{{mainpage.title}}</span>
      </v-col>
    </v-row>
    <v-row justify="start" no-gutters>
      <v-col cols="auto">
        <AmBreadcrumbs class="ma-0 pa-0">
          <template #crumb="{ crumb }">
            <router-link
              class="text-h4 breadcrumb__item color--corporate-main"
              style="text-decoration: none; color: inherit"
              :to="crumb.link"
            >
              {{ crumb.label }}
            </router-link>
            <div class="am-breadcrumbs__separator">
              <v-icon
                class="color--corporate-main"
              >chevron_right</v-icon>
            </div>
          </template>
        </AmBreadcrumbs>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "PageHeader",
  setup () {
    const store = useStore()

    // Computed properties
    const mainpage = computed(() => store.state.mainpage)

    return {
      mainpage,
    }
  },
}
</script>

<style lang="scss">
.am-breadcrumbs__item:last-child > a {
  color: var(--color-text-main);
  text-decoration-line: underline !important;
}
</style>
