import { ACCOUNTS_INSTANCE } from '@/helper/consts'
import Services from '@/modules/core/pages/Services'
import names from '@/modules/core/routers/names'
import RedirectPage from "@/modules/core/pages/Redirect.vue"
import ApproveMail from '@/modules/core/pages/ApproveMail.vue'

export default ([
  {
    path: '/email/approve/:token([0-9a-zA-Z]+)/',
    name: 'ApproveMail',
    component: ApproveMail,
    meta: {
      requiredAuth: false,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
  },
  {
    path: '/tools',
    name: names.TOOLS,
    component: Services,
    meta: {
      requiredAuth: false,
      breadcrumb: 'Tools',
    },
  },
  {
    path: '/redirect',
    name: 'RedirectPage',
    component: RedirectPage,
    meta: {
      requiredAuth: true,
      breadcrumb: 'RedirectPage',
    }
  },
])
