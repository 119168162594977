import helper from './helper'

const getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters

export default {
  getAccountsUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getAccountsUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  PROFILE: {
    DETAIL:
      (user_uid, gets = null) => getAccountsUrlWithParameters(
        'users|user|profile',
        'get',
        { user_uid },
        gets
      ),
    CHANGE_AVATAR:
      (user_uid, gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|avatar|change',
        'put',
        { user_uid },
        gets
      ),
    CHANGE_EMAIL:
      (user_uid, gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|email|change',
        'put',
        { user_uid },
        gets
      ),
    APPROVE_EMAIL:
      (gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|email|approve',
        'post',
        gets
      ),
    CHANGE_PASS:
      (user_uid, gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|password|change',
        'put',
        { user_uid },
        gets
      ),
    STUDENT:
      (gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|student',
        'get',
        null,
        gets
      ),
    WORKER:
      (gets = null) => getAccountsUrlWithParameters(
        'users|user|profile|worker',
        'get',
        null,
        gets
      ),
    ACCOUNT: {
      CHANGE_ACCOUNT:
        (gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|change|account',
          'post',
          null,
          gets
        ),
    },
    SESSION: {
      LIST:
        (gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|session-list',
          'list',
          null,
          gets
        ),
      DELETE_ALL:
        (gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|session-all',
          'all',
          {},
          gets
        ),
      DELETE:
        (key, gets = null) => getAccountsUrlWithParameters(
          'authenticate|authentication|session-detail',
          'destroy',
          { id: key },
          gets
        )
    },
    MAIL: {
      CREATE:
        (gets = null) => getAccountsUrlWithParameters(
          'users|contacts|emails-list',
          'create',
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|emails-detail',
          'update',
          { id },
          gets
        ),
      DELETE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|emails-detail',
          'destroy',
          { id },
          gets
        )
    },
    PHONE: {
      CREATE:
        (gets = null) => getAccountsUrlWithParameters(
          'users|contacts|phones-list',
          'create',
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|phones-detail',
          'update',
          { id },
          gets
        ),
      DELETE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|phones-detail',
          'destroy',
          { id },
          gets
        )
    },
    LANGUAGE: {
      CREATE:
        (gets = null) => getAccountsUrlWithParameters(
          'users|user|languages-list',
          'create',
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|user|languages-detail',
          'update',
          { id },
          gets
        ),
      DELETE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|user|languages-detail',
          'destroy',
          { id },
          gets
        )
    },
    ADDRESS: {
      DELETE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|address-detail',
          'destroy',
          { id },
          gets
        ),
      DETAIL:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|address-detail',
          'update',
          { id },
          gets
        ),
      CREATE:
        (gets = null) => getAccountsUrlWithParameters(
          'users|contacts|address-list',
          'create',
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getAccountsUrlWithParameters(
          'users|contacts|address-detail',
          'update',
          { id },
          gets
        )
    }
  }
}
