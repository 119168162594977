<template>
  <v-container :fluid="true" style="width: 100%" v-if="isActive">
    <v-card
      class="rounded-lg mb-4"
    >
      <v-toolbar
        :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
        :height="toolbarDisplayHeight()"
      >
        <span class="dark-primary">Обращение в Департамент Информационных Технологий РХТУ&nbsp;им.&nbsp;Д.И.&nbsp;Менделеева</span>
        <v-spacer/>
      </v-toolbar>
      <v-card-text class="text-left pt-4 pb-0 text-body-1">
        <v-row no-gutters>
          <v-col>Уважаемый пользователь, для заявления на проведение мероприятия Вы можете направить обращение в Департамент информационных технологий посредством заполнения формы.</v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col cols="12">Для обращения можете воспользоваться следующий подготовленной формой:</v-col>
          <v-col cols="12" class="pl-3 medium-primary">
            <a
              href="https://www.muctr.ru/upload/iblock/046/c3vyz67m3lsiffqce230y3pm0l6gvi7v.docx"
              target="_blank"
              style="text-decoration: none; color: inherit"
            >
              <v-icon>link</v-icon> Форма заявки на проведение мероприятия (Очное/ВКС/Смешанное)
            </a>
          </v-col>
          <v-col cols="12" class="pl-3 medium-primary">
            <a
              href="https://www.muctr.ru/upload/iblock/1f4/kkjqy2ltgtahxsg4fvf7nwi74h3mrxct.docx"
              target="_blank"
              style="text-decoration: none; color: inherit"
            >
              <v-icon>link</v-icon> Форма заявки на техническое обеспечение учебного процесса
            </a>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col cols="12">Прикрепите файл: <span class="text-red">*</span> </v-col>
          <v-col cols="12" class="mt-3">
            <v-file-input
              v-model="data.file"
              counter
              :label="$vuetify.display.smAndUp ? 'Выберите файл (.doc, .docx или .pdf)' : 'Выберите файл'"
              :placeholder="$vuetify.display.smAndUp ? 'Выберите файл (.doc, .docx или .pdf)' : 'Выберите файл'"
              prepend-icon="attach_file"
              variant="outlined"
              :show-size="1000"
              multiple
              accept=".pdf, .doc, .docx"
              :error="hasError('files')"
              :error-messages="getError('files')"
            >
              <template v-slot:selection="{ fileNames }">
                <template v-for="(fileName, index) in fileNames" :key="`${index}_file_chip`">
                  <v-chip
                    label
                    size="small"
                    :class="this.errors.fields.files ? 'mr-2 bg-red-darken-2' : 'mr-2 medium-primary'"
                  >
                    {{ fileName }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <v-textarea
              class="mt-2"
              v-model="data.text"
              :label="$vuetify.display.smAndUp ? 'Дополнительная информация' : 'Информация'"
              placeholder="Введите дополнительную информацию"
              variant="outlined"
              rows="3"
              :auto-grow="true"
              prepend-icon="notes"
              :error="hasError('text')"
              :error-messages="getError('text')"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            В случае, если Вам по какой-то причине не удается заполнить подготовленную форму – направьте письмо с Вашей корпоративной почты на адрес
            <a
              class="medium-primary"
              style="text-decoration: none; color: inherit"
              href="mailto:support@muctr.ru"
            >
              support@muctr.ru
            </a>.
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="my-3 justify-center">
        <v-btn
          class="bg-blue-darken-1 rounded-lg"
          min-width="260"
          @click="sendTicket()"
          :loading="loading"
        >
          Отправить обращение
        </v-btn>
      </v-card-actions>
    </v-card>

    <pop-up-alert
      v-model="success"
      top-color="success"
      close-color="grey-darken-2"
      title="Обращение отправлено"
      text='Ваше обращение было успешно отправлено. <br> В случае возникновения вопросов обратитесь на почту <a class="medium-primary" style="text-decoration: none; color: inherit" href="mailto:support@muctr.ru"> support@muctr.ru </a>.'
      :scrolled="false"
      @close="success = false"
    >
    </pop-up-alert>
    <pop-up-alert
      v-model="errorSend"
      top-color="error"
      close-color="grey-darken-2"
      title="Обращение не было отправлено"
      text='Попробуйте еще раз. <br> В случае возникновения вопросов обратитесь на почту <a class="medium-primary" style="text-decoration: none; color: inherit" href="mailto:support@muctr.ru"> support@muctr.ru </a>.'
      :scrolled="false"
      @close="errorSend = false"
    >
    </pop-up-alert>
  </v-container>
  <v-container style="" v-else>
    <DisableServices ></DisableServices>
  </v-container>
</template>

<script>

import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import { mapActions } from "vuex"
import PopUpAlert from "@/modules/templates/PopUpAlert"
import store from '@/store'
import { FEEDBACK_INSTANCE } from '@/helper/consts'
import DisableServices from '@/modules/templates/DisableServices.vue'

export default {
  name: "AppealPage",
  mixins: [FormErrorsMixin, TitledPageMixin],
  components: {
    PopUpAlert,
    DisableServices
  },
  data () {
    return {
      page_title: "Форма обратной связи ДИТ",
      loading: false,
      success: false,
      errorSend: false,
      data: {
        file: [],
        text: '',
      },
    }
  },
  computed: {
    isActive () {
      return store.getters['urls/checkAlreadyLoaded'](FEEDBACK_INSTANCE)
    },
  },
  watch: {
    data: {
      handler: function () {
        this.errors = {
          fields: {},
          non: []
        }
      },
      deep: true
    },

  },
  methods: {
    ...mapActions({
      createTicket: 'feedback/createTicket',
    }),
    toolbarDisplayHeight () {
      if (this.$vuetify.display.xs) {
        return 88
      } else if (this.$vuetify.display.smAndDown) {
        return 76
      } else {
        return 48
      }
    },
    sendTicket () {
      this.loading = true
      this.success = false

      const sendData = new FormData()
      sendData.append('text', this.data.text)
      if (typeof this.data.file === 'object' && this.data.file.length > 0) {
        sendData.append('files', this.data.file[0], this.data.file[0].name)
      }

      this.createTicket({
        data: sendData,
        finalizer: (val) => {
          this.loading = false
          this.success = true
          this.data = {
            file: [],
            text: ''
          }
        },
        catcher: (val) => {
          this.loading = false
          this.errors.fields = val
          if (String(val).search('JSON') !== -1) {
            this.errorSend = true
          }
        },
      })
    },
  },
  created () {
  }
}
</script>

<style scoped>

</style>
