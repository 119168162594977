import education from '@/urls/education'
import accounts from "@/urls/accounts"
import {
  postFileRequest, sendDeleteRequest,
  sendGetRequest, patchFileRequest
} from '@/helper/requests'
import { executeRequest } from '@/store/utils'

const state = {
  education: {
    advancedTrainingList: null,
    advancedTrainingDetail: null,
    loadingAdvancedTraining: false,
    currentUrl: null,
    educationDetail: null,
    loadingEducationDetail: false,
    error: null
  }
}

const getters = {
  getEducation: state => state.education.educationDetail,
  isLoadingEducationDetail: state => state.education.loadingWorkerDetail,
  saveEducationError: state => state.education.error,
  getAdvancedTrainingList: state => state.education.advancedTrainingList,
  isLoadingAdvancedTraining: state => state.education.loadingAdvancedTraining
}

const actions = {
  loadEducation: ({ commit }, { url, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_EDUCATION_DETAIL_LOADING')
    if (!url) url = accounts.PROFILE.STUDENT()
    sendGetRequest(
      url, (data, url) => {
        data = data.sort(function (a, b) {
          if (a.active < b.active) {
            return 1
          }
          if (a.active > b.active) {
            return -1
          }
          return 0
        })
        commit('SET_EDUCATION_DETAIL', data)
        commit('SET_EDUCATION_DETAIL_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_EDUCATION_ERROR', data)
        commit('SET_EDUCATION_DETAIL_LOADING_END')
        catcher(data, url)
      }
    )
  },
  loadAdvancedTraining: ({ commit }, { url, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_ADVANCEDTRAINING_LOADING')
    if (!url) url = education.EDUCATION.ADVANCED_TRAINING.LIST()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ADVANCEDTRAINING_LIST', data)
        commit('SET_ADVANCEDTRAINING_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_ADVANCEDTRAINING_ERROR', data)
        commit('SET_ADVANCEDTRAINING_LOADING_END')
        catcher(data, url)
      }
    )
  },
  changeAdvancedTraining: ({ dispatch, commit, getters }, { data, id, finalizer, catcher }) => {
    const url = education.EDUCATION.ADVANCED_TRAINING.UPDATE(id)
    executeRequest('AdvancedTraining', patchFileRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  },
  deleteAdvancedTraining: ({ dispatch, commit, getters }, { data, finalizer, catcher }) => {
    const url = education.EDUCATION.ADVANCED_TRAINING.DELETE(data.id)
    executeRequest('AdvancedTraining', sendDeleteRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  },
  createAdvancedTraining: ({ dispatch, commit, getters }, { data, finalizer, catcher }) => {
    const url = education.EDUCATION.ADVANCED_TRAINING.CREATE()
    executeRequest('AdvancedTraining', postFileRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  }
}

const mutations = {
  SET_EDUCATION_DETAIL: (state, value) => {
    state.education.educationDetail = value
  },
  SET_EDUCATION_DETAIL_LOADING: (state) => {
    state.education.loadingEducationDetail = true
  },
  SET_EDUCATION_DETAIL_LOADING_END: (state) => {
    state.education.loadingEducationDetail = false
  },
  SET_ADVANCEDTRAINING_LIST: (state, value) => {
    state.education.advancedTrainingList = value
  },
  SET_ADVANCEDTRAINING_DETAIL: (state, value) => {
    state.education.advancedTrainingDetail = value
  },
  SET_ADVANCEDTRAINING_LOADING: (state) => {
    state.education.loadingAdvancedTraining = true
  },
  SET_ADVANCEDTRAINING_LOADING_END: (state) => {
    state.education.loadingAdvancedTraining = false
  },
  SAVE_ADVANCEDTRAINING_ERROR: (state, error) => {
    state.education.error = error
  },
  SAVE_EDUCATION_ERROR: (state, error) => {
    state.education.error = error
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
