<template>
  <v-list lines="one" class="pb-0">
      <span  v-for="(item, i) in instances.slice().reverse()" :key="i">
        <InstanceLeftMenuAction
          :currentInstance="current_instance"
          :item="item"
          v-if="item.router"
        />
      </span>
  </v-list>
</template>

<script>
import InstanceLeftMenuAction from "@/modules/core/components/menus/left_menu/InstanceLeftMenuAction.vue"

export default {
  name: "InstanceLeftMenu",
  components: {
    InstanceLeftMenuAction,
  },
  props: {
    instances: {
      required: true,
      type: Array,
    },
    current_instance: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
