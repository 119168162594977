import signer from '@/urls/signer'
import {
  sendGetRequest,
  postFileRequest,
  saveFileRequest, sendPostRequest
} from '@/helper/requests'
import { executeRequest } from '@/store/utils'

const state = {
  signer: {
    currentUrl: null,
    items: [],
    loading: false,
    count: 0,
    detail: [],
    // next: null,
    // prev: null,
    error: null
  }
}

const getters = {
  getSigner: state => state.signer.items,
  getSignerCount: state => state.signer.count,
  getCurrentUrl: state => state.signer.currentUrl,
  getSignerDetail: state => state.signer.detail,
  // getNext: state => state.signer.next,
  // getPrev: state => state.signer.prev,
  isLoadingSigner: state => state.signer.loading,
  saveSignerError: state => state.signer.error
}

const actions = {
  loadSignerList: ({ commit, getters }, { url, urlAppend, finalizer = () => {}, catcher = () => {} }) => {
    if (!url) {
      url = signer.SIGNER.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoadingSigner) {
      commit('SET_SIGNER_LOADING')
      sendGetRequest(
        url, (data, url) => {
          commit('SET_SIGNER', data.results)
          commit('SET_SIGNER_COUNT', data.count)
          // commit('SET_SIGNER_NEXT', data['next']);
          // commit('SET_SIGNER_PREV', data['prev']);
          commit('SET_SIGNER_CURRENT_URL', url)
          commit('SET_SIGNER_LOADING_END')
          finalizer(data, url)
        }, (data, url) => {
          commit('SET_SIGNER_LOADING_END')
          commit('SAVE_SIGNER_ERROR', data)
          catcher(data, url)
        }
      )
    }
  },
  saveFile: ({ commit, getters }, { id, url, finalizer = () => {}, catcher = () => {} }) => {
    if (!url) url = signer.SIGNER.DETAIL(id)
    saveFileRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createSignature: ({ dispatch, commit, getters }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = signer.SIGNER.CREATE()
    executeRequest('Signer', postFileRequest, { url, data, finalizer, catcher }, { dispatch, commit, getters })
  },
  sendFileToMail: ({ dispatch, commit, getters }, { data, document, recipient, finalizer = () => {}, catcher = () => {} }) => {
    const url = signer.SIGNER.SEND_MAIL(document, recipient)
    executeRequest('Signer', sendPostRequest, { data, url, finalizer, catcher }, { dispatch, commit, getters })
  },
  getApproveHash: ({ dispatch, commit, getters }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = signer.SIGNER.DOCUMENTS_CHECK()
    executeRequest('Signer', sendPostRequest, { data, url, finalizer, catcher }, { dispatch, commit, getters })
  },
  sendFileToMailAppendFile: ({ dispatch, commit, getters }, { data, document, recipient, finalizer = () => {}, catcher = () => {} }) => {
    const url = signer.SIGNER.SEND_MAIL(document, recipient)
    executeRequest('Signer', postFileRequest, { data, url, finalizer, catcher }, { dispatch, commit, getters })
  },
}

const mutations = {
  SET_SIGNER: (state, items) => {
    state.signer.items = items
  },
  SET_SIGNER_COUNT: (state, value) => {
    state.signer.count = value
  },
  // SET_NEXT: (state, value) => {
  //   state.signer.next = value
  // },
  // SET_PREV: (state, value) => {
  //   state.signer.prev = value
  // },
  SET_SIGNER_CURRENT_URL: (state, value) => {
    state.signer.currentUrl = value
  },
  SET_SIGNER_LOADING: (state) => {
    state.signer.loading = true
  },
  SET_SIGNER_LOADING_END: (state) => {
    state.signer.loading = false
  },
  SAVE_SIGNER_ERROR: (state, error) => {
    state.signer.error = error
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
