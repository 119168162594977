<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.625C0 16.1223 0.197544 16.5992 0.549175 16.9508C0.900805 17.3025 1.37772 17.5 1.875 17.5H18.125C18.6223 17.5 19.0992 17.3025 19.4508 16.9508C19.8025 16.5992 20 16.1223 20 15.625V8.125H18.75V15.625C18.75 15.7908 18.6842 15.9497 18.5669 16.0669C18.4497 16.1842 18.2908 16.25 18.125 16.25H1.875C1.70924 16.25 1.55027 16.1842 1.43306 16.0669C1.31585 15.9497 1.25 15.7908 1.25 15.625V8.125H0V15.625Z" fill="#0563C1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.625C0 5.12772 0.197544 4.65081 0.549175 4.29917C0.900805 3.94754 1.37772 3.75 1.875 3.75H18.125C18.6223 3.75 19.0992 3.94754 19.4508 4.29917C19.8025 4.65081 20 5.12772 20 5.625V8.605L10.4825 11.1425C10.1663 11.2267 9.83366 11.2267 9.5175 11.1425L0 8.605V5.625ZM1.875 5C1.70924 5 1.55027 5.06585 1.43306 5.18306C1.31585 5.30027 1.25 5.45924 1.25 5.625V7.645L9.83875 9.935C9.9444 9.96321 10.0556 9.96321 10.1612 9.935L18.75 7.645V5.625C18.75 5.45924 18.6842 5.30027 18.5669 5.18306C18.4497 5.06585 18.2908 5 18.125 5H1.875ZM6.25 3.125C6.25 2.62772 6.44754 2.15081 6.79917 1.79917C7.15081 1.44754 7.62772 1.25 8.125 1.25H11.875C12.3723 1.25 12.8492 1.44754 13.2008 1.79917C13.5525 2.15081 13.75 2.62772 13.75 3.125V3.75H12.5V3.125C12.5 2.95924 12.4342 2.80027 12.3169 2.68306C12.1997 2.56585 12.0408 2.5 11.875 2.5H8.125C7.95924 2.5 7.80027 2.56585 7.68306 2.68306C7.56585 2.80027 7.5 2.95924 7.5 3.125V3.75H6.25V3.125Z" fill="#0563C1"/>
  </svg>
</template>

<script>
export default {
  name: 'CaseIcon'
}
</script>

<style scoped>

</style>
