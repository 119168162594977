import helper from './helper'

const getEventChainUrlWithParameters = helper.getEventChainUrlWithParameters

export default {
  getEventChainUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getEventChainUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  EVENT_CHAIN: {
    SALT:
      (gets = null) => getEventChainUrlWithParameters(
        'base|events|get|salt',
        'get',
        null,
        gets
      ),
    JWT:
      (gets = null) => getEventChainUrlWithParameters(
        'base|events|get|jwt',
        'get',
        null,
        gets
      )
  }
}
