import worker from '@/urls/worker'
import {
  sendGetRequest,
} from '@/helper/requests'

const state = {

  organization: {
    organizationDetail: null,
    loadingOrganizationDetail: false,
    error: null,
    organizationTree: null,
    loadingOrganizationTree: false,
    errorTree: null
  }
}

const getters = {
  getOrganization: state => state.organization.organizationDetail,
  getOrganizationTree: state => state.organization.organizationTree,
  isLoadingOrganizationDetail: state => state.organization.loadingOrganizationDetail,
  isLoadingOrganizationTree: state => state.organization.loadingOrganizationTree,
}

const actions = {
  loadOrganization: ({ commit }, { url, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_ORGANIZATION_LOADING')
    if (!url) url = worker.WORKER.ORGANIZATION()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ORGANIZATION', [data[0]])
        commit('SET_ORGANIZATION_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_ORGANIZATION_ERROR', data)
        commit('SET_ORGANIZATION_LOADING_END')
        catcher(data, url)
      }
    )
  },
  loadOrganizationTree: ({ commit }, { url, organization_id, finalizer = () => {}, catcher = () => {} }) => {
    commit('SET_ORGANIZATIONTREE_LOADING')
    if (!url) url = worker.WORKER.ORGANIZATION_TREE(organization_id)
    sendGetRequest(
      url, (data, url) => {
        // Преобразование JSON: замена имен переменных для вывода структуры через библиотеку vue3-tree
        let stringified = JSON.stringify(data)
        stringified = stringified.replaceAll('name', 'label')
        stringified = stringified.replaceAll('children', 'nodes')
        stringified = stringified.replaceAll('pk', 'id')
        const jsonObject = JSON.parse(stringified)

        commit('SET_ORGANIZATIONTREE', jsonObject)
        commit('SET_ORGANIZATIONTREE_LOADING_END')
        finalizer(data, url)
      }, (data, url) => {
        commit('SAVE_ORGANIZATIONTREE_ERROR', data)
        commit('SET_ORGANIZATIONTREE_LOADING_END')
        catcher(data, url)
      }
    )
  },
}

const mutations = {
  SET_ORGANIZATION: (state, value) => {
    state.organization.organizationDetail = value
  },
  SET_ORGANIZATION_LOADING: (state) => {
    state.organization.loadingOrganizationDetail = true
  },
  SET_ORGANIZATION_LOADING_END: (state) => {
    state.organization.loadingOrganizationDetail = false
  },
  SAVE_ORGANIZATION_ERROR: (state, error) => {
    state.organization.error = error
  },
  SET_ORGANIZATIONTREE: (state, value) => {
    state.organization.organizationTree = value
  },
  SET_ORGANIZATIONTREE_LOADING: (state) => {
    state.organization.loadingOrganizationTree = true
  },
  SET_ORGANIZATIONTREE_LOADING_END: (state) => {
    state.organization.loadingOrganizationTree = false
  },
  SAVE_ORGANIZATIONTREE_ERROR: (state, error) => {
    state.organization.errorTree = error
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
