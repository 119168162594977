<template>
  <v-container :fluid="true" style="width: 100%">
<!--    <v-alert-->
<!--      v-if="!isAuthenticated"-->
<!--      type="info"-->
<!--      :icon="false"-->
<!--      variant="outlined"-->
<!--      rounded="xl"-->
<!--      :class="$vuetify.display.mdAndUp ? 'text-h5 px-8 pt-4' : 'text-body px-4 pt-2'"-->
<!--      style="line-height: 2"-->
<!--      min-height="200"-->
<!--    >-->
<!--      <v-icon :size="$vuetify.display.mdAndUp ? 100 : 60" class="mb-3">info</v-icon>-->
<!--      <p>Для продолжения работы в системе - авторизируйтесь</p>-->
<!--    </v-alert>-->
    <v-card class="rounded-lg" v-if="user">
      <v-toolbar
        class="text-h6 bg-gray-light text-left px-4"
        :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
      >
<!--        <span class="dark-primary pr-3">Мой профиль</span>-->
        <v-spacer/>
        <v-toolbar-items v-if="$vuetify.display.mdAndUp">
          <v-btn @click="opened_form_avatar = true"  class="dark-primary">
            <v-icon>aspect_ratio</v-icon>
            <span class="ml-3">Сменить аватар</span>
          </v-btn>
          <v-btn @click="opened_form_pass = true" class="dark-primary">
            <v-icon>lock</v-icon>&nbsp;
            Сменить пароль
          </v-btn>
          <v-btn @click="opened_form_email = true" class="dark-primary">
            <v-icon>mail_outline</v-icon>&nbsp;
            Сменить почту
          </v-btn>
        </v-toolbar-items>
        <v-tooltip location="bottom" text="Режим редактирования">
          <template v-slot:activator="{ props }">
            <v-switch v-bind="props"
                      hide-details
                      inset
                      color="green-darken-1"
                      v-model="edit" class="medium-primary my-0">
              <v-icon :icon="(!!edit)?'edit_off':'edit'"></v-icon>
            </v-switch>
          </template>
        </v-tooltip>
        <v-menu v-if="$vuetify.display.smAndDown">
          <template v-slot:activator="{ props }">
            <v-btn icon="more_vert" v-bind="props" class="ml-1 mr-n3 dark-primary"></v-btn>
          </template>
          <v-list class="rounded-lg">
            <v-list-item>
              <v-btn elevation="0" block @click="opened_form_avatar = true" color="bg-gray-light">
                <v-icon>aspect_ratio</v-icon>
                <span class="ml-3">Сменить аватар</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn elevation="0" block @click="opened_form_pass = true" color="bg-gray-light">
                <v-icon>lock</v-icon>&nbsp;
                Сменить пароль
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn elevation="0" block @click="opened_form_email = true" color="bg-gray-light">
                <v-icon>mail_outline</v-icon>&nbsp;
                Сменить почту
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-card-text class="subheading text-left">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" md="3" class="pr-md-4">
            <v-img :src="NotAvatar"
                   :max-height="$vuetify.display.smAndUp ? 350 : 190"
                   :aspect-ratio="1/1"
                   class="ma-5" v-if="avatar === null"
            ></v-img>
            <v-img v-if="avatar !== null"
                   :src="avatar"
                   :max-height="$vuetify.display.smAndUp ? 350 : 190"
                   :lazy-src="NotAvatar"
                   :aspect-ratio="1/1"
                   class="ma-5"
            ></v-img>
          </v-col>
          <v-col cols="12" md="9">
            <v-row :class="(col%2) ? 'pa-3 bg-grey-lighten-3 rounded-lg' : 'pa-3 rounded-lg'"
                   v-for="col in Object.keys(fields)" :key="`row${i}_${col}`" no-gutters>
              <v-col class="text-left">
                <b class="font-weight-black">{{ fields[col].text }}:</b>
              </v-col>
              <v-col class="text-right text-sm-left">
              <span
                v-if="fields[col].type">
                {{
                  displayDate(user[fields[col].field], fields[col].type === 'datetime')
                }}
              </span>
              <span v-else>
                <span v-if="user[fields[col].field] !== 'null'">{{ user[fields[col].field] }}</span>
              </span>
              </v-col>
            </v-row>
            <ImageCropper
              v-if="opened_form_avatar"
              :picture="avatar"
              v-model="opened_form_avatar"
              @save="saveImg"
              @close="opened_form_avatar=false"
            ></ImageCropper>
            <ChangeMail
              v-if="opened_form_email"
              v-model="opened_form_email"
              :old="user.email"
              @save="opened_form_email=false"
              @close="opened_form_email=false"
            ></ChangeMail>
            <ChangePass
              v-if="opened_form_pass"
              v-model="opened_form_pass"
              :username="user.username"
              :has-additional="user.has_additional"
              @save="opened_form_pass=false"
              @close="opened_form_pass=false"
            ></ChangePass>
          </v-col>
        </v-row>
      </v-card-text>
      <InformationUser
        @update_profile="loadData"
        :edit="edit"
      >
      </InformationUser>
    </v-card>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import NotAvatar from '@/assets/NotAvatar.png'
import { checkIsDevelopmentServer, displayDate } from '@/helper'
import ImageCropper from '@/modules/profile/account/modals/ImageCropper'
import ChangePass from '@/modules/profile/account/modals/ChangePass'
import ChangeMail from '@/modules/profile/account/modals/ChangeMail'
import InformationUser from '@/modules/profile/account/components/InformationUser'
import TitledPageMixin from '@/mixins/TitledPageMixin'
import { getLeftMenu } from '@/helper/instances'
import { th } from 'vuetify/locale'

export default {
  name: 'MainPage',
  components: {
    ImageCropper,
    ChangePass,
    ChangeMail,
    InformationUser
  },
  mixins: [TitledPageMixin],
  data () {
    return {
      user: null,
      avatar: null,
      edit: false,
      NotAvatar,
      opened_form_avatar: false,
      opened_form_pass: false,
      opened_form_email: false,
      page_title: 'Мой профиль',
      fields: [
        {
          text: 'Имя пользователя',
          field: 'username'
        },
        {
          text: 'Зарегистрирован',
          field: 'joined',
          type: 'datetime'
        },
        {
          text: 'Вы были последний раз',
          field: 'last_login',
          type: 'datetime'
        },
        {
          text: 'Фамилия',
          field: 'lastname'
        },
        {
          text: 'Имя',
          field: 'firstname'
        },
        {
          text: 'Отчество',
          field: 'midname'
        },
        {
          text: 'Пол',
          field: 'sex'
        },
        {
          text: 'Дата рождения',
          field: 'birth',
          type: 'date'
        },
      ]
    }
  },
  methods: {
    displayDate,
    ...mapActions({
      loadAccountDetail: 'user/loadAccountDetail',
      checkUserSurvey: 'user/checkUserSurvey',
      checkUserConfirmation: 'user/checkUserConfirmation',
      addSpecialNotification: 'notifications/addSpecialNotification',
      deleteSpecialNotification: 'notifications/deleteSpecialNotification',
      loadEducation: 'education/loadEducation'
    }),
    saveImg () {
      this.opened_form_avatar = false
      this.loadData()
    },
    loadData (url = undefined) {
      this.loadAccountDetail({
        finalizer: (data, url) => {
          this.user = data
          this.avatar = this.user.avatar
          let midname = this.user.midname
          if (String(midname) === 'null') { midname = '' }
          this.page_title = this.user.lastname + ' ' + this.user.firstname + ' ' + midname
          let is_student = false
          if (this.user.is_student || this.user.is_graduate) {
            is_student = true
          }
          this.$store.commit('maintoolbar/changeAvatar', this.avatar)
          this.$store.commit('leftmenu/replaceItems', getLeftMenu(this.user.is_employee, is_student))
          // this.checkNotification(is_student)
        }
      })
    },
    // скрыто до лучших времён
    checkNotification (is_student) {
      // для прода
      let id_sur1 = 258 // Получение кампусной карты «Газпромбанка»
      let sur1_param = 'acec06082de78f001609b501695c78a3c2351f0ed6a44c5694963b24eb6cf6294f7aff631a430644fa2e3fbbf3cc9be8'
      let id_sur2 = 259 // Форма заполнения сведений — удостоверение гражданина подлежащего призыву на военную службу
      let sur2_param = '4dbb86b8ee57c83b942abd6e072fe23f532a38551b5f03d0607a25ed97b98eccc41c9cc31c8b71c99a25540823700582'
      let id_sur3 = 260 // Форма заполнения (Военный билет)
      let sur3_param = 'a01723f305e5d1613d1d4ca7a5074157d96c1fca83c74b1b1e7c736ae6bfeadace7c71d4b9848343510609fa633ab01b'
      // для теста
      if (checkIsDevelopmentServer()) {
        id_sur1 = 213 // Получение кампусной карты «Газпромбанка»
        sur1_param = '0d1d04569c78b1284965fa8e0035a509b1476361fc7e9e23f05fe4976865a507476a3b876fcd75006f0eb26307f2fc20'
        id_sur2 = 214 // Форма заполнения сведений — удостоверение гражданина подлежащего призыву на военную службу
        sur2_param = '5bfb1f44a5fe14d02334b33c3cde2ba3ffc9484b41bf5b900536abfa68dd7b9d9eef8800da3e954c2664e558de28baf4'
        id_sur3 = 215 // Форма заполнения (Военный билет)
        sur3_param = '586b8c6a3e00bfc7276c5acdc3c2343b6e4f34cfa4205a21b1b7bd4209cf11e6005b67293aa16bb7f9006054bb6e368c'
      }
      if (is_student) {
        this.loadEducation({
          undefined,
          finalizer: (data, url) => {
            if (data.length === 0) {
              this.checkUserSurvey({
                id: id_sur1,
                special_link_key: sur1_param,
                finalizer: (data, url) => {
                  if (!data.attempt_ended) {
                    this.addSpecialNotification({
                      val: {
                        id: self.crypto.randomUUID(),
                        text: "<p  style='font-size: 20px;'>Уважаемые первокурсники! </p>" +
                          "<p style='text-indent: 15px'>Чтобы получить кампусную карту, которая одновременно является постоянным пропуском и банковской картой для получения выплат от Университета, необходимо до 1 сентября 2024 года заполнить данные и прикрепить все необходимые документы в опросе <a style='text-decoration: none; color: #1D71B8' href='https://survey.muctr.ru/survey/258/link?key=acec06082de78f001609b501695c78a3c2351f0ed6a44c5694963b24eb6cf6294f7aff631a430644fa2e3fbbf3cc9be8'>«Получение кампусной карты «Газпромбанка»</a>. " +
                        "<p style='text-indent: 15px'>График выдачи кампусных карт будет опубликован в <a style='text-decoration: none; color: #1D71B8' href='https://vk.com/omp_rhtu'>официальной группе Управления по воспитательной работе и молодежной политики.</a> По вопросам подачи заявления обращаться по <a style='text-decoration: none; color: #1D71B8' href='mailto:ipr_opk@muctr.ru' >электронной почте</a>.</p>",
                        url: '',
                        close_btn: false
                      },
                    })
                  }
                }
              })
              if (this.user.sex === "Мужской") {
                const obj = {
                  id: self.crypto.randomUUID(),
                  text: "<p  style='font-size: 20px;'>Уважаемые молодые люди призывного возраста!</p> " +
                    "<p  style='text-indent: 15px'>Просим вас пройти анкетирование и заполнить все обязательные поля в опросе <a style='text-decoration: none; color: #1D71B8'' href='https://survey.muctr.ru/survey/259/link?key=4dbb86b8ee57c83b942abd6e072fe23f532a38551b5f03d0607a25ed97b98eccc41c9cc31c8b71c99a25540823700582'>«Форма заполнения сведений — удостоверение гражданина подлежащего призыву на военную службу»</a>." +
                    " Это необходимо для отправки сведений о военно-учетной специальности во Второй отдел для дальнейшей передачи в военный комиссариат.</p>" +
                    "<p  style='text-indent: 15px'>Если у Вас уже есть военный билет, просим пройти анкетирование и заполнить все обязательные поля в опросе <a style='text-decoration: none; color: #1D71B8' href='https://survey.muctr.ru/survey/260/link?key=a01723f305e5d1613d1d4ca7a5074157d96c1fca83c74b1b1e7c736ae6bfeadace7c71d4b9848343510609fa633ab01b'>«Форма заполнения (Военный билет)»</a>. </p> " +
                    "<p  style='text-indent: 15px'>Контакты для связи по вопросам воинского учета доступны <a style='text-decoration: none; color: #1D71B8' href='https://www.muctr.ru/university/departments/vus/contacts/'>по ссылке</a>. </p> ",
                  url: '',
                  close_btn: false
                }
                let i = 1
                let all_ended = false
                for (const id_sur of [id_sur2, id_sur3]) {
                  this.checkUserSurvey({
                    id: id_sur,
                    special_link_key: i === 1 ? sur2_param : sur3_param,
                    finalizer: (data, url) => {
                      if (!data.attempt_ended) {
                        if (!all_ended) {
                          this.addSpecialNotification({
                            val: obj
                          })
                          all_ended = true
                        }
                      } else {
                        all_ended = true
                        this.deleteSpecialNotification({
                          val: obj
                        })
                      }
                    }
                  })
                  i++
                }
              }
            }
          }
        })
      }
    },
    checkConfirmation () {
      this.checkUserConfirmation({
        finalizer: (data, url) => {
          if (data.need_acquaintance) {
            if (this.$route.query.redirect !== undefined) {
              document.location.href = process.env.VUE_APP_CONF_LINK + '?redirect=' + this.$route.query.redirect
            } else {
              document.location.href = process.env.VUE_APP_CONF_LINK
            }
          } else if (this.$route.query.redirect !== undefined) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.loadData()
          }
        },
        catcher: (val) => {
          if (this.$route.query.redirect !== undefined) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.loadData()
          }
        }
      })
    }
  },
  created () {
    if (!this.getProfile) {
      this.checkConfirmation()
    } else {
      this.user = this.getProfile
      this.avatar = this.user.avatar
      let midname = this.user.midname
      if (String(midname) === 'null') { midname = '' }
      this.page_title = this.user.lastname + ' ' + this.user.firstname + ' ' + midname
      this.$store.commit('maintoolbar/changeAvatar', this.avatar)
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticate',
      getProfile: 'user/getProfile'
    })
  },
}
</script>

<style scoped>

</style>
