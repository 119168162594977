<template>
  <scroll-center-dialog v-model="open" @close="close" @apply="checkEmail" :title="'Изменить почту'" :scrolled="false">
    <v-card class="px-2 pt-2" flat>
      <v-form v-model="valid">
        <v-card-text class="title px-0">Ваша основная почта для уведомлений</v-card-text>
        <h2>{{ old }}</h2>
        <v-card-text class="title px-0">Выберите новую почту для уведомлений</v-card-text>
        <v-text-field
          label="E-mail"
          :error="hasError('email')"
          :error-messages="String(getError('email')).replace('user','Пользователь')"
          v-model="email"
          variant="outlined"
          :rules="[rules.required, rules.email, rules.noMuctr, rules.noNirhtu]"
        ></v-text-field>
      </v-form>
    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions } from 'vuex'

export default {
  name: 'ChangeMail',
  props: {
    value: Boolean,
    edit: Boolean,
    old: String,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      user: {},
      URL_UPDATE: '',
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не верный почтовый ящик'
        },
        noMuctr: value => {
          const pattern = /^(?!.*@muctr.ru).*$/
          return pattern.test(value) || 'Почта в домене @muctr.ru не допустима'
        },
        noNirhtu: value => {
          const pattern = /^(?!.*@nirhtu\.ru).*$/
          return pattern.test(value) || 'Почта в домене @nirhtu.ru не допустима.'
        },

      },
      email: '',
      valid: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  methods: {
    ...mapActions({
      changeEmail: 'user/changeEmail'
    }),
    save () {
      this.$emit('close')
    },
    checkEmail: function () {
      if (this.valid) {
        const data = { email: this.email }
        this.changeEmail({
          data,
          finalizer: (data, url) => {
            this.loading = false
            this.$emit('close')
          },
          catcher: (data, url) => {
            this.loading = false
            this.errors.fields = data
          }
        })
      }
    },
  },
  close () {
    this.$emit('close')
  },
  created () {

  }
}
</script>

<style scoped>

</style>
