// eslint-disable-next-line
function capitalize (value) {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export function executeRequest (prefix, request, { url, data, finalizer, catcher }, { getters, dispatch, commit }) {
  const LOADING_END = `SET_${prefix.toUpperCase()}_LOADING_END`
  const SAVE_ERROR = `SAVE_${prefix.toUpperCase()}_ERROR`
  commit(`SET_${prefix.toUpperCase()}_LOADING`)
  const ifinalizer = (data) => {
    commit(LOADING_END)
    finalizer(data)
  }
  const icatcher = (data) => {
    commit(LOADING_END)
    commit(SAVE_ERROR, data)
    catcher(data)
  }
  if (data) request(url, data, ifinalizer, icatcher)
  else request(url, ifinalizer, icatcher)
}
