<template>
  <v-row no-gutters justify="center" v-show="isLoadingWorkerDetail">
    <semipolar-spinner
      :animation-duration="2000"
      :size="$vuetify.display.mdAndUp ? 200 : 100"
      color="#1976D2"
      class="mt-16"
    />
  </v-row>
  <span v-show="!isLoadingWorkerDetail">
      <WorkerDetail/>
      <AdvancedTraining/>
  </span>
</template>

<script>
import WorkerDetail from "@/modules/profile/worker/components/WorkerDetail"
import AdvancedTraining from "@/modules/profile/worker/components/AdvancedTraining.vue"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import { SemipolarSpinner } from "epic-spinners"
import { mapGetters } from "vuex"

export default {
  name: "DetailPage",
  components: {
    WorkerDetail,
    AdvancedTraining,
    SemipolarSpinner,
  },
  mixins: [TitledPageMixin],
  data () {
    return {
      page_title: "Работник",
    }
  },
  computed: {
    ...mapGetters({
      isLoadingWorkerDetail: 'worker/isLoadingWorkerDetail',
      isLoadingAdvancedTraining: "education/isLoadingAdvancedTraining"
    })
  }
}
</script>

<style scoped>

</style>
