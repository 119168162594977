<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7522 2.25H2.2522C1.95383 2.25 1.66768 2.36853 1.4567 2.5795C1.24572 2.79048 1.1272 3.07663 1.1272 3.375V13.5L4.10395 10.8517C4.1959 10.7601 4.3167 10.7031 4.44588 10.6904C4.57505 10.6777 4.70465 10.7101 4.8127 10.782L7.8052 12.7766L11.9789 8.60287C12.0624 8.51937 12.1699 8.46423 12.2864 8.44525C12.4029 8.42627 12.5225 8.44442 12.6281 8.49713L16.8772 10.6875V3.375C16.8772 3.07663 16.7587 2.79048 16.5477 2.5795C16.3367 2.36853 16.0506 2.25 15.7522 2.25ZM2.2522 1.125C1.65546 1.125 1.08316 1.36205 0.661207 1.78401C0.23925 2.20597 0.00219727 2.77826 0.00219727 3.375V14.625C0.00219727 15.2217 0.23925 15.794 0.661207 16.216C1.08316 16.6379 1.65546 16.875 2.2522 16.875H15.7522C16.0477 16.875 16.3403 16.8168 16.6132 16.7037C16.8862 16.5907 17.1343 16.4249 17.3432 16.216C17.5521 16.0071 17.7179 15.759 17.8309 15.486C17.944 15.2131 18.0022 14.9205 18.0022 14.625V3.375C18.0022 2.77826 17.7651 2.20597 17.3432 1.78401C16.9212 1.36205 16.3489 1.125 15.7522 1.125H2.2522ZM6.7522 6.1875C6.7522 6.40911 6.70855 6.62854 6.62374 6.83328C6.53894 7.03802 6.41464 7.22404 6.25794 7.38074C6.10124 7.53744 5.91521 7.66174 5.71048 7.74655C5.50574 7.83135 5.2863 7.875 5.0647 7.875C4.84309 7.875 4.62366 7.83135 4.41892 7.74655C4.21418 7.66174 4.02815 7.53744 3.87145 7.38074C3.71476 7.22404 3.59046 7.03802 3.50565 6.83328C3.42085 6.62854 3.3772 6.40911 3.3772 6.1875C3.3772 5.73995 3.55499 5.31072 3.87145 4.99426C4.18792 4.67779 4.61714 4.5 5.0647 4.5C5.51225 4.5 5.94147 4.67779 6.25794 4.99426C6.57441 5.31072 6.7522 5.73995 6.7522 6.1875Z" fill="#0563C1"/>
  </svg>
</template>

<script>
export default {
  name: 'ImageIcon'
}
</script>

<style scoped>

</style>
