<template>
  <v-container :fluid="true" class="bg-light-beige" style="height: calc(var(--doc-height) - 78px); overflow-y: hidden !important;">
    <v-row align="center"
           justify="center"
           no-gutters
           style="height: 100%"
    >
      <div class="text-lg-left flex-wrap d-inline-flex" :style="$vuetify.display.mdAndUp ? 'width: 70vw' : 'width: 100vw'">
        <v-col cols="12" lg="7" xl="6" class="pr-4" align-self="center">
          <span class="text-left">
            <h1 class="text-sm-h1 py-1 py-md-2 font-weight-bold" style="color: var(--gray-shades-main)"><span class="text-sm-h2 font-weight-bold">ошибка</span> 404</h1>
            <h2 class="text-sm-h4 py-2 py-md-1 medium-primary" :class="$vuetify.display.smAndDown ? 'text-h4' : ''">
              <span class="typewriter" style="color: var(--corporate-main)"></span>
            </h2>
            <p class="text-sm-h6 text-grey-darken-2" :class="$vuetify.display.smAndDown ? 'text-body-1' : ''">К сожалению, запрашиваемая Вами страница не найдена.</p>
            <p class="text-sm-h6 text-grey-darken-2" :class="$vuetify.display.smAndDown ? 'text-body-1' : ''">Если вы уверены, что запрашиваемая страница должна существовать, пожалуйста <a href="mailto:help@mendeleevskayakarta.ru" class="text-decoration-none" style="color: #376FFE">сообщите нам.</a></p>
            <p class="text-sm-h6 text-grey-darken-2" :class="$vuetify.display.smAndDown ? 'text-body-1' : ''">Приносим извинения за доставленные неудобства.</p>
          </span>
          <v-btn
            :to="{name: names.BASE}"
            color="#356A9E"
            rounded="lg"
            height="50"
            min-width="170"
            class="text-white font-weight-bold mt-6"
          >
            На главную
          </v-btn>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="pl-4 mt-10 mt-md-0" align-self="center">
          <v-img
            :src="rhtuImg"
            :max-height="$vuetify.display.lgAndUp ? 400 : 200"
          >
          </v-img>
        </v-col>
      </div>
    </v-row>
  </v-container>

</template>

<script>
import names from '@/modules/profile/routers/names'
import rhtuImg from '@/assets/rhtu.svg'

export default {
  name: 'PageNotFound',
  computed: {
    names () {
      return names
    }
  },
  data () {
    return {
      rhtuImg: rhtuImg,
    }
  },
  methods: {
    documentHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
  },
  mounted () {
    window.addEventListener("resize", this.documentHeight)
    this.documentHeight()
  },
  unmounted () {
    window.removeEventListener("resize", this.documentHeight)
  },
}
</script>

<style scoped>
/* Typewriter effect */
@keyframes typing {
  0%, 54% { content: ""; }
  1%, 53% { content: "У"; }
  1.5%, 52% { content: "Уп"; }
  2%, 51% { content: "Упс"; }
  3%, 50% { content: "Упс,"; }
  4%, 49% { content: "Упс, "; }
  5%, 48% { content: "Упс, ч"; }
  7%, 47% { content: "Упс, чт"; }
  8%, 46% { content: "Упс, что"; }
  9%, 45% { content: "Упс, что-"; }
  10%, 44% { content: "Упс, что-т"; }
  11%, 43% { content: "Упс, что-то"; }
  12%, 42% { content: "Упс, что-то "; }
  13%, 41% { content: "Упс, что-то п"; }
  14%, 40% { content: "Упс, что-то по"; }
  15%, 39% { content: "Упс, что-то пош"; }
  16%, 38% { content: "Упс, что-то пошл"; }
  17%, 37% { content: "Упс, что-то пошло"; }
  18%, 36% { content: "Упс, что-то пошло "; }
  19%, 35% { content: "Упс, что-то пошло н"; }
  20%, 34% { content: "Упс, что-то пошло не"; }
  21%, 33% { content: "Упс, что-то пошло не "; }
  22%, 32% { content: "Упс, что-то пошло не т"; }
  23%, 31% { content: "Упс, что-то пошло не та"; }
  24%, 30% { content: "Упс, что-то пошло не так"; }

  59%, 97% { content: ""; }
  60%, 96% { content: "С"; }
  61%, 95% { content: "Ст"; }
  62%, 94% { content: "Стр"; }
  63%, 93% { content: "Стра"; }
  64%, 92% { content: "Стран"; }
  65%, 91% { content: "Страни"; }
  66%, 90% { content: "Страниц"; }
  67%, 89% { content: "Страницa"; }
  68%, 88% { content: "Страница "; }
  69%, 87% { content: "Страница н"; }
  70%, 86% { content: "Страница не "; }
  71%, 85% { content: "Страница не н"; }
  72%, 84% { content: "Страница не на"; }
  73%, 83% { content: "Страница не най"; }
  74%, 82% { content: "Страница не найд"; }
  75%, 81% { content: "Страница не найде"; }
  76%, 80% { content: "Страница не найден"; }
  77%, 79% { content: "Страница не найдена"; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 13.5s infinite;
}

.typewriter::after {
  content: "";
  border-right: 1px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}

@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }

  @keyframes sequencePopup {
    0%, 100% { content: "Упс, что-то пошло не так"; }
    55% { content: "Упс, что-то пошло не так"; }
    98% { content: "Страница не найдена"; }
  }

  .typewriter::before {
    content: "developer";
    animation: sequencePopup 12s linear infinite;
  }
}

:root {
  --doc-height: 100vh;
}
</style>
