import {
  sendGetRequest
} from '@/helper/requests'

import choices from '@/urls/choices'

const state = {
  choices: {
    positionHeldType: undefined,
    advancedTrainingDocumentType: [],
    advancedTrainingWorkType: [],
    advancedTrainingKnowledgeType: [],
    contactType: undefined,
    languageType: undefined,
  }
}

const getters = {
  positionHeldType: state => state.choices.positionHeldType,
  advancedTrainingDocumentType: state => state.choices.advancedTrainingDocumentType,
  advancedTrainingWorkType: state => state.choices.advancedTrainingWorkType,
  advancedTrainingKnowledgeType: state => state.choices.advancedTrainingKnowledgeType,
  contactType: state => state.choices.contactType,
  languageType: state => state.choices.languageType,
}

const actions = {
  loadPositionHeldType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.POSITION_HELD_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_POSITION_HELD_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAdvancedTrainingDocumentType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.ADVANCED_TRAINING_DOCUMENT_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ADVANCED_TRAINING_DOCUMENT_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadAdvancedTrainingWorkType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.ADVANCED_TRAINING_WORK_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ADVANCED_TRAINING_WORK_TYPE', data)
      }, catcher
    )
  },
  loadAdvancedTrainingKnowledgeType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.ADVANCED_TRAINING_KNOWLEDGE_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ADVANCED_TRAINING_KNOWLEDGE_TYPE', data)
      }, catcher
    )
  },
  loadContactType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.CONTACT_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_CONTACT_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadLanguageType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = choices.CHOICES.LANGUAGE_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_LANGUAGE_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
}

const mutations = {
  SET_POSITION_HELD_TYPE: (state, value) => {
    state.choices.positionHeldType = value
  },
  SET_ADVANCED_TRAINING_DOCUMENT_TYPE: (state, value) => {
    state.choices.advancedTrainingDocumentType = value
  },
  SET_ADVANCED_TRAINING_WORK_TYPE: (state, value) => {
    state.choices.advancedTrainingWorkType = value
  },
  SET_ADVANCED_TRAINING_KNOWLEDGE_TYPE: (state, value) => {
    state.choices.advancedTrainingKnowledgeType = value
  },
  SET_CONTACT_TYPE: (state, value) => {
    state.choices.contactType = value
  },
  SET_LANGUAGE_TYPE: (state, value) => {
    state.choices.languageType = value
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
