import eventchain from '@/urls/eventchain'
import {
  sendGetRequest
} from '@/helper/requests'
import { SOCKET_BASE_HOST } from '@/helper/consts'
import { MESSAGES_TYPES } from '@/helper/socket'

class EventHandlers {
  dispatch
  commit
  getters
  constructor ({ dispatch, commit, getters }) {
    this.dispatch = (...args) => dispatch(...args)
    this.commit = (...args) => commit(...args)
    this.getters = getters
  }

  [MESSAGES_TYPES.AUTH] ({ message, data }) {
  }

  [MESSAGES_TYPES.NEW_EVENT] ({ message, data }) {
    this.dispatch('addMessages', { val: data.data })
  }

  [MESSAGES_TYPES.OLD_MESSAGES] ({ message, data }) {
    this.dispatch('addOldMessages', { data: data.data })
  }

  [MESSAGES_TYPES.RECEIVE_MESSAGE] ({ message, data }) {
    console.log(data)
  }

  [MESSAGES_TYPES.REACTION_MESSAGE] ({ message, data }) {
    console.log(data)
  }

  [MESSAGES_TYPES.ACCEPT] ({ message, data }) {
    setInterval(() => {
      this.dispatch('heartbeatMessage', {})
    }, 60000);
  }

  [MESSAGES_TYPES.ERROR] ({ message, data }) {
    console.log(data)
  }
}

const state = {
  eventchain: {
    messages: [],
  }
}
const getters = {
  getMessages: (state) => state.eventchain.messages,
}

const actions = {
  connectJWT: ({ dispatch, commit, rootGetters }, { finalizer = () => {}, catcher = () => {} }) => {
    const url = eventchain.EVENT_CHAIN.JWT()
    sendGetRequest(
      url, (data, url) => {
        dispatch('socket/setToken', { token: data.token }, { root: true })
        // commit('socket/REPLACE_HANDLERS', new EventHandlers({ commit, dispatch, getters: rootGetters }), { root: true })
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  initEvent ({ commit, dispatch, getters, rootGetters }, url = null) {
    if (!rootGetters["socket/isInit"]) {
      dispatch('socket/init', {
        url: SOCKET_BASE_HOST + '/api/events/',
        handlers: new EventHandlers({ commit, dispatch, getters: rootGetters })
      }, { root: true })
    }
  },
  addMessages: ({ commit }, { val }) => {
    const searchNotification = state.eventchain.messages.find((item) => item.hash === val.hash)
    if (!searchNotification) { commit('ADD_MESSAGES', val) }
  },
  addOldMessages: ({ commit }, { data }) => {
    data.forEach((item) => {
      commit('ADD_MESSAGES', item)
    })
  },
  deleteMessages: ({ commit, dispatch }, { val }) => {
    const filteredNotification = state.eventchain.messages.filter((item) => item.hash !== val.hash)
    commit('SET_MESSAGES', filteredNotification)

    dispatch('socket/sendMessages',
      {
        message: {
          type: MESSAGES_TYPES.RECEIVE_MESSAGE,
          event_id: val.id,
        }
      }, { root: true }
    )
  },
  heartbeatMessage: ({ commit, dispatch }, { val }) => {
    dispatch('socket/sendMessages',
      {
        message: {
          type: MESSAGES_TYPES.HEARTBEAT,
        },
      }, { root: true }
    )
  },
  deleteAllMessages: ({ commit, dispatch }, { val }) => {
    commit('SET_MESSAGES', [])
    dispatch('socket/sendMessages',
      {
        message: {
          type: MESSAGES_TYPES.RECEIVE_ALL_MESSAGE,
        },
      }, { root: true }
    )
  },
}

const mutations = {
  SET_MESSAGES: (state, list) => {
    state.eventchain.messages = list
  },
  ADD_MESSAGES: (state, val) => {
    state.eventchain.messages.unshift(val)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
