<template>
  <v-app-bar :elevation="0" color="corporate_main" height="78" class="top_menu">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click.stop="leftMenuToggle" v-if="leftmenu.enabled" class="ml-sm-4">
        <v-icon color="white" size="37">menu</v-icon>
      </v-app-bar-nav-icon>
    </template>

    <v-app-bar-title align="left" class="hidden-sm-and-down" style="max-width: 380px; min-width: 350px">
      <router-link class="text-decoration-none text-white" to="/">
        <v-row no-gutters class="flex-nowrap">
          <v-avatar size="53">
            <v-img :src="logo" alt="logo" aspect-ratio="1/1" :cover="false"></v-img>
          </v-avatar>
          <v-col align-self="center" align="left">
            <span v-if="maintoolbar.title">
              {{ maintoolbar.title }}
            </span>
            <span v-else>
              <p class="text-h4 text-white">РОССИЙСКИЙ ХИМИКО</p>
              <p class="text-h4 text-white">ТЕХНОЛОГИЧЕСКИЙ УНИВЕРСИТЕТ</p>
              <p class="text-caption text-white">имени Д.И. Менделеева</p>
            </span>
          </v-col>
        </v-row>
      </router-link>
    </v-app-bar-title>

    <v-spacer/>
    <template v-slot:append>
      <div class="top_menu__button text-white d-flex">
        <div class="d-flex flex-nowrap" v-if="$vuetify.display.width > 1560">
          <div :key="`top_mainInstances_item_${i}`" v-for="(item, i) in maintoolbar.mainInstances">
            <v-btn @click="dispatchRouter(item)" class="text-h6 text-uppercase text-white px-sm-6" type="text" v-if="item.router">
              {{ item.title }}
            </v-btn>
            <v-btn :href="item.href" type="text" class="text-h6 text-uppercase text-white px-sm-6" target="_blank" v-else>
              {{ item.title }}
            </v-btn>
          </div>
        </div>
        <v-menu location="bottom" class="text-left">
          <template v-slot:activator="{ props }">
            <v-btn type="text" v-bind="props" class="text-h6 text-uppercase text-white px-sm-6">
              Сервисы <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list class="top_menu__button__list">
            <v-list-item
              class="top_menu__button__list-item"
              v-for="item in maintoolbar.secondInstances"
              :key='item.title'
              @click="routerPath(item)"
            >
              <v-list-item-title class="top_menu__button__list-title text-body-1">{{item.title}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <EventAlerts v-if="checkIsDevelopment"/>
        <v-menu location="bottom" class="text-center" v-if="isUserAuthenticated">
          <template  v-slot:activator="{ props }">
            <v-btn type="text" v-bind="props" class="text-h6 text-uppercase text-white px-sm-6 mr-1">
              <v-avatar size="32" class="mr-3">
                <v-icon color="white" v-if="!maintoolbar.userAvatar" size="34">account_circle</v-icon>
                <v-img :src="maintoolbar.userAvatar" aspect-ratio="1"
                       v-else></v-img>
              </v-avatar>
              <span v-if="isUserAuthenticated && $vuetify.display.width > 500">
                {{ user.username }}
              </span>
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list class="top_menu__button__list">
            <template v-if="accountAccounts && accountAccounts.has_additional">
              <v-list-item
                class="top_menu__button__list-item"
                :key="`${i}_accountAccounts`"
                v-for="(item, i) in  accountAccounts.accounts"
                @click="changeUserAccount(item.value)"
              >
                <v-list-item-title class="top_menu__button__list-title text-body-1">
                  {{ item.text }} <span v-if="item.is_origin">(основной)</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              class="top_menu__button__list-item"
              :key="i"
              v-for="(item, i) in maintoolbar.userActions"
              @click="routerPath(item)"
            >
              <v-list-item-title class="top_menu__button__list-title text-body-1">
                {{item.title}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="top_menu__button__list-item" @click="logout(false, true, false)">
              <v-list-item-title class="top_menu__button__list-title text-body-1">
                Выйти
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn @click="routerLogin" class="top_menu__button__list-title text-h6 text-uppercase text-white" type="text" v-else>
          Войти
        </v-btn>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import logo from '@/assets/logo.png'
import { goToLoginPage } from '@/helper/auth'
import names from '@/modules/profile/routers/names'
import { useRouter } from "vue-router"
import EventAlerts from '@/modules/templates/EventAlerts.vue'
import { checkIsDevelopmentServer } from "@/helper";
export default {
  name: 'TopMenu',
  components: { EventAlerts },
  setup () {
    const store = useStore()
    const router = useRouter()

    const checkIsDevelopment = checkIsDevelopmentServer();

    // Data properties
    const logoRef = ref(logo)
    const namesRef = ref(names)

    // Computed properties
    const accountAccounts = computed(() => store.getters['selectors/accountAccounts'])
    const isUserAuthenticated = computed(() => store.getters['auth/isAuthenticate'])
    const leftmenu = computed(() => store.state.leftmenu)
    const maintoolbar = computed(() => store.state.maintoolbar)
    const user = computed(() => store.state.user)

    const changeUserAccount = function (value) {
      store.dispatch('user/changeAccount', {
        data: { user: value },
        finalizer: () => {
        },
        catcher: () => {
        }
      })
    }

    // Methods
    const routerLogin = () => {
      goToLoginPage()
    }

    const routerPath = (item) => {
      if (item.router) {
        router.push({ name: item.router })
      } else if (item.url) {
        window.open(item.url, '_blank')
      } else {
        router.push({ name: item.name })
      }
    }

    const dispatchRouter = (item) => {
      if (item.router) {
        this.$router.push({ name: item.router })
      }
    }

    const leftMenuToggle = () => {
      store.commit('leftmenu/toggleVisible')
    }

    const logout = (dissable_left = true, reload_page = false, redirect = true) => {
      store.commit('urls/clearUrls')
      if (dissable_left) {
        store.commit('leftmenu/dissable')
      }
      store.commit('leftmenu/replaceItems', [])
      store.dispatch('auth/AUTH_LOGOUT')
        .then(() => {
          if (redirect) {
            document.location.href = import.meta.env.VITE_VUE_APP_LOGIN_HOST
          }
          if (reload_page) {
            window.location.reload()
          }
        })
    }

    return {
      logo: logoRef,
      names: namesRef,
      isUserAuthenticated,
      leftmenu,
      maintoolbar,
      user,
      accountAccounts,
      changeUserAccount,
      routerLogin,
      routerPath,
      dispatchRouter,
      leftMenuToggle,
      logout,
      checkIsDevelopment
    }
  },
}
</script>

<style scoped lang="scss">
.top_menu {
  background-image: url('@/assets/bg_top_menu.svg'), linear-gradient(90deg, var(--corporate-main) 0%, var(--blue-shades-dark) 100%, var(--blue-shades-dark) 100%) !important;
  background-position: 90% 0, center !important;
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 1919px) {
  .top_menu {
    background-position: 700px 0, center !important;
  }
}

@media screen and (max-width: 1279px) {
  .top_menu {
    background-position: 500px 0, center !important;
  }
}

@media screen and (max-width: 959px) {
  .top_menu {
    background-position: 300px 0, center !important;
  }
}

@media screen and (max-width: 599px) {
  .top_menu {
    background-position: 150px 0, center !important;
  }
}

@media screen and (max-width: 400px) {
  .top_menu {
    background-position: 70px 0, center !important;
  }
}

.top_menu__button .v-btn.v-btn--density-default {
  margin: 0 12px;
  border-radius: 2000px;

  &:hover {
    background: var(--back-shades-dark);
  }
}

.top_menu__button__list {
  padding: 0;
  margin-top: 12px;
  border-radius: 15px !important;
  background: var(--background-white);
  box-shadow: 0 6px 20px 0 rgba(38, 54, 73, 0.32);
}

.top_menu__button__list-item {
  border-bottom: 1px solid var(--blue-shades-focus);

  &:hover {
    background: var(--blue-shades-focus);
  }
}

.top_menu__button__list-title {
  padding-left: 24px;
  padding-right: 24px;
  color: var(--corporate-main);
}
</style>
