<template>
  <div class="mx-0 px-0 pb-0 pt-4">
    <v-card class="rounded-lg" v-if="!!positionHeld">
      <v-toolbar
        :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
        :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
      >
        <span class="dark-primary"> Занимаемые должности </span>
        <v-spacer/>
      </v-toolbar>
      <v-expansion-panels
        v-model="expanded_items"
        multiple
        variant="accordion"
      >
        <v-expansion-panel
          v-for="(item, index) in positionHeld"
          :key="index"
          elevation="0"
          :class="index != positionHeld.length - 1 && !expanded_items.includes(index) ? 'rounded-0 border-b-sm' : 'rounded-0 border-0'"
        >
          <v-expansion-panel-title
            :class="expanded_items.includes(index) ? 'medium-primary' : ''"
          >
            <v-row no-gutters class="align-center">
              <v-col
                cols="12" md="6"
                :class="$vuetify.display.mdAndUp ? 'pr-md-2' : 'mb-3'"
              >
                <b>Подразделение:</b>
                {{ item.department }}
                <v-icon size="14" class="mb-1 dark-primary" @click.stop="copyInClipboard(item.department)">content_copy</v-icon>
              </v-col>
              <v-col
                cols="12" md="5"
                class="pl-md-6"
              >
                <b>Должность:</b>
                {{ item.title }}
                <v-icon size="14" class="mb-1 dark-primary" @click.stop="copyInClipboard(item.title)">content_copy</v-icon>
              </v-col>
              <v-col
                cols="12" md="1"
                v-if="item.is_fired"
                :class="$vuetify.display.mdAndUp ? '' : 'mt-3'"
              >
                <v-chip :color="expanded_items.includes(index) ? 'medium-primary' : ''">Уволен</v-chip>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row no-gutters class="align-center text-left pr-6">
              <v-col cols="12" md="2" class="py-2" v-if="$vuetify.display.smAndDown">
                <v-chip><span><b>Ставка:</b> {{ item.rate }}</span></v-chip>
              </v-col>
              <v-col cols="12" md="6" class="py-2">
                <b>Дата начала работы в организации:</b> {{ displayDate(item.start_work) }}
              </v-col>
              <v-col cols="12" md="4" class="py-2 pl-md-6">
                <span v-if="!!item.end_work"><b>Дата увольнения:</b> {{ displayDate(item.end_work) }}</span>
              </v-col>
              <v-col cols="12" md="2" class="py-2 text-md-right pr-md-3 pr-xl-5 pr-xxl-16" v-if="$vuetify.display.mdAndUp">
                <v-chip><span><b>Ставка:</b> {{ item.rate }}</span></v-chip>
              </v-col>
              <v-col cols="12" class="py-2">
                <b>Тип должности:</b> {{ item.type }}
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-tooltip
      v-model="copy"
      location="bottom center"
      text="Текст скопирован в буфер обмена"
      style="position: absolute; left: 50%; top: 90%"
    />
  </div>
</template>

<script>
import { displayDate } from "@/helper"

export default {
  props: {
    positionHeld: Object,
  },
  name: "PositionHeld",
  components: {},
  data: function () {
    return {
      expanded_items: [],
      copy: false,
    }
  },
  watch: {},
  computed: {},
  methods: {
    displayDate,
    copyInClipboard (text) {
      try {
        navigator.clipboard.writeText(text)
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, "2000")
      } catch (e) {
      }
    },
  },
  created () {}
}
</script>

<style scoped>

</style>
