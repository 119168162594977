import helper from './helper'

const getEducationUrlWithParameters = helper.getEducationUrlWithParameters
const allowedEducationOrderUrl = helper.allowedEducationOrderUrl
export default {
  getEducationUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getEducationUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  EDUCATION: {
    ALLOWED: () => allowedEducationOrderUrl(
      'student|students|student',
      "get",
    ),
    ADVANCED_TRAINING: {
      LIST:
                (gets = null) => getEducationUrlWithParameters(
                  'connectors|relations|achievement|documents-list',
                  'list',
                  null,
                  gets
                ),
      CREATE:
                (gets = null) => getEducationUrlWithParameters(
                  'connectors|relations|achievement|documents-list',
                  'create',
                  null,
                  gets
                ),
      DELETE:
                (id, gets = null) => getEducationUrlWithParameters(
                  'connectors|relations|achievement|documents-detail',
                  'destroy',
                  { id },
                  gets
                ),
      UPDATE:
                (id, gets = null) => getEducationUrlWithParameters(
                  'connectors|relations|achievement|documents-detail',
                  'update',
                  { id },
                  gets
                )
    }
  }
}
