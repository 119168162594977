<template>
  <v-list lines='one' class="pa-0">
    <template v-for="(item, i) in items" :key="`current_menu_${i}_item`">
      <span v-if="!item.base && !item.hide">
        <CurrentLeftMenuAction
          :item="item"
          v-if="item.router"
        />
        <template v-if="item.subs !== undefined && !item.router && !item.base">
          <v-list-group
            :value="item.subs"
            color='blue_shades_dark'
            base-color='blue_shades_dark'
          >
            <template v-slot:activator="{ props, isOpen }">
              <v-list-item
                v-bind="props"
                :class=" isOpen ? 'current_menu__item--active' : 'current_menu__item'"
              >
                <v-list-item-title class="current_menu__title text-uppercase text-h4">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-for="(subItem, index) in item.subs" :key="`current_menu_${index}_sub_item`">
              <CurrentLeftMenuSubAction
                :item="subItem"
              />
            </template>
          </v-list-group>
        </template>
      </span>
    </template>
  </v-list>
</template>

<script>
import CurrentLeftMenuAction from "@/modules/core/components/menus/left_menu/CurrentLeftMenuAction.vue"
import CurrentLeftMenuSubAction from "@/modules/core/components/menus/left_menu/CurrentLeftMenuSubAction.vue"

export default {
  name: "CurrentLeftMenu",
  components: {
    CurrentLeftMenuAction,
    CurrentLeftMenuSubAction
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
}
</script>

<style lang="scss">
.current_menu__item {
  padding: 16px 24px !important;
  height: 55px;
  border-bottom: 1px solid var(--blue-shades-focus);
  background: var(--background-white);

  &:hover {
    background: var(--gray-shades-hover);
  }

  &--active {
    padding: 16px 24px !important;
    height: 55px;
    border-bottom: 1px solid var(--blue-shades-focus);
    background: var(--blue-shades-focus);
  }
}

.current_menu__title {
  text-align: left;
  color: var(--blue-shades-dark);
}
</style>
