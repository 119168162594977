<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5575 1.4324C9.61556 1.37419 9.68453 1.32801 9.76046 1.29651C9.83639 1.265 9.91779 1.24878 10 1.24878C10.0822 1.24878 10.1636 1.265 10.2395 1.29651C10.3155 1.32801 10.3844 1.37419 10.4425 1.4324L17.9425 8.9324C18.0005 8.99054 18.0465 9.05956 18.0778 9.13548C18.1091 9.21141 18.1251 9.29276 18.125 9.3749V18.1249C18.125 18.2907 18.0592 18.4496 17.9419 18.5668C17.8247 18.684 17.6658 18.7499 17.5 18.7499H11.875C11.7092 18.7499 11.5503 18.684 11.4331 18.5668C11.3158 18.4496 11.25 18.2907 11.25 18.1249V13.1249H8.75V18.1249C8.75 18.2907 8.68415 18.4496 8.56694 18.5668C8.44973 18.684 8.29076 18.7499 8.125 18.7499H2.5C2.33424 18.7499 2.17527 18.684 2.05806 18.5668C1.94085 18.4496 1.875 18.2907 1.875 18.1249V9.3749C1.87485 9.29276 1.8909 9.21141 1.92221 9.13548C1.95353 9.05956 1.9995 8.99054 2.0575 8.9324L9.5575 1.4324ZM3.125 9.63365V17.4999H7.5V12.4999C7.5 12.3341 7.56585 12.1752 7.68306 12.058C7.80027 11.9407 7.95924 11.8749 8.125 11.8749H11.875C12.0408 11.8749 12.1997 11.9407 12.3169 12.058C12.4342 12.1752 12.5 12.3341 12.5 12.4999V17.4999H16.875V9.63365L10 2.75865L3.125 9.63365Z" fill="#0563C1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 3.125V7.5L13.75 5V3.125C13.75 2.95924 13.8158 2.80027 13.9331 2.68306C14.0503 2.56585 14.2092 2.5 14.375 2.5H15.625C15.7908 2.5 15.9497 2.56585 16.0669 2.68306C16.1842 2.80027 16.25 2.95924 16.25 3.125Z" fill="#0563C1"/>
  </svg>
</template>

<script>
export default {
  name: 'HouseIcon'
}
</script>

<style scoped>

</style>
