import helper from './helper'

const getSignerUrlWithParameters = helper.getSignerUrlWithParameters

export default {
  getSignerUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getSignerUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  SIGNER: {
    LIST:
      (gets = null) => getSignerUrlWithParameters(
        'self|documents-list',
        'list',
        null,
        gets
      ),
    CREATE:
      (gets = null) => getSignerUrlWithParameters(
        'self|documents-list',
        'create',
        null,
        gets
      ),
    DETAIL:
      (id, gets = null) => getSignerUrlWithParameters(
        'self|documents-detail',
        'retrieve',
        { id },
        gets
      ),
    DISPATCHED:
      (id, gets = null) => getSignerUrlWithParameters(
        'self|documents-dispatched',
        'dispatched',
        { id },
        gets
      ),
    SEND_MAIL:
      (document, recipient, gets = null) => getSignerUrlWithParameters(
        'self|document-to-recipient-send',
        'post',
        { document, recipient },
        gets
      ),
    DOCUMENTS_CHECK:
      (gets = null) => getSignerUrlWithParameters(
        'self|documents-check',
        'check',
        null,
        gets
      )
  }
}
