import helper from './helper'

const getWorkerUrlWithParameters = helper.getWorkerUrlWithParameters
const allowedWorkerOrderUrl = helper.allowedWorkerOrderUrl

export default {
  getWorkerUrlWithParameters,
  getByRecievedUrlData: (url, gets = null) => getWorkerUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  WORKER: {
    ALLOWED: () => allowedWorkerOrderUrl(
      'workers|employee|position|held-list',
      "list",
    ),
    POSITION_HELD_DETAIL:
      (gets = null) => getWorkerUrlWithParameters(
        'workers|employee|position|held-list',
        'list',
        null,
        gets
      ),
    UPDATE:
      (id, gets = null) => getWorkerUrlWithParameters(
        'workers|employee|worker|profile|self-detail',
        'update',
        { id },
        gets
      ),
    ORGANIZATION:
      (gets = null) => getWorkerUrlWithParameters(
        'users|department|organization-list',
        'list',
        null,
        gets
      ),
    ORGANIZATION_TREE:
      (organization_id, gets = null) => getWorkerUrlWithParameters(
        'users|department|organization|department-tree',
        'tree',
        { organization_id },
        gets
      )
  }
}
