import helper from './helper'

const getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters
// const getWorkerUrlWithParameters = helper.getWorkerUrlWithParameters
const getSignerUrlWithParameters = helper.getSignerUrlWithParameters

export default {
  SELECTORS: {
    ACCOUNTS: {
      COUNTRY: (gets = null) => getAccountsUrlWithParameters(
        'selectors|contacts|country',
        'get',
        {},
        gets
      ),
      COUNTRY_CODE: (gets = null) => getAccountsUrlWithParameters(
        'selectors|contacts|country|code',
        'get',
        {},
        gets
      ),
      LANGUAGES: (gets = null) => getAccountsUrlWithParameters(
        'selectors|users|languages',
        'get',
        {},
        gets
      ),
      ACCOUNTS: (gets = null) => getAccountsUrlWithParameters(
        'selectors|users|additional|accounts',
        'get',
        {},
        gets
      )
    },
    SIGNER: {
      RECIPIENTS: (gets = null) => getSignerUrlWithParameters(
        'selectors|recipients',
        'get',
        {},
        gets
      )
    }
  }
}
