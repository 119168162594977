<template>
  <v-container :fluid="true" style="width: 100%">
    <v-card
      class="rounded-lg"
    >
      <v-toolbar
        class="bg-gray-light text-left px-4"
        density="comfortable"
      >
        <v-text-field
          v-model="search.value"
          variant="outlined"
          :label="$vuetify.display.smAndUp ? 'Поиск по справочнику' : 'Поиск'"
          :placeholder="$vuetify.display.smAndUp ? 'Для поиска нажмите Enter' : 'Поиск'"
          class="mb-n6 dark-primary"
          density="compact"
          @keydown.enter="searchData"
          :clearable="true"
          @click:clear="$nextTick(() =>{ this.loadData() })"
        >
        </v-text-field>
        <v-spacer/>
      </v-toolbar>
      <v-card-text class="text-left pt-4 text-body-1">
        <v-data-table-server
          v-model:items-per-page="pagination.size"
          :items-per-page-options="[{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}]"
          v-model:page="pagination.page"
          :headers="headers"
          :items-length="count"
          :items="items"
          :loading="isLoading"
          :loading-text="'Подождите идет загрузка данных...'"
          :color="'#5e9de0'"
          :theme="'#5e9de0'"
          :search="search"
        >
          <template v-slot:item.email="{item}">
            <v-tooltip v-if="item.email" location="bottom" text="Скопировать почту">
              <template v-slot:activator="{ props }">
                <v-chip
                  v-if="item.email"
                  density="compact"
                  class="dark-primary mr-1 my-1"
                  v-bind="props"
                  @click="copyInClipboard(item.email, 0)"
                  :href="mailTo(item.email)"
                >
                  <span>{{item.email}}</span>
                </v-chip>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:item.room="{item}">
            <div v-if="item.room">
              <span v-if="item.room.text">
                {{item.room.text}}
              </span>
              <span v-else>
                {{item.room}}
              </span>
            </div>
          </template>
          <template v-slot:item.phone="{item}">
            <v-tooltip location="bottom" text="Скопировать телефон">
              <template v-slot:activator="{ props }">
                <v-chip
                  density="compact"
                  class="dark-primary mr-1 my-1"
                  v-bind="props"
                  v-for="(number, index) in item.phones"
                  @click="copyInClipboard(number.number, index)"
                  :key="index"
                  :href="callNumber(number.number)"
                >
                  <span>
                    {{number.number}}
                    <span v-if="number.addition.length !== 0">
                      ( Доб:
                      <span v-for="(add, index) in number.addition" :key="index">
                        {{add}}
                        <span v-if="index + 1 !== number.addition.length">
                        ,
                        </span>
                      </span>
                      )
                    </span>
                  </span>
                </v-chip>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:loader>
            <v-progress-linear
              :indeterminate="true"
              color="#5e9de0"
            ></v-progress-linear>
          </template>
        </v-data-table-server>
        <v-tooltip
          v-model="copy"
          location="bottom center"
          text="Текст скопирован в буфер обмена"
          style="position: absolute; left: 50%; top: 90%"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin"
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CorporateDirectory",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {},
  data: function () {
    return {
      page_title: "Корпоративный справочник",
      copy: false,
      search: {
        parameter: 'search',
      },
      headers: [
        { title: 'ФИО', value: 'user.text', align: 'start', sortable: false, width: '15%' },
        { title: 'Подразделение (Должность)', value: 'department', align: 'start', sortable: false, width: '30%' },
        { title: 'Адрес', value: 'address', align: 'start', sortable: false, width: '15%' },
        { title: 'Кабинет', value: 'room', align: 'center', sortable: false, width: '10%' },
        { title: 'Почта', value: 'email', align: 'center', sortable: false, width: '15%' },
        { title: 'Телефон', value: 'phone', align: 'center', sortable: false, width: '15%' },
        // { text: 'Действия', value: 'actions', align: 'center', sortable: false, width: '5%' },
      ],
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      items: 'reference/getPersonalList',
      count: 'reference/getPersonalListCount',
      isLoading: 'reference/isPersonalListLoading'
    }),
  },
  methods: {
    ...mapActions({
      loadPersonalList: 'reference/loadPersonalList',
    }),
    callNumber (number) {
      if (this.$vuetify.display.smAndDown) return 'tel:' + String(number)
      else return null
    },
    mailTo (mail) {
      if (this.$vuetify.display.smAndDown) return 'mailto:' + String(mail)
      else return null
    },
    copyInClipboard (item, index) {
      let copyText
      if (typeof item === 'object') copyText = item[index]
      else copyText = item
      try {
        navigator.clipboard.writeText(copyText)
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, "2000")
      } catch (e) {
        // console.log(e)
      }
    },
    loadData (url = undefined) {
      this.loadPersonalList({
        url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
  },
  created () {
    this.loadData()
  }
}
</script>

<style lang="scss">
</style>
