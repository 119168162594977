const state = {
  // enabled: false,
  mainInstances: [
    {
      title: 'СООБЩИТЬ ОБ ОШИБКЕ',
      router: null,
      href: 'mailto:support@muctr.ru'
    },
    {
      title: 'Инструкция',
      router: null,
      href: 'https://r.muctr.ru/portal'
    },
    // {
    //   title: 'Управление аккаунтом',
    //   router: 'Portal',
    //   href: null
    // },
  ],
  secondInstances: [
    {
      name: 'allServices',
      title: 'Все сервисы',
      enabled: false,
      url: 'https://portal.muctr.ru/tools',
    },
    {
      name: 'muctr',
      title: 'Официальный сайт',
      enabled: false,
      url: 'https://muctr.ru/'
    },
    {
      name: 'mail',
      title: 'Корпоративная почта',
      enabled: false,
      url: 'https://post.muctr.ru/'
    },
    {
      name: 'paymuctr',
      title: 'Оплата услуг университета',
      enabled: false,
      url: 'https://pay.muctr.ru/'
    },
    {
      name: 'queue',
      title: 'Очередь на мат. помощь',
      enabled: false,
      url: 'https://queue.muctr.ru/'
    },
    {
      name: 'DissBreadcrumbs',
      title: 'Учет диссоветов',
      url: 'https://council.muctr.ru/',
      enabled: false,
    },
  ],
  userActions: [
    {
      title: 'Управление аккаунтом',
      router: 'Portal'
    }
  ],

  title: '',
  userAvatar: null,
  is_page_loading: false

}

const mutations = {
  changeTitle (state, title) {
    state.title = title
  },
  changeAvatar (state, Avatar) {
    state.userAvatar = Avatar
  },
  setStartLoading (state) {
    state.is_page_loading = true
  },
  setEndRLoading (state) {
    state.is_page_loading = false
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
