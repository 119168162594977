<template>
  <v-card class="rounded-lg" v-if="!!workerData">
    <v-toolbar
      :class="$vuetify.display.mdAndUp ? 'text-h6 bg-gray-light text-left px-4 pr-1' : 'text bg-gray-light text-left px-4 pr-1'"
      :density="$vuetify.display.mdAndUp ? 'compact' : 'default'"
    >
      <!--        <span class="dark-primary"> Информация о трудоустройстве <br v-if="$vuetify.display.smAndDown"> РХТУ им. Д.И. Менделеева </span>-->
      <span class="dark-primary"> Личная карточка </span>
      <v-spacer/>
      <v-toolbar-items>
        <v-tooltip location="bottom" text="Редактировать информацию о работнике">
          <template v-slot:activator="{ props }">
            <v-btn flat icon v-bind="props"  @click="editWorkerDetail"  class="dark-primary">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="text-left pt-2">
      <v-row
        no-gutters
        :class="($vuetify.display.mdAndUp && i%2) ? 'bg-grey-lighten-3 rounded-lg' : 'rounded-lg'"
        v-for="(row, i) in data" :key="`row${i}`"
      >
        <v-col
          cols="12" md="6"
          :class="($vuetify.display.smAndDown && col%2) ? 'pa-3 bg-grey-lighten-3 rounded-lg' : 'pa-3 rounded-lg pr-md-8'"
          v-for="col in Object.keys(row)"
          :key="`row${i}_${col}`"
        >
          <b>{{ row[col].text }}:</b> {{ (row[col].type === 'date') ? displayDate(this.workerData[row[col].field]) : this.workerData[row[col].field] }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <span v-if="!!workerData">
      <position-held
        :position-held="workerData.position_helds"
      ></position-held>
    </span>

  <worker-edit-dialog
    v-if="dialog_edit"
    v-model="dialog_edit"
    :data="edit_data"
    :edit="edit_status"
    @save="saveWorkerEdit"
    @close="dialog_edit=false"
  >
  </worker-edit-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import WorkerEditDialog from "@/modules/profile/worker/modals/WorkerEditDialog"
import names from "@/modules/profile/routers/names"
import router from "@/router"
import PositionHeld from "@/modules/profile/worker/components/PositionHeld.vue"
import moment from 'moment/moment'

export default {
  name: "WorkerDetail",
  components: { WorkerEditDialog, PositionHeld },
  data: function () {
    return {
      dialog_edit: false,
      edit_data: undefined,
      edit_status: false,
      data: [
        [
          { text: 'Дата начала трудового стажа', field: 'start_seniority', type: 'date' },
          { text: 'Дата начала трудового стажа в организации', field: 'start_seniority_in_organization', type: 'date' },
        ],
        [
          { text: 'Информация о себе', field: 'personal_information' },
          { text: 'Награды', field: 'awards' },
        ],
        [
          { text: 'Предыдущие места работы', field: 'previous_jobs' },
          { text: 'Ученое звание', field: 'academic_ranks' },
        ],
        [
          { text: 'Ученая степень', field: 'academic_degree' },
          { text: 'Образование', field: 'education' },
        ],
      ],
    // worker_data: [],
    }
  },
  computed: {
    ...mapGetters({
      workerData: 'worker/getWorkerDetail'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadWorkerDetail: 'worker/loadWorkerDetail',
    }),
    loadData (url = undefined) {
      this.loadWorkerDetail({
        url,
        finalizer: (data, url) => {},
        catcher: () => {
          router.push({ name: names.BASE })
        }
      })
    },
    displayDate (date, minutes = false) {
      if (!date) {
        return 'Не указано'
      }
      let format = 'DD.MM.YYYY'
      if (minutes) { format += ' HH:mm' }
      return moment(date).format(format)
    },
    editWorkerDetail () {
      this.edit_data = this.workerData
      this.edit_status = true
      this.dialog_edit = true
    },
    saveWorkerEdit () {
      this.edit_data = undefined
      this.edit_status = false
      this.dialog_edit = false
      this.loadData()
    },
  },
  created () {
    if (!this.workerData) {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
