<template>
  <div class="signature-wrapper">
    <table cellspacing="0" cellpadding="0" style="width: 715px; box-sizing: border-box">
      <colgroup>
        <col style="width: 150px">
        <col style="width: 400px">
        <col style="width: 165px">
      </colgroup>
      <tbody>
        <tr class="signature-header" style="width: 715px; height: 70px">
          <td style="width: 150px">
            <section>
              <a href="https://muctr.ru" target="_blank">
                <p style="text-align: center!important;">
                  <img alt="logo" height="50" :src="smallLogoRHTU"/>
                </p>
              </a>
            </section>
          </td>
          <td colspan="2" style="width: 565px">
            <section>
              <p class="sub-title">С уважением,</p>
              <p class="signature-title">{{ fio }}</p>
            </section>
          </td>
          <td rowspan="4" style="width: 165px" class="box-shadow_top">
            <div v-if="vcard3" class="qr_code">
              <vue-qrcode
                tag="img"
                :value="vcard3"
                :options="{ type: 'svg', margin: 0, width: 150, color: {dark: '#8a8a8a' } }"
              />
            </div>
          </td>
        </tr>
        <tr class="signature-content" style="width: 715px; height: 50px">
          <td style="width: 150px; text-align: center"  class="box-shadow_top">
<!--            <section>-->
<!--              <a href="https://muctr.ru" target="_blank">-->
<!--                <p class="text-center">-->
<!--                  <img alt="logo" :src="ditLogo"/>-->
<!--                </p>-->
<!--              </a>-->
<!--            </section>-->
          </td>
          <td style="width: 400px" class="box-shadow_top">
            <section v-if="firstPosition && firstDepartment">
              <p class="signature-content__position">{{ firstPosition }}</p>
              <p class="signature-content__department">{{ firstDepartment }}</p>
            </section>
          </td>
        </tr>
        <tr style="width: 715px" class="signature-content">
          <td style="width: 150px">
            <section v-if="false">
              <img alt="logo" :src="ditLogo"/>
            </section>
          </td>
          <td style="width: 400px">
            <section v-if="secondPosition && secondDepartment">
              <p class="signature-content__position">{{ secondPosition }}</p>
              <p class="signature-content__department">{{ secondDepartment }}</p>
            </section>
          </td>
        </tr>
        <tr style="width: 715px; height: 80px">
          <td style="width: 150px">{{'  '}}</td>
          <td style="width: 400px; height: 50px">
            <div class="signature-content__contacts">
              <div class="signature-content__contacts__section" v-if="firstEmail">
                <div class="signature-content__contact" >
                  <a :href="`mailto:${firstEmail}`" style="line-height: 16px;">
                    {{ firstEmail }}
                  </a>
                </div>
                <div v-if="secondEmail" class="signature-content__contact">
                  <a :href="`mailto:${secondEmail}`" style="line-height: 16px;">
                    {{ secondEmail }}
                  </a>
                </div>
              </div>
              <div class="signature-content__contacts__section">
                <div v-if="firstPhone" class="signature-content__contact">
                  <a :href="`tel:${firstPhone}`" style="line-height: 16px;">
                    {{ firstPhone }}
                  </a>
                </div>
                <div v-if="secondPhone" class="signature-content__contact">
                  <a :href="`tel:${secondPhone}`" style="line-height: 16px;">
                    {{ secondPhone }}
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr style="width: 715px; height: 30px" class="signature-footer">
          <td style="width: 150px" colspan="1">
            <a href="https://muctr.ru" target="_blank">
              www.muctr.ru
            </a>
          </td>
          <td colspan="3" class="text-left">
            <section>
              <p class="text-left" style="text-align: left;">
                <a :href="`${address.urlMap}`" target="_blank">
                    РХТУ им. Д.И. Менделеева {{ address.idx }} {{ address.city }} {{ address.street }}
                </a>
              </p>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import smallLogoRHTU from '@/assets/smallLogoRHTU.png'
import smallLogoMuctr from '@/assets/smallLogoMuctr.png'
import muctr_gr from '@/assets/muctr_gr.svg'
import ditLogo from '@/assets/ditLogo.png'
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: "SignatureComponent",
  components: { VueQrcode },
  props: {
    vcard3: {
      required: true,
      default: '',
      type: String
    },
    fio: {
      required: true,
      default: '',
      type: String
    },
    firstPosition: {
      required: true,
      default: '',
      type: String
    },
    firstDepartment: {
      required: true,
      default: '',
      type: String
    },
    secondPosition: {
      required: false,
      default: '',
      type: String
    },
    secondDepartment: {
      required: false,
      default: '',
      type: String
    },
    firstEmail: {
      required: true,
      default: '',
      type: String
    },
    secondEmail: {
      required: true,
      default: '',
      type: String
    },
    firstPhone: {
      required: true,
      default: '',
      type: String
    },
    secondPhone: {
      required: true,
      default: '',
      type: String
    },
    address: Object
  },
  data () {
    return {
      ditLogo,
      smallLogoMuctr,
      smallLogoRHTU,
      muctr_gr
    }
  }
}
</script>

<style scoped lang="scss">
  .signature-wrapper {
    font-family: Arial, sans-serif;
    width: 715px;
    min-height: 200px;
    margin: auto;
    //font-family: 'MADE Evolve Sans', 'Inter', 'Calibri', sans-serif;
  }
  .signature-content {
    //padding: 10px 0;
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 20px);
    .signature-content__position {
      font-weight: 700;
      font-size: 14px;
      text-align: left;
    }
    .signature-content__department {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #8a8a8a;
    }
  }
  .signature-content__contacts {
    display: block;
    gap: 12px;
    text-align: left;
    line-height: 20px;
    .signature-content__contacts__section {
      display: inline-table;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 8px;
      margin-right: 16px;
      .signature-content__contact {
        display: block;
        align-items: center;
        justify-content: start;
        min-width: 130px;
        height: 23px;
        border-radius: 5px;
        //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.11);
        font-weight: 400;
        font-size: 9px;
        a {
          padding: 0;
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }
  .signature-footer {
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 20px);
    a {
      font-weight: 700;
      font-size: 12px;
      color: #000000;
      text-decoration: none;
    }
     p {
         text-align: left;
       a {
         color: #000000;
         font-weight: 400;
         font-size: 10px;
       }
     }
  }
  .signature-header {
    section p {
      text-align: left;
    }
    .sub-title {
      font-size: 11px;
      font-weight: 400;
    }
    .signature-title {
      font-weight: 700;
      font-size: 20px;
      //text-transform: uppercase;
    }
  }
  .qr_code {
    padding: 20px 10px 5px 10px;
    margin: auto;
  }
  .box-shadow_top {
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 20px);
  }
</style>
