import url_acc from '@/urls/accounts'
import { sendPostRequest } from '@/helper/requests'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

function get_domain () {
  /*
    *
     */
  if (process.env.VUE_APP_COOKIE_DOMAIN !== undefined) {
    return process.env.VUE_APP_COOKIE_DOMAIN
  } else {
    return null
  }
}

function set_cookie_store (token, username, uid) {
  /*
    * Заполняем кукисы
     */
  cookies.remove('userMuctr', null, get_domain()) // удалим старый
  cookies.set(
    'userMuctr',
    {
      userToken: token,
      userName: username,
      userUid: uid
    },
    '3y',
    null,
    get_domain(),
    null
  )
}

const state = {}

const getters = {}

const mutations = {}

const actions = {
  changeAccount: ({ commit }, { data, finalizer, catcher }) => {
    const url = url_acc.PROFILE.ACCOUNT.CHANGE_ACCOUNT()
    sendPostRequest(
      url, data, (data, url) => {
        if (data.token) {
          set_cookie_store(data.token, data.username, data.uid)
          window.location.reload()
        }
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
}
export default {
  state,
  mutations,
  getters,
  actions
}
