<template>
  <v-container :fluid="true" style="width: 100%">
    <v-row no-gutters align="center" justify="center" style="height: 90vh">
      <v-card elevation="0">
        <v-alert
          type="info"
          :icon="false"
          variant="outlined"
          rounded="xl"
          :class="$vuetify.display.mdAndUp ? 'text-h5 px-8 pt-4' : 'text-body px-4 pt-2'"
          style="line-height: 2"
          min-height="200"
        >
          <v-icon :size="$vuetify.display.mdAndUp ? 100 : 60">info</v-icon>
          <p>Уважаемый пользователь, Вы покидаете данный раздел сайта</p>
          <p>Вы будуте перенаправлены на <a class="text-blue" :href="linkText().url" target="_blank">{{ linkText().text }}</a></p>
        </v-alert>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { ADMIN_LINK, EIOS_LINK, MESSAGE_LINK, PASSORDER_LINK, CONF_LINK } from '@/helper/consts'
export default {
  name: "RedirectPage",
  methods: {
    linkText () {
      if (this.$route.query.link === 'eios') {
        return { text: 'eios.muctr.ru', url: 'https://eios.muctr.ru' }
      }
      if (this.$route.query.link === 'passorder') {
        return { text: 'passorder.muctr.ru', url: 'https://passorder.muctr.ru' }
      }
      if (this.$route.query.link === 'admin') {
        return { text: 'admin.muctr.ru', url: 'https://admin.muctr.ru' }
      }
      if (this.$route.query.link === 'map') {
        return { text: 'map.muctr.ru', url: 'https://map.muctr.ru' }
      }
      if (this.$route.query.link === 'schedule') {
        return { text: 'schedule.muctr.ru', url: 'https://schedule.muctr.ru' }
      }
      if (this.$route.query.link === 'messaging') {
        return { text: 'messaging.muctr.ru', url: 'https://messaging.muctr.ru' }
      }
      if (this.$route.query.link === 'confirmation') {
        return { text: 'confirmation.muctr.ru', url: 'https://confirmation.muctr.ru' }
      } else {
        return { text: 'portal.muctr.ru', url: 'https://portal.muctr.ru' }
      }
    }
  },
  created () {
    this.$store.commit('leftmenu/dissable')
    setTimeout(() => {
      if (this.$route.query.link === 'eios') {
        document.location.href = EIOS_LINK
      }
      if (this.$route.query.link === 'message') {
        document.location.href = MESSAGE_LINK
      }
      if (this.$route.query.link === 'passorder') {
        document.location.href = PASSORDER_LINK
      }
      if (this.$route.query.link === 'confirmation') {
        document.location.href = CONF_LINK
      }
      if (this.$route.query.link === 'admin') {
        document.location.href = ADMIN_LINK
      }
    }, 10)
  }
}
</script>

<style scoped>

</style>
