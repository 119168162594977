const state = {
  notifications: {
    special: [
    ],
  }
}

const getters = {
  getNotificationsSpecial: (state) => state.notifications.special,
}

const actions = {
  deleteSpecialNotification: ({ commit }, { val }) => {
    const filteredNotification = state.notifications.special.filter((item) => item.id !== val.id)
    commit('SET_NOTIFICATIONS_SPECIAL', filteredNotification)
  },
  addSpecialNotification: ({ commit }, { val }) => {
    const searchNotification = state.notifications.special.find((item) => item.id === val.id)
    if (!searchNotification) { commit('ADD_NOTIFICATIONS_SPECIAL', val) }
  },
}

const mutations = {
  SET_NOTIFICATIONS_SPECIAL: (state, list) => {
    state.notifications.special = list
  },
  ADD_NOTIFICATIONS_SPECIAL: (state, val) => {
    state.notifications.special.push(val)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
