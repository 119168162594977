import url_acc from '@/urls/accounts'
import { sendDeleteRequest, sendPostRequest, sendPutRequest } from '@/helper/requests'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  createUserLanguage: ({ commit }, { data, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.LANGUAGE.CREATE()
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserLanguage: ({ commit }, { data, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.LANGUAGE.UPDATE(id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserLanguage: ({ commit }, { id, finalizer = () => {}, catcher = () => {} }) => {
    const url = url_acc.PROFILE.LANGUAGE.DELETE(id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  }
}
export default {
  state,
  mutations,
  getters,
  actions
}
