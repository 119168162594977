<template>
  <v-dialog
    v-model="open"
    :max-width="maxWidth"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <v-card  class="rounded-lg">
      <v-toolbar flat tile rounded="0" width="100%" :color="topColor">
        <span  :class="$vuetify.display.mdAndUp ? 'text-h5 pl-6 dark-primary' : 'text-h6 pl-5 dark-primary'">
          Подтвердите изменение
        </span>
      </v-toolbar>
      <v-card-text>
        <span v-html="getText()" v-if="getText !== undefined"></span>
        <slot v-else></slot>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-spacer/>
          <v-btn
            class="rounded-lg"
            @click="applyDialog"
            color="warning"
            variant="text"
          >
            Подтвердить
          </v-btn>
          <v-btn
            class="rounded-lg"
            @click="closeDialog"
            variant="text"
          >
            Отменить
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApplyDialog",
  props: {
    getText: {
      type: Function,
      default: undefined,
    },
    topColor: {
      default: '#dadada',
      type: String
    },
    maxWidth: {
      default: '500px',
      type: String,
    },
    // opened: Boolean,
  },
  data: function () {
    return {
      open: false
    }
  },
  methods: {
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      this.$emit('close', true)
    }

  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
}
</script>

<style scoped>

</style>
