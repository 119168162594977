<template>
  <scroll-center-dialog v-model="open" @close="close" @apply="checkForm" :title=" (select)?'Изменить языки':'Добавить языки'" :scrolled="false">
    <v-card flat class="pt-4">
      <v-select
        class="py-1"
        :error="hasError('language')"
        :error-messages="getError('language')"
        :items="language"
        autocomplete
        item-title="text"
        item-value="value"
        variant="outlined"
        label="Язык"
        v-model="form.language"
      ></v-select>
      <v-select
        class="pt-1"
        :error="hasError('level')"
        :error-messages="getError('level')"
        :items="languageType"
        variant="outlined"
        item-title="text"
        item-value="value"
        label="Уровень знания языка"
        required
        v-model="form.level"
      ></v-select>
      <v-checkbox
        :error="hasError('is_native')"
        :error-messages="getError('is_native')"
        label="Родной"
        v-model="form.is_native"
      ></v-checkbox>

    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/templates/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImageCropper',
  props: {
    value: Boolean,
    select: Object,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  computed: {
    ...mapGetters({
      languageType: 'choices/languageType',
      language: 'selectors/accountLanguages',
    })
  },
  data () {
    return {
      open: false,
      selectors: [],
      form: {
        language: null,
        level: null,
        is_native: false
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  methods: {
    ...mapActions({
      loadLanguageType: 'choices/loadLanguageType',
      loadLanguage: 'selectors/loadAccountLanguages',
      createUserLanguage: "user/createUserLanguage",
      updateUserLanguage: "user/updateUserLanguage"
    }),
    loadSelectors () {
      this.loadLanguage({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    loadChoices () {
      this.loadLanguageType({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.$emit('close')
    },
    checkForm () {
      const finalizer = (val) => {
        this.$emit('save')
      }
      const catcher = (data, url) => {
        this.errors.fields = data
      }
      if (this.select) {
        if (typeof this.form.language === 'object') { this.form.language = this.form.language.value }
        this.updateUserLanguage({
          data: this.form,
          id: this.select.id,
          finalizer,
          catcher
        })
      } else {
        this.createUserLanguage({
          data: this.form,
          finalizer,
          catcher
        })
      }
    },
  },
  close () {
    this.$emit('close')
  },
  created () {
    if (!this.languageType) {
      this.loadChoices()
    }
    if (this.language.length === 0) {
      this.loadSelectors()
    }
    if (this.select) {
      this.form.language = this.select.language
      this.form.level = this.select.level
      this.form.is_native = this.select.is_native
    }
  }
}
</script>

<style scoped>

</style>
